import React, { useCallback, useEffect, useMemo } from 'react';
import { Flex, Text, colors } from '@lama/design-system';

import { AddBusiness, CreateOutlined, PersonAdd, ContentPasteSearch } from '@mui/icons-material';
import type { FC } from 'react';
import {
  businessName,
  businessRelationsSelector,
  getApplicationEntityByIdSelector,
  personFullName,
  personRelationsSelector,
  relatedCompaniesSelector,
} from '@lama/data-formatters';
import type { ApplicationApiModel, PersonApiModel } from '@lama/clients';
import type { BusinessApiModel } from '@lama/business-service-client';
import { noop, uniqBy } from 'lodash-es';
import type { Entity } from '@lama/common-types';
import { LoadingWrapper } from '../LoadingWrapper';
import { ExistingEntityBox, RelationsListOptionButtonWithIcon } from './RelationsListOptionButtons';

interface CreateEntitySelectionProps {
  entityType?: 'business' | 'person';
  onEntitySelected: (entityId?: string) => void;
}

const CreateEntitySelection: FC<CreateEntitySelectionProps> = ({ entityType, onEntitySelected }) => {
  const icon = useMemo(() => {
    if (!entityType) {
      return <CreateOutlined sx={{ height: 20, width: 20, fill: colors.deepPurple['400'] }} fontSize={'small'} />;
    }
    return entityType === 'business' ? (
      <AddBusiness sx={{ height: 20, width: 20, fill: colors.deepPurple['400'] }} fontSize={'small'} />
    ) : (
      <PersonAdd sx={{ height: 20, width: 20, fill: colors.deepPurple['400'] }} fontSize={'small'} />
    );
  }, [entityType]);

  return (
    <Flex flexDirection={'column'} height={64}>
      <RelationsListOptionButtonWithIcon entityType={entityType} onClick={onEntitySelected} name={'Create new entity'} icon={icon} />
    </Flex>
  );
};

const SearchEntitySelection: FC<Pick<CreateEntitySelectionProps, 'onEntitySelected'>> = ({ onEntitySelected }) => {
  const entitySelectedHandler = useCallback(() => {
    onEntitySelected();
  }, [onEntitySelected]);

  return (
    <Flex data-testid={'search-existing-customer-button'} flexDirection={'column'} height={64}>
      <RelationsListOptionButtonWithIcon
        onClick={entitySelectedHandler}
        name={'Search for existing customer'}
        icon={<ContentPasteSearch sx={{ height: 20, width: 20, fill: colors.deepPurple['400'] }} />}
      />
    </Flex>
  );
};

interface SelectOrCreateEntityProps {
  onEntitySelected: (entity?: BusinessApiModel | PersonApiModel, entityType?: Entity) => void;
  onSearchSelected?: () => void;
  searchEnabled?: boolean;
  entityType?: 'business' | 'person';
  excludedEntityIds?: string[];
  application: ApplicationApiModel;
  loading: boolean;
}

const getApplicationBusinessesAndAffiliatesWithRelation = (application: ApplicationApiModel) =>
  uniqBy(
    [
      ...application.relatedBusinesses,
      ...application.relatedBusinesses
        .flatMap(({ business }) => relatedCompaniesSelector(application, business))
        .map((a) => ({
          business: a.business,
          relation: 'Affiliate',
        })),
    ],
    (b) => b.business.id,
  );

const getApplicationPeopleWithRelation = (application: ApplicationApiModel) =>
  uniqBy(
    [
      ...application.relatedPeople,
      ...application.relatedBusinesses
        .flatMap(({ business: { people } }) => people)
        .map((entity) => ({
          person: entity,
          relation: personRelationsSelector(entity.id, application),
        })),
    ],
    (p) => p.person.id,
  );

type EntityType = 'business' | 'person';

export const SelectOrCreateEntity: FC<SelectOrCreateEntityProps> = ({
  entityType,
  onEntitySelected,
  onSearchSelected,
  excludedEntityIds,
  application,
  searchEnabled,
  loading,
}) => {
  const createEntitySelectedCallback = useCallback(() => {
    onEntitySelected();
  }, [onEntitySelected]);

  const entitySelectedCallbackCreator = useCallback(
    (entityId: string, currentEntityType: Entity) => () => {
      const entity = getApplicationEntityByIdSelector(application, entityId);
      onEntitySelected(entity, currentEntityType);
    },
    [application, onEntitySelected],
  );

  const entities = useMemo<{ id: string; name: string; relationDisplayName: string; entityType: EntityType }[]>(() => {
    const existingBusinesses = getApplicationBusinessesAndAffiliatesWithRelation(application)
      .map((entity) => ({
        id: entity.business.id,
        name: businessName(entity.business) ?? '',
        relationDisplayName: businessRelationsSelector(entity.business.id, application),
        entityType: 'business' as EntityType,
      }))
      .filter((entity) => !excludedEntityIds?.includes(entity.id));
    const existingPeople = getApplicationPeopleWithRelation(application)
      .map((entity) => ({
        id: entity.person.id,
        name: personFullName(entity.person),
        relationDisplayName: personRelationsSelector(entity.person.id, application),
        entityType: 'person' as EntityType,
      }))
      .filter((entity) => !excludedEntityIds?.includes(entity.id));
    if (!entityType) {
      return [...existingBusinesses, ...existingPeople];
    }
    return entityType === 'business' ? existingBusinesses : existingPeople;
  }, [application, entityType, excludedEntityIds]);

  useEffect(() => {
    if (!entities.length && !loading && !searchEnabled) {
      onEntitySelected();
    }
  }, [entities, loading, onEntitySelected, searchEnabled]);

  return (
    <Flex height={'100%'} flexDirection={'column'} width={'100%'}>
      <LoadingWrapper disabled={loading} loading={loading}>
        <Flex flexDirection={'column'} gap={2}>
          <CreateEntitySelection onEntitySelected={createEntitySelectedCallback} entityType={entityType} />
          {searchEnabled ? <SearchEntitySelection onEntitySelected={onSearchSelected ?? noop} /> : null}
          {entities.length ? (
            <>
              <Text color={colors.grey['600']} variant={'body3'}>
                {'Other entities related to this application'}
              </Text>
              {entities.map(({ id, name, relationDisplayName, entityType: businessOrPerson }) => (
                <ExistingEntityBox
                  key={`existing_entity_${id}`}
                  entityType={businessOrPerson}
                  name={name}
                  relationName={relationDisplayName}
                  onClick={entitySelectedCallbackCreator(id, businessOrPerson)}
                />
              ))}
            </>
          ) : null}
        </Flex>
      </LoadingWrapper>
    </Flex>
  );
};
