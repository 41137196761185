import React from 'react';

export const FormatItalicIcon = () => (
  <svg width={'20'} height={'20'} viewBox={'0 0 20 20'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'}>
    <mask id={'mask0_30701_2470'} style={{ maskType: 'alpha' }} maskUnits={'userSpaceOnUse'} x={'0'} y={'0'} width={'20'} height={'20'}>
      <rect width={'20'} height={'20'} fill={'#D9D9D9'} />
    </mask>
    <g mask={'url(#mask0_30701_2470)'}>
      <path
        d={
          'M5.2483 15.5208C5.03742 15.5208 4.85917 15.4479 4.71355 15.3022C4.56793 15.1564 4.49512 14.978 4.49512 14.767C4.49512 14.5559 4.56793 14.3777 4.71355 14.2324C4.85917 14.0871 5.03742 14.0144 5.2483 14.0144H7.6762L10.3765 5.98556H7.94864C7.73775 5.98556 7.5595 5.91269 7.41389 5.76694C7.26826 5.62119 7.19545 5.44277 7.19545 5.23169C7.19545 5.02062 7.26826 4.84243 7.41389 4.69712C7.5595 4.55183 7.73775 4.47919 7.94864 4.47919H14.0704C14.2813 4.47919 14.4596 4.55206 14.6052 4.69781C14.7508 4.84356 14.8236 5.02197 14.8236 5.23304C14.8236 5.44412 14.7508 5.62232 14.6052 5.76763C14.4596 5.91292 14.2813 5.98556 14.0704 5.98556H11.8989L9.1986 14.0144H11.3701C11.581 14.0144 11.7592 14.0873 11.9048 14.2331C12.0505 14.3788 12.1233 14.5572 12.1233 14.7683C12.1233 14.9794 12.0505 15.1576 11.9048 15.3029C11.7592 15.4482 11.581 15.5208 11.3701 15.5208H5.2483Z'
        }
        fill={'#9E9E9E'}
      />
    </g>
  </svg>
);
