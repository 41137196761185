import React, { useCallback, useContext, useMemo } from 'react';
import type { Entity } from '@lama/common-types';
import type { Document } from '@lama/document-service-client';
import type { DismissedSourceReason, EvaluatedRequirementSharedData, FileUploadSource } from '@lama/contracts';
import { ConfirmLeave, DocumentBox as DocumentBoxInner } from '@lama/app-components';
import { useTranslation } from 'react-i18next';
import { useUploadDocumentMutation } from '../../../../../hooks/react-query/useUploadDocumentMutation';
import { ApplicationContext } from '../../../../../shared/contexts/ApplicationContext';
import { useDeleteDocumentMutation } from '../../../../../hooks/react-query/useDeleteDocumentMutation';
import type { DismissWithReasonDialogValues } from '../../../DismissWithReasonDialog';
import { DismissWithReasonDialog } from '../../../DismissWithReasonDialog';
import { useUpdateRequirementMutation } from '../../../../../hooks/react-query/useUpdateRequirementMutation';
import { useUpdateApplicationRequirementV2Mutation } from '../../../../../hooks/react-query/useUpdateRequirementV2';

interface DocumentBoxProps {
  requirement: EvaluatedRequirementSharedData;
  description: string;
  topic: Document['topic'];
  document?: Document;
  sharedRequirementId?: string;
  requirementKey?: string;
  entityId: string;
  entityType: Entity;
  dismissible?: boolean;
  dismissed?: DismissedSourceReason;
  extractable?: boolean;
  relatedItemId?: string;
  relatedItemType?: string;
}

export const DocumentBox: React.FC<DocumentBoxProps> = ({
  requirement: { id: requirementId, sources },
  description,
  topic,
  entityId,
  entityType,
  document,
  dismissible,
  dismissed,
  sharedRequirementId,
  requirementKey,
  extractable,
  relatedItemId,
  relatedItemType,
}) => {
  const {
    application: { id: applicationId },
  } = useContext(ApplicationContext);
  const { mutateAsync: uploadDocument, isPending: uploadingDocument } = useUploadDocumentMutation(applicationId);
  const { mutateAsync: deleteDocument, isPending: deletingDocument } = useDeleteDocumentMutation(applicationId);
  const [dismissingSource, setDismissingSource] = React.useState<FileUploadSource | null>(null);
  const { mutateAsync: updateRequirement } = useUpdateRequirementMutation(applicationId, requirementId);
  const { mutateAsync: updateRequirementV2 } = useUpdateApplicationRequirementV2Mutation(applicationId, requirementId);
  const { t } = useTranslation();

  const documentProcessing = useMemo(() => uploadingDocument || deletingDocument, [uploadingDocument, deletingDocument]);

  const onDismissDocument = useCallback(
    async (name: string) => {
      setDismissingSource(sources.uploadFilesSource?.find((s) => s.name === name) ?? null);
    },
    [sources.uploadFilesSource],
  );

  const handleDismiss = useCallback(
    async (dialogResult: DismissWithReasonDialogValues | null) => {
      if (!dismissingSource || !dialogResult?.reason) {
        setDismissingSource(null);
        return;
      }

      const { description: dismissDescription, reason: dismissReason } = dialogResult;

      const updatedSources = sources.uploadFilesSource?.map((source) => {
        if (source.name === dismissingSource.name) {
          return {
            ...source,
            dismissDataByEntity: {
              ...source.dismissDataByEntity,
              [entityId]: {
                description: dismissDescription,
                reason: dismissReason,
              },
            },
          };
        }

        return source;
      });
      await Promise.all([
        updateRequirement({
          updateRequirementPayload: {
            sources: {
              uploadFilesSource: updatedSources,
            },
          },
        }),
        updateRequirementV2({
          updateRequirementPayload: {
            uploadFileSources: updatedSources,
          },
        }),
      ]);

      setDismissingSource(null);
    },
    [dismissingSource, entityId, sources.uploadFilesSource, updateRequirement, updateRequirementV2],
  );

  const onUndismissDocument = useCallback(async () => {
    const updatedSources = sources.uploadFilesSource?.map((s) =>
      s.name === description
        ? {
            ...s,
            dismissDataByEntity: {},
          }
        : s,
    );
    await Promise.all([
      updateRequirement({
        updateRequirementPayload: {
          sources: {
            uploadFilesSource: updatedSources,
          },
        },
      }),
      updateRequirementV2({
        updateRequirementPayload: {
          uploadFileSources: updatedSources,
        },
      }),
    ]);
  }, [description, sources.uploadFilesSource, updateRequirement, updateRequirementV2]);

  const onDocumentSelected = useCallback(
    async (file: File) => {
      if (documentProcessing) {
        return;
      }

      await uploadDocument({
        file,
        description,
        requirementId,
        sharedRequirementId,
        requirementKey,
        applicationId,
        entityId,
        entityType,
        topic,
        extractable,
        relatedItemId,
        relatedItemType,
      });
    },
    [
      applicationId,
      description,
      entityId,
      entityType,
      requirementId,
      requirementKey,
      sharedRequirementId,
      topic,
      uploadDocument,
      documentProcessing,
      extractable,
      relatedItemId,
      relatedItemType,
    ],
  );

  const onDocumentRemoved = useCallback(async () => {
    if (documentProcessing) {
      return;
    }

    if (document?.id) {
      await deleteDocument({ documentId: document?.id });
    }
  }, [deleteDocument, document?.id, documentProcessing]);

  return (
    <>
      <ConfirmLeave shouldBlock={documentProcessing} message={t('documentRequirement.confirmLeave')}>
        <DocumentBoxInner
          description={description}
          document={document}
          onDocumentUpload={onDocumentSelected}
          onDocumentRemoved={onDocumentRemoved}
          onDismissSource={onDismissDocument}
          onUndismissSource={onUndismissDocument}
          dismissible={dismissible}
          dismissed={dismissed}
        />
      </ConfirmLeave>
      {dismissible && !!dismissingSource ? (
        <DismissWithReasonDialog
          open={!!dismissingSource}
          handleClose={handleDismiss}
          title={'Are you sure you want to dismiss this file requirement'}
        />
      ) : null}
    </>
  );
};
