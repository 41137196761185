import type { FC } from 'react';
import React, { useContext, useMemo } from 'react';
import type { Entity } from '@lama/common-types';
import type { RequirementProperty } from '@lama/contracts';
import { Flex } from '@lama/design-system';
import type { ApplicationApiModel } from '@lama/clients';
import { useFormikContext } from 'formik';

import { get } from 'lodash-es';
import { ComponentRestrictor } from '../ComponentRestrictor/ComponentRestrictor.js';
import { isEmptyValue } from '../../utils/isEmptyValue.js';
import { GenericPropertiesContext } from './GenericPropertiesContext.js';
import { typeToComponent } from './propertyTypeToComponentMap.js';

interface ConditionedPropertyProps {
  property: RequirementProperty;
  submitted?: boolean;
  entityType: Entity;
  application: ApplicationApiModel;
}

export const PropertyComponent: FC<ConditionedPropertyProps> = ({ property: p, submitted, entityType, application }) => {
  const { customComponents, sourceToValues, sourceToHierarchicalValues } = useContext(GenericPropertiesContext);
  const Component = useMemo(
    () => customComponents[p.customComponent ?? p.type] ?? typeToComponent[p.type],
    [customComponents, p.customComponent, p.type],
  );
  const { initialValues } = useFormikContext();
  const propertyInitialValue = useMemo(() => get(initialValues, p.fieldName), [initialValues, p.fieldName]);

  return (
    <Flex flexDirection={'column'} flex={1} minWidth={0}>
      <ComponentRestrictor
        isEmptyValue={isEmptyValue(propertyInitialValue)}
        displayName={p.displayName}
        restricted={!!p.restricted}
        componentType={'input'}
      >
        <Component
          property={p}
          submitted={submitted}
          entityType={entityType}
          sourceToValues={sourceToValues}
          sourceToHierarchicalValues={sourceToHierarchicalValues}
          application={application}
          initialValue={propertyInitialValue}
        />
      </ComponentRestrictor>
    </Flex>
  );
};
