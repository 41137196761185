import type { HierarchicalOption, LabeledValue } from '@lama/contracts';
import { sosStatuses } from '@lama/business-service-client';
import {
  businessPositions,
  legalEntityTypes,
  states,
  naicsCodes,
  fraudCheckValues,
  idCardTypes,
  matchValues,
  searchStatusValues,
  collateralTypes,
  collateralTypesSpecifications,
  vehicleCollateralTypes,
  valuationMethods,
  ownershipStatuses,
  securedByTypes,
  debtTypes,
  occupancyTypes,
  useOfFunds,
  useOfFundsGateCity,
  useOfFundsSouthState,
  occupations,
  citizenships,
  collateralConditionTypes,
  industryTypes,
  employeePlans,
  militaryVeteranStatuses,
  genders,
  ethnicities,
  races,
  hmdaRaceCollected,
  countries,
  vehicleBodyStyles,
  rateTypes,
  paymentPeriod,
  employmentStatuses,
  residentalStatuses,
  loanTypes,
  vehicleLoanTypes,
  taxEntityTypes,
  hmdaLoanTypes,
  hmdaLoanPurpose,
  hmdaPreapproval,
  hmdaConstructionMethods,
  hmdaOccupancyTypes,
  hmdaTypeOfPurchaser,
  hmdaActionTaken,
  hmdaBalloonPayment,
  hmdaHoepaStatus,
  hmdaInterestOnlyPayments,
  hmdaNegativeAmortization,
  hmdaOtherNonAmortizingFeatures,
  hmdaManufacturedHomeSecuredPropertyTypes,
  hmdaManufacturedHomeLandPropertyInterest,
  hmdaSubmissionOfApplication,
  hmdaInitiallyPayable,
  hmdaReverseMortgage,
  hmdaOpenEndLineOfCredit,
  hmdaBusinessOrCommercialPurpose,
  hmdaEthnicities,
  hmdaRaces,
  hmdaSex,
  hmdaSexCollected,
  hmdaLienStatus,
  hmdaEthnicityCollected,
  propertyTypes,
  ownershipTypes,
  hmdaRacesHeirarchical,
  hmdaEthnicitiesHeirarchical,
  applicationSubmissionMethodOptions,
} from '@lama/contracts';

export const sourceToValues: Record<string, LabeledValue[] | string[] | readonly string[]> = {
  businessPositions,
  legalEntityTypes,
  states: states.map((s) => s.name),
  naicsCodes,
  channels: ['Phone', 'Email', 'Website', 'Social Media', 'In Person', 'Other'],
  fraudCheckValues,
  searchValues: searchStatusValues,
  matchValues,
  identificationCardTypes: idCardTypes,
  sosStatuses,
  collateralTypes,
  collateralTypesSpecifications,
  valuationMethods,
  ownershipStatuses,
  securedByTypes,
  debtTypes,
  useOfFunds,
  useOfFundsGateCity,
  useOfFundsSouthState,
  occupancyTypes,
  occupations,
  citizenships,
  collateralConditionTypes,
  industryTypes,
  employeePlans,
  militaryVeteranStatuses,
  genders,
  ethnicities,
  races,
  countries,
  vehicleBodyStyles,
  rateTypes,
  paymentPeriod,
  employmentStatuses,
  residentalStatuses,
  loanTypes,
  vehicleCollateralTypes,
  vehicleLoanTypes,
  taxEntityTypes,
  hmdaLoanTypes,
  hmdaLoanPurpose,
  hmdaPreapproval,
  hmdaConstructionMethods,
  hmdaOccupancyTypes,
  hmdaTypeOfPurchaser,
  hmdaActionTaken,
  hmdaBalloonPayment,
  hmdaHoepaStatus,
  hmdaInterestOnlyPayments,
  hmdaNegativeAmortization,
  hmdaOtherNonAmortizingFeatures,
  hmdaManufacturedHomeSecuredPropertyTypes,
  hmdaManufacturedHomeLandPropertyInterest,
  hmdaSubmissionOfApplication,
  hmdaInitiallyPayable,
  hmdaReverseMortgage,
  hmdaOpenEndLineOfCredit,
  hmdaBusinessOrCommercialPurpose,
  hmdaEthnicities,
  hmdaRaces,
  hmdaRaceCollected,
  hmdaSex,
  hmdaSexCollected,
  hmdaLienStatus,
  hmdaEthnicityCollected,
  propertyTypes,
  ownershipTypes,
  applicationSubmissionMethodOptions,
};

export const sourceToHeirarchicalValues: Record<string, HierarchicalOption[]> = {
  hmdaRaces: hmdaRacesHeirarchical,
  hmdaEthnicities: hmdaEthnicitiesHeirarchical,
};
