import { useAsync } from 'react-use';
import { useRecoilState } from 'recoil';
import { partnerState } from '../state/appState';
import { getPartnerFromUrl } from './react-query/usePartnerFromSubdomainQuery';

export const usePartnerFromSubdomain = () => {
  const [partner, setPartner] = useRecoilState(partnerState);

  useAsync(async () => {
    if (!partner) {
      const partnerEntityFromBackend = await getPartnerFromUrl(window.location);
      if (partnerEntityFromBackend) {
        setPartner(partnerEntityFromBackend);
      }
    }
  }, [partner, setPartner]);

  return partner;
};
