/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { AccountBalanceRounded } from '@mui/icons-material';
import React, { useCallback, useContext, useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useIsMobile, LoadingPage } from '@lama/app-components';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { useGetCurrentRequirement } from '../../hooks/useGetCurrentRequirement';
import { usePlaid } from '../../hooks/usePlaid';
import type { ScreenProps } from '../ScreenProps';
import { BasicScreen } from '../shared/BasicScreen';
import { ConnectedAccountsView } from '../shared/ConnectedAccountsView';
import { NoConnectedAccountsView } from '../shared/NoConnectedAccountsView';
import { ApplicationContext } from '../../shared/contexts/ApplicationContext';
import { useDocumentsQuery } from '../../hooks/react-query/useDocumentsQuery';
import { useBankAccountsQuery } from '../../hooks/react-query/useBankAccountsQuery';
import { isDocumentRelatedToRequirement } from '../../shared/utils/isDocumentRelatedToRequirement';
import { BankStatementsIcon } from './BankStatementsIcon';
import { UploadBankStatements } from './UploadBankStatements';

interface BankStatementsScreenProps extends ScreenProps {
  titleOverride?: string;
  subtitleOverride?: string;
  allowUpload?: boolean;
}

export const BankStatementsScreen: React.FC<BankStatementsScreenProps> = ({
  nextEnabled,
  flow,
  titleOverride,
  subtitleOverride,
  allowUpload = true,
  ...rest
}) => {
  const isMobile = useIsMobile();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [fillingOption, setFillingOption] = useState<'auto' | 'manual' | null>(null);
  const requirement = useGetCurrentRequirement();
  const { application } = useContext(ApplicationContext);
  const business = useMemo(
    () => application.relatedBusinesses.find(({ business: { id } }) => id === requirement?.entityId)?.business,
    [application, requirement],
  );
  const { data: plaidAccounts, isPending: loadingAccounts } = useBankAccountsQuery(business?.id);
  const { data: documents, isPending: loadingDocuments } = useDocumentsQuery(application.id);

  const accounts = useMemo(
    () =>
      plaidAccounts?.map((account) => ({
        id: account.mask ?? account.accountId,
        name: [account.name[0], account.name[1]],
        title: account.name,
      })) ?? [],
    [plaidAccounts],
  );

  const relatedDocuments = useMemo(
    () => (requirement && documents?.length ? documents.filter((doc) => isDocumentRelatedToRequirement(doc, requirement)) : []),
    [requirement, documents],
  );
  const queryClient = useQueryClient();

  const onPlaidSuccess = useCallback(async () => {
    if (business) {
      await queryClient.invalidateQueries({
        queryKey: ['bankAccounts', business.id],
      });
      await queryClient.invalidateQueries({
        queryKey: ['applicationRequirements', application.id],
      });
    }
  }, [application.id, business, queryClient]);

  const { open, ready, init } = usePlaid(onPlaidSuccess, business?.id);

  useEffect(() => {
    if (!ready) {
      void init();
    }
  }, [init, ready]);

  const handleConnectBankClick = useCallback(() => {
    open();
  }, [open]);

  if (loadingAccounts || loadingDocuments) {
    return <LoadingPage />;
  }

  if (fillingOption === 'manual' || (relatedDocuments?.length && fillingOption === null)) {
    if (!requirement) {
      navigate('../overview');
      return null;
    }
    return <UploadBankStatements requirement={requirement} setFillingOption={setFillingOption} flow={flow} />;
  }

  return (
    <BasicScreen
      {...rest}
      flow={flow}
      title={titleOverride ?? t('bankStatements.title')}
      subtitle={subtitleOverride ?? (isMobile ? t('bankStatements.mobile.subtitle') : t('bankStatements.subtitle'))}
      nextVisible={flow === 'onboarding'}
    >
      {accounts.length ? (
        <ConnectedAccountsView
          accounts={accounts}
          addAccount={handleConnectBankClick}
          addAccountText={isMobile ? t('bankStatements.mobile.addAccount') : t('bankStatements.addAccount')}
        />
      ) : (
        <NoConnectedAccountsView
          connectIcon={<AccountBalanceRounded />}
          connectText={isMobile ? t('bankStatements.mobile.connect') : t('bankStatements.connect')}
          emptyLogo={<BankStatementsIcon />}
          connectAccount={handleConnectBankClick}
          setFillingOption={setFillingOption}
          allowUpload={allowUpload}
        />
      )}
    </BasicScreen>
  );
};
