import { ToggleButton, styled, Typography, ToggleButtonGroup } from '@mui/material';
import { useIsMobile } from '@lama/app-components';
import React from 'react';

interface Option {
  value: string;
  text: string;
  disabled?: boolean;
}

interface ConnectionToggleProps {
  value: string;
  onToggle: (value: string) => void;
  optionA: Option;
  optionB: Option;
}

const StyledToggleButton = styled(ToggleButton)({
  width: '80px',
  height: '24px',
  backgroundColor: '#EEEEEE',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '&.hover': {
    backgroundColor: '#EEEEEE',
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '&.Mui-selected, &.Mui-selected:hover': {
    backgroundColor: 'white',
    zIndex: 2,
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '&.MuiToggleButtonGroup-grouped': {
    borderRadius: '20px !important',
    border: '1px solid rgb(0, 0, 0, 0.12) !important',
  },
});

export const ConnectionToggle: React.FC<ConnectionToggleProps> = ({ value, onToggle, optionA, optionB }) => {
  const isMobile = useIsMobile();

  const internalOnToggle = React.useCallback(
    (_event: React.MouseEvent<HTMLElement>, internalValue: string | null) => {
      if (internalValue) {
        onToggle(internalValue);
      }
    },
    [onToggle],
  );

  return (
    <ToggleButtonGroup value={value} exclusive onChange={internalOnToggle} sx={{ minWidth: '200px' }}>
      <StyledToggleButton value={optionA.value} sx={{ marginRight: '-26px', width: isMobile ? '100%' : '100px', height: '32px' }}>
        <Typography variant={'subtitle2'}>{optionA.text}</Typography>
      </StyledToggleButton>
      <StyledToggleButton value={optionB.value} sx={{ width: isMobile ? '100%' : '110px', height: '32px' }} disabled>
        <Typography variant={'subtitle2'}>{optionB.text}</Typography>
      </StyledToggleButton>
    </ToggleButtonGroup>
  );
};
