import React, { useContext, useMemo } from 'react';
import type { Alert } from '@lama/alerts-service-client';
import { Flex, Text } from '@lama/design-system';
import { useIsMobile } from '@lama/app-components';
import { useTranslation } from 'react-i18next';
import { ThankYouScreen } from '../thankYou/ThankYouScreen';
import { ApplicationContext } from '../../shared/contexts/ApplicationContext';
import { UserDetailsContext } from '../../shared/contexts/UserDetailsContext';
import { ApplicationAlerts } from './ApplicationAlerts';

export const AllRequirementsSubmittedScreen: React.FC<{ alerts: Alert[] }> = ({ alerts }) => {
  const { application } = useContext(ApplicationContext);
  const userDetails = useContext(UserDetailsContext);
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const firstName = useMemo(() => userDetails?.firstName ?? 'applicant', [userDetails]);

  if (!alerts.length) {
    return <ThankYouScreen firstName={firstName} />;
  }

  return (
    <Flex flexDirection={'column'} width={'100%'} gap={8}>
      <Flex flexDirection={'column'} gap={4} alignItems={isMobile ? 'center' : 'left'} width={'100%'}>
        <Text variant={isMobile ? 'h5' : 'h4'} textAlign={isMobile ? 'center' : 'left'}>
          {t('allRequirementsSubmitted.title', { firstName })}
        </Text>
        <Text variant={'body1'} textAlign={isMobile ? 'center' : 'left'}>
          {t('allRequirementsSubmitted.alertsSubtitle')}
        </Text>
      </Flex>
      <ApplicationAlerts applicationId={application.id} alerts={alerts ?? []} />
    </Flex>
  );
};
