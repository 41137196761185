import { Flex } from '@lama/design-system';
import type { FC } from 'react';
import React, { useMemo } from 'react';
import { useIsMobile } from '@lama/app-components';
import { useRecoilValue } from 'recoil';
import { useLocation } from 'react-router-dom';
import { AccountMenu } from '../managementScreen/AccountMenu';
import { getLogoUrl } from '../../shared/utils/getLogoUrl';
import { partnerState } from '../../state/appState';

interface SimpleHeaderProps {
  showAccountSettings: boolean;
}

export const SimpleHeader: FC<SimpleHeaderProps> = ({ showAccountSettings }) => {
  const isMobile = useIsMobile();
  const partner = useRecoilValue(partnerState);
  const { search } = useLocation();

  const logoUrl = useMemo(() => {
    const themeOverrideParamValue = partner?.branding.themeOverrideParamName
      ? new URLSearchParams(search).get(partner?.branding.themeOverrideParamName)
      : null;
    return getLogoUrl(partner, themeOverrideParamValue, isMobile ? 'small' : 'big');
  }, [isMobile, partner, search]);

  return (
    <Flex justifyContent={'space-between'} alignItems={'center'} p={4} borderBottom={'1px solid #E0E0E0'}>
      <Flex>
        <img height={'28px'} src={logoUrl} />
      </Flex>
      <AccountMenu showAccountSettings={showAccountSettings} popoverLocation={{ horizontal: 'center', vertical: 'bottom' }} />
    </Flex>
  );
};
