import React, { useContext } from 'react';
import { Button, Flex, Modal, Text, ModalContent, ModalFooter } from '@lama/design-system';
import { useIsMobile } from '../../hooks/useIsMobile.js';
import { ConfirmationModalContext } from './ConfirmationModalContext.js';

export const ConfirmationModal: React.FC = () => {
  const { modalOpen, cancel, confirm, modalProps } = useContext(ConfirmationModalContext);
  const isMobile = useIsMobile();

  return (
    <Modal
      key={`confirmation-modal-${modalProps.title}`}
      title={modalProps.title}
      open={modalOpen}
      onClose={cancel}
      aria-labelledby={'alert-dialog-title'}
      aria-describedby={'alert-dialog-description'}
      hideCloseButton
    >
      <Flex flexDirection={'column'} minWidth={isMobile ? '80vw' : 400}>
        {modalProps.message ? (
          <ModalContent>
            <Text variant={'body1'}>{modalProps.message}</Text>
          </ModalContent>
        ) : null}
        <ModalFooter>
          <Flex gap={2}>
            <Button variant={'tertiary'} color={'neutral'} onClick={cancel}>
              {modalProps.cancelText}
            </Button>
            <Button variant={'primary'} color={modalProps.type === 'danger' ? 'danger' : 'primary'} onClick={confirm}>
              {modalProps.confirmText}
            </Button>
          </Flex>
        </ModalFooter>
      </Flex>
    </Modal>
  );
};
