import type { ProductPublicData } from '@lama/contracts';
import { Flex } from '@lama/design-system';
import type { FC } from 'react';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { kebabCase } from 'lodash-es';
import { useIsMobile } from '@lama/app-components';
import { useSetRecoilState } from 'recoil';
import { ChevronRight } from '@mui/icons-material';
import { Button } from '@mui/material';
import { BasicScreen } from '../shared/BasicScreen';
import { LoginSubmittedIcon } from '../loginScreen/LoginSubmittedIcon';
import { currentOnboardingApplicationIdState } from '../../state/appState';

export const OnboardingIncompleteScreen: FC<{ product: ProductPublicData; applicationId: string }> = ({ product, applicationId }) => {
  const isMobile = useIsMobile();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const setCurrentOnboardingApplicationId = useSetRecoilState(currentOnboardingApplicationIdState);

  const onContinueClick = useCallback(() => {
    setCurrentOnboardingApplicationId(applicationId);
    const firstOnboardingFlowStepUrl = `/products/${product.name}/onboarding/${kebabCase(
      product.screensConfiguration.flowSteps[0]?.[0]?.toString() ?? '',
    )}`;
    navigate(firstOnboardingFlowStepUrl, { replace: true });
  }, [applicationId, navigate, product.name, product.screensConfiguration.flowSteps, setCurrentOnboardingApplicationId]);

  return (
    <Flex flexDirection={'column'} minHeight={'100vh'} alignItems={'center'} justifyContent={'center'}>
      <Flex flexDirection={'column'}>
        <BasicScreen
          title={t('onboardingIncomplete.title')}
          subtitle={t('onboardingIncomplete.subtitle')}
          nextButtonText={'Continue'}
          backVisible={false}
          nextVisible={false}
          flow={'onboarding'}
        >
          <Flex flexDirection={'column'} alignItems={'center'}>
            <LoginSubmittedIcon />
            <Button endIcon={<ChevronRight />} variant={'contained'} onClick={onContinueClick} sx={{ width: isMobile ? '100%' : '296px' }}>
              {t('onboardingIncomplete.cta')}
            </Button>
          </Flex>
        </BasicScreen>
      </Flex>
    </Flex>
  );
};
