import type { FC, ReactNode } from 'react';
import React, { useContext, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { useIsMobile } from '@lama/app-components';
import { useLocation } from 'react-router-dom';
import { partnerState } from '../../../state/appState';
import { useSentryIdentification } from '../hooks/useSentryIdentification';
import { UserDetailsContext } from '../../../shared/contexts/UserDetailsContext';
import { getLogoUrl } from '../../../shared/utils/getLogoUrl';
import { DesktopLayout } from './DesktopLayout';
import { MobileLayout } from './MobileLayout';

export const Layout: FC<{ children: ReactNode }> = ({ children }) => {
  const isMobile = useIsMobile();
  const partner = useRecoilValue(partnerState);
  const { search } = useLocation();

  const logoUrl = useMemo(() => {
    const themeOverrideParamValue = partner?.branding.themeOverrideParamName
      ? new URLSearchParams(search).get(partner?.branding.themeOverrideParamName)
      : null;
    return getLogoUrl(partner, themeOverrideParamValue, isMobile ? 'small' : 'big');
  }, [isMobile, partner, search]);

  const user = useContext(UserDetailsContext);

  useSentryIdentification(user, partner?.name);

  if (isMobile) {
    return <MobileLayout logoUrl={logoUrl}>{children}</MobileLayout>;
  }

  return <DesktopLayout logoUrl={logoUrl}>{children}</DesktopLayout>;
};
