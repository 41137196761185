import React, { useCallback } from 'react';
import type { FC } from 'react';
import { BaseDialog, PropertyFormikInput, FormikPicker, ModifyItemButton, useIsMobile } from '@lama/app-components';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { dismissDescriptionValidation, dismissReasonValidation } from '@lama/yup-validations';
import { Flex } from '@lama/design-system';
import { reasons } from './types/reasons';

const initialValues = {
  reason: '',
  description: '',
};

const validationSchema = yup.object({
  reason: dismissReasonValidation,
  description: dismissDescriptionValidation,
});

export type DismissWithReasonDialogValues = typeof initialValues;

interface DismissWithReasonDialogProps {
  open: boolean;
  handleClose: (values: DismissWithReasonDialogValues) => Promise<void> | void;
  title?: string;
}

export const DismissWithReasonDialog: FC<DismissWithReasonDialogProps> = ({ open, handleClose, title }) => {
  const isMobile = useIsMobile();
  const { t } = useTranslation();

  const onSubmitInternal = useCallback(
    (values: DismissWithReasonDialogValues) => {
      void handleClose(values);
    },
    [handleClose],
  );

  return (
    <BaseDialog open={open} onClose={handleClose} title={title ?? t('dismissRequirement.dialog.title')}>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmitInternal}>
        {({ handleSubmit }) => (
          <Flex flexDirection={'column'} width={'100%'} gap={4}>
            <FormikPicker name={'reason'} label={'Reason'} values={reasons} fullWidth />
            <PropertyFormikInput name={'description'} label={'Description'} fullWidth multiline minRows={2} maxRows={2} />
            <Flex pt={isMobile ? 1 : 2} justifyContent={'flex-end'}>
              <ModifyItemButton text={'Dismiss'} onClick={handleSubmit} color={'error'} showIcon={false} />
            </Flex>
          </Flex>
        )}
      </Formik>
    </BaseDialog>
  );
};
