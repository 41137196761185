import { Flex, Text } from '@lama/design-system';
import { LoadingButton } from '@mui/lab';
import { TextField } from '@mui/material';
import type { ChangeEvent, FC } from 'react';
import { useIsMobile } from '@lama/app-components';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSendOtpMutation } from '../../../hooks/react-query/useSendOtpMutation';
import { ConnectionToggle } from '../../verification/ConnectionToggle';

// remove this once we have a way to verify phone numbers
const phoneVerificationEnabled = false;

export const SendOtpComponent: FC<{
  connection: 'email' | 'sms';
  email: string | null | undefined;
  phoneNumber: string | null | undefined;
  onConnectionToggle: (value: string) => void;
  onOtpSent: (to: string) => void;
  autoSend?: boolean;
}> = ({ connection, email, phoneNumber, onOtpSent, onConnectionToggle, autoSend }) => {
  const { t } = useTranslation();
  const [to, setTo] = useState(email ?? phoneNumber ?? '');
  const inputDisabled = useMemo(() => !!email || !!phoneNumber, [email, phoneNumber]);
  const isMobile = useIsMobile();
  const [sent, setSent] = useState(false);

  const { mutate: sendOtp, isPending: sendingOtp } = useSendOtpMutation({
    onSuccess: () => {
      onOtpSent(to);
    },
  });

  const onInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setTo(e.target.value);
  }, []);

  const onSendOtpClick = useCallback(async () => {
    sendOtp({ sendOtpRequest: { connection, to } });
    setSent(true);
  }, [connection, sendOtp, to]);

  useEffect(() => {
    if (autoSend && !sent && !sendingOtp) {
      void onSendOtpClick();
    }
  }, [autoSend, onSendOtpClick, sendingOtp, sent]);

  return (
    <Flex flexDirection={'column'} alignItems={'center'} gap={8}>
      <Text variant={'body1'}>{t('verification.subtitle.sendOtp')}</Text>
      {phoneVerificationEnabled ? (
        <ConnectionToggle
          value={connection}
          onToggle={onConnectionToggle}
          optionA={{ value: 'email', text: 'Email', disabled: !email }}
          optionB={{ value: 'sms', text: 'Mobile', disabled: !phoneNumber }}
        />
      ) : null}
      <TextField
        value={to}
        disabled={inputDisabled}
        label={connection === 'email' ? 'Email' : 'Phone Number'}
        name={connection === 'email' ? 'email' : 'phoneNumber'}
        onChange={onInputChange}
        placeholder={connection === 'email' ? 'Email' : 'Phone Number'}
        variant={'outlined'}
        sx={{ width: isMobile ? '100%' : '60%' }}
      />
      <LoadingButton variant={'contained'} color={'primary'} onClick={onSendOtpClick} loading={sendingOtp}>
        {t('verification.cta.sendOtp')}
      </LoadingButton>
    </Flex>
  );
};
