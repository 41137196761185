import type { FC } from 'react';
import React, { useCallback, useContext, useState } from 'react';
import type { Entity } from '@lama/common-types';
import type { RequirementProperty } from '@lama/contracts';
import type { SelectedEntity } from '@lama/fiserv-service-client';

import { Flex } from '@lama/design-system';
import type { CreateBusinessModelApi, DataProviderCredentials, UpdateBusinessRequest } from '@lama/business-service-client';
import type { ApplicationApiModel, PersonCreateApiModel, PersonUpdateApiModel } from '@lama/clients';
import { capitalCase } from 'change-case-all';
import type { FeatureConfigurations } from '@lama/partner-service-client';
import { BaseDialog, type DialogMode } from '../BaseDialog';
import { Toggle } from '../Toggle';
import { SearchExistingCustomersContext } from '../RelationsList';
import { AddOrCreateRelatedEntity } from '../RelationsList/AddOrCreateRelatedEntity';
import { GenericPropertiesProvider, customComponents } from '../GenericProperties';
import type { BusinessWithOwnership } from './BusinessForm';
import { BusinessForm } from './BusinessForm';
import { PersonForm } from './PersonForm';
import type { ExistingPrincipal, BusinessWithOwnershipRequest, ExistingPrincipalPerson } from './BasePrincipalsScreen';

interface AddOrEditDialogProps {
  open: boolean;
  handleClose: (values: ExistingPrincipal | null | undefined) => Promise<void>;
  createPerson?: ({ person }: { person: PersonCreateApiModel }) => Promise<string | undefined>;
  createBusiness?: ({
    business,
    dataProvidersCredentials,
    applicationId,
  }: {
    business: CreateBusinessModelApi;
    applicationId: string;
    dataProvidersCredentials?: DataProviderCredentials[];
  }) => Promise<void>;
  updatePerson?: ({ personId, updatePersonPayload }: { personId: string; updatePersonPayload: PersonUpdateApiModel }) => Promise<void>;
  updateBusiness?: ({
    businessId,
    updateBusinessPayload,
  }: {
    businessId: string;
    updateBusinessPayload: UpdateBusinessRequest;
  }) => Promise<void>;
  existingPrincipal: ExistingPrincipal | null;
  remainingOwnershipPercentage: number;
  requirementProperties: RequirementProperty[];
  isLoading?: boolean;
  dialogMode: DialogMode;
  dialogType: 'business' | 'person';
  existingEntityIds?: string[];
  application: ApplicationApiModel;
  partnerFeatureConfigurations: FeatureConfigurations;
  disableEmailUpdate?: boolean;
}

const toggleOptions = [
  { value: true, text: 'person' as const },
  { value: false, text: 'business' as const },
];
type PrincipleFormProps = Pick<
  AddOrEditDialogProps,
  | 'application'
  | 'dialogMode'
  | 'dialogType'
  | 'disableEmailUpdate'
  | 'existingPrincipal'
  | 'handleClose'
  | 'isLoading'
  | 'partnerFeatureConfigurations'
  | 'remainingOwnershipPercentage'
  | 'requirementProperties'
> & {
  showBackButton?: boolean;
  onBack?: () => void;
};
const PrincipalForm: FC<PrincipleFormProps> = ({
  handleClose,
  remainingOwnershipPercentage,
  partnerFeatureConfigurations,
  existingPrincipal,
  requirementProperties,
  isLoading,
  dialogType,
  dialogMode,
  disableEmailUpdate,
  showBackButton,
  application,
  onBack,
}) => {
  const onBusinessFormClose = useCallback(
    async (formValues: BusinessWithOwnership | null) => {
      const business = formValues
        ? {
            ...formValues.businessValues,
            ownershipPercentage: formValues.ownershipPercentage,
          }
        : null;

      await handleClose(business);
    },
    [handleClose],
  );

  return dialogType === 'business' ? (
    <BusinessForm
      business={existingPrincipal as BusinessWithOwnershipRequest}
      ownershipPercentage={existingPrincipal ? existingPrincipal.ownershipPercentage ?? 0 : undefined}
      remainingOwnershipPercentage={remainingOwnershipPercentage}
      handleClose={onBusinessFormClose}
      isLoading={isLoading}
      dialogMode={dialogMode}
      showBackButton={showBackButton}
      onBack={onBack}
    />
  ) : (
    <PersonForm
      existingPrincipal={existingPrincipal as ExistingPrincipalPerson}
      remainingOwnershipPercentage={remainingOwnershipPercentage}
      handleClose={handleClose}
      requirementProperties={requirementProperties}
      isLoading={isLoading}
      dialogMode={dialogMode}
      disableEmailUpdate={disableEmailUpdate}
      showBackButton={showBackButton}
      onBack={onBack}
      applicationId={application.id}
      partnerId={application.originatingPartner}
      markInviteByDefault={partnerFeatureConfigurations.inviteCollaboratorsByDefault ?? true}
    />
  );
};

interface AddPrincipalComponentProps extends AddOrEditDialogProps {
  onToggleChange: (value: boolean) => void;
}

interface CreateFormProps {
  handleClose: (values: ExistingPrincipal | null | undefined) => Promise<void>;
  remainingOwnershipPercentage: number;
  existingPrincipal: ExistingPrincipal | null;
  requirementProperties: RequirementProperty[];
  isLoading?: boolean;
  dialogType: 'business' | 'person';
  disableEmailUpdate?: boolean;
  onToggleChange?: (value: boolean) => void;
  showToggle?: boolean;
  onBack: () => void;
  application: ApplicationApiModel;
  partnerFeatureConfigurations: FeatureConfigurations;
}

const CreateForm = ({
  handleClose,
  remainingOwnershipPercentage,
  existingPrincipal,
  requirementProperties,
  isLoading,
  dialogType,
  disableEmailUpdate,
  onToggleChange,
  showToggle,
  onBack,
  application,
  partnerFeatureConfigurations,
}: CreateFormProps) => (
  <Flex flexDirection={'column'} alignItems={'center'} gap={6} justifyContent={'center'}>
    {showToggle && onToggleChange ? (
      <Toggle
        onChange={onToggleChange}
        optionA={{ value: true, text: 'Person' }}
        optionB={{ value: false, text: 'Business' }}
        value={dialogType === 'person'}
      />
    ) : null}
    <PrincipalForm
      handleClose={handleClose}
      remainingOwnershipPercentage={remainingOwnershipPercentage}
      existingPrincipal={existingPrincipal}
      requirementProperties={requirementProperties}
      isLoading={isLoading}
      dialogMode={'add'}
      dialogType={dialogType}
      disableEmailUpdate={disableEmailUpdate}
      showBackButton
      onBack={onBack}
      partnerFeatureConfigurations={partnerFeatureConfigurations}
      application={application}
    />
  </Flex>
);

const AddOrSearchPrincipalComponent: FC<AddPrincipalComponentProps> = ({
  existingPrincipal,
  requirementProperties,
  isLoading,
  application,
  partnerFeatureConfigurations,
  remainingOwnershipPercentage,
  handleClose,
  existingEntityIds,
  disableEmailUpdate,
  dialogType,
  onToggleChange,
}) => {
  const { addExistingEntityEnabled } = partnerFeatureConfigurations;
  const [step, setStep] = useState<'form' | 'selection'>(addExistingEntityEnabled ? 'selection' : 'form');
  const [existingPrincipalInner, setExistingPrincipalInner] = useState<ExistingPrincipal | null>(existingPrincipal);
  const { searchButtonVisible } = useContext(SearchExistingCustomersContext);

  const submitHandler = useCallback(
    async (values: Record<string, any>, entityType: Entity) => {
      setExistingPrincipalInner({ ...values, id: values.id ?? '' });
      onToggleChange(entityType === 'person');
      setStep('form');
    },
    [onToggleChange],
  );
  const onEntityConverted = useCallback(
    async (entity: SelectedEntity) => {
      if (!entity) {
        return;
      }
      if (entity.business) {
        return submitHandler(entity.business, 'business');
      }
      if (entity.person) {
        return submitHandler(entity.person, 'person');
      }
    },
    [submitHandler],
  );
  const onBack = useCallback(() => {
    setExistingPrincipalInner(null);
    setStep('selection');
  }, []);
  const onClose = useCallback(async () => {
    await handleClose(null);
  }, [handleClose]);
  return (
    <GenericPropertiesProvider customComponents={customComponents} customSourceToValues={{}}>
      {step === 'form' ? (
        <CreateForm
          handleClose={handleClose}
          application={application}
          partnerFeatureConfigurations={partnerFeatureConfigurations}
          remainingOwnershipPercentage={remainingOwnershipPercentage}
          existingPrincipal={existingPrincipalInner}
          requirementProperties={requirementProperties}
          isLoading={isLoading}
          dialogType={dialogType}
          disableEmailUpdate={disableEmailUpdate}
          showToggle={false}
          onBack={onBack}
        />
      ) : (
        <AddOrCreateRelatedEntity
          dialogMode={'add'}
          application={application}
          showEntityTypeToggle
          existingEntityIds={existingEntityIds}
          onSearchResultConverted={onEntityConverted}
          initialValues={existingPrincipalInner ?? undefined}
          entityType={dialogType}
          currentEntityProperties={requirementProperties}
          onToggleChange={onToggleChange}
          submitHandler={submitHandler}
          addExistingEntityEnabled={!!addExistingEntityEnabled}
          isLoading={isLoading}
          editSelectedEntity
          allowedEntityTypes={['business', 'person']}
          // eslint-disable-next-line react/jsx-no-bind, react/no-unstable-nested-components
          customForm={({ onBack: onBackInner }) => (
            <CreateForm
              handleClose={handleClose}
              application={application}
              partnerFeatureConfigurations={partnerFeatureConfigurations}
              remainingOwnershipPercentage={remainingOwnershipPercentage}
              existingPrincipal={existingPrincipalInner}
              requirementProperties={requirementProperties}
              isLoading={isLoading}
              dialogType={dialogType}
              disableEmailUpdate={disableEmailUpdate}
              showToggle
              onToggleChange={onToggleChange}
              onBack={onBackInner ?? onBack}
            />
          )}
          onClose={onClose}
          searchButtonVisible={searchButtonVisible}
        />
      )}
    </GenericPropertiesProvider>
  );
};

const EditPrincipalComponent: FC<AddOrEditDialogProps> = (props) => <PrincipalForm {...props} />;

export const AddOrEditPrincipalDialog: FC<AddOrEditDialogProps> = (props) => {
  const { open, handleClose, dialogMode, dialogType } = props;
  const [formType, setFormType] = useState<'business' | 'person'>(dialogType);

  const onToggleChange = useCallback((value: boolean) => {
    setFormType(toggleOptions.find((option) => option.value === value)?.text ?? 'person');
  }, []);

  return (
    <BaseDialog
      open={open}
      onClose={handleClose}
      title={`${capitalCase(dialogMode)} Principal`}
      data-testid={'add-or-edit-principal-dialog'}
    >
      {dialogMode === 'add' ? (
        <AddOrSearchPrincipalComponent {...props} dialogType={formType} onToggleChange={onToggleChange} />
      ) : (
        <EditPrincipalComponent {...props} dialogType={formType} />
      )}
    </BaseDialog>
  );
};
