import React, { useMemo } from 'react';
import type { FC } from 'react';
import type { RequirementProperty } from '@lama/contracts';
import { useTranslation } from 'react-i18next';
import { Flex, greyPalette, RichInput } from '@lama/design-system';

export const GenericRichText: FC<{
  property: RequirementProperty;
}> = ({ property: { fieldName } }) => {
  const { t } = useTranslation();
  const value = useMemo(() => t(`genericRichText.${fieldName}`), [fieldName, t]);

  return (
    <Flex border={'1px solid'} borderColor={greyPalette[300]} borderRadius={'4px'} pl={4} py={2} mb={2}>
      <RichInput value={value} allowEditing={false} maxHeight={'400px'} />
    </Flex>
  );
};
