import type { FC } from 'react';
import React, { useContext, useMemo } from 'react';
import { useIsMobile } from '@lama/app-components';
import { grey } from '@mui/material/colors';
import { useRecoilValue } from 'recoil';
import { Text, Flex } from '@lama/design-system';
import { personFullName } from '@lama/data-formatters';
import { partnerState } from '../../../state/appState';
import { UserDetailsContext } from '../../../shared/contexts/UserDetailsContext';
import { SimpleHeader } from '../../shared/SimpleHeader';

export const FirstBusinessBankDemoApplicationRejectionScreen: FC = () => {
  const isMobile = useIsMobile();
  const partner = useRecoilValue(partnerState);
  const userContext = useContext(UserDetailsContext);

  const personName = useMemo(
    () =>
      userContext?.firstName && userContext?.lastName
        ? personFullName(userContext as { firstName: string; middleName?: string; lastName: string })
        : 'applicant',
    [userContext],
  );

  return (
    <Flex width={'100%'} height={'100%'} flexDirection={'column'}>
      <SimpleHeader showAccountSettings />
      <Flex
        flexDirection={'column'}
        gap={10}
        alignItems={'center'}
        flex={isMobile ? 1 : 0.7}
        color={grey[500]}
        py={20}
        px={isMobile ? 0 : 4}
        justifyContent={'center'}
      >
        <Flex flexDirection={'column'} gap={8} alignItems={'center'} justifyContent={'center'}>
          <Text variant={'h4'} color={'black'} textAlign={'center'}>
            {"You don't meet the loan requirements"}
          </Text>
          <Flex
            flexDirection={'column'}
            gap={4}
            justifyContent={'center'}
            borderRadius={'8px'}
            border={isMobile ? 'none' : '1px solid #E0E0E0'}
            p={isMobile ? 0 : 8}
            width={isMobile ? '85%' : '75%'}
          >
            <Text variant={'body2'} color={'secondary'} textAlign={isMobile ? 'center' : 'inherit'}>
              {`Dear ${personName},`}
            </Text>
            <Text variant={'body2'} color={'secondary'} textAlign={isMobile ? 'center' : 'inherit'}>
              {
                "Thank you for your interest in First Business Bank's financial solutions. Our Accounts Receivable department is currently accepting online applications for business invoice factoring only, within eligible business industries. Based on the information you provided, our partner at Lama Banking will reach out to connect you with the best financial offerings, out of a network of 150 bank partners."
              }
            </Text>
            <Text variant={'body2'} color={'secondary'} textAlign={isMobile ? 'center' : 'inherit'}>
              {`Sincerely, ${partner?.displayName}, Member FDIC`}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
