/* eslint-disable unicorn/no-keyword-prefix */
import React, { useMemo } from 'react';
import { Grid } from '@mui/material';
import { Formik } from 'formik';
import * as yup from 'yup';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import type { RequirementProperty } from '@lama/contracts';
import {
  emailValidation,
  ficoValidation,
  firstNameValidation,
  lastNameValidation,
  ownershipPercentageValidation,
  phoneNumberValidation,
  positionValidation,
} from '@lama/yup-validations';
import { Button, Flex } from '@lama/design-system';
import { isNil } from 'lodash-es';
import { useAsyncFn } from 'react-use';
import type { DialogMode } from '../BaseDialog';
import { PropertyFormikInput, FormikEmailInputField, FormikPhoneNumberField, FormikPositionPicker, FormikCheckbox } from '../FormikFields';
import { ModifyItemButton } from '../ModifyItemButton';
import type { ExistingPrincipalPerson } from './BasePrincipalsScreen';

const FICO_SCORE_PROPERTY_KEY = 'people_ficoScore';
const PRINCIPALS_PROPERTY_KEY = 'people';
const AUTHORIZED_SIGNER_PROPERTY_KEY = 'people_isAuthorizedSigner';
const COMPANY_EXECUTIVE_PROPERTY_KEY = 'person_isExecutive';
const CONTROL_PERSON_PROPERTY_KEY = 'person_controlPerson';

interface PrincipalPersonFormValues {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  ficoScore: string;
  isPrimaryContact: boolean;
  ownershipPercentage: string;
  position: string;
  isExecutive: boolean;
  controlPerson: boolean;
  inviteToApplication: boolean;
}

interface PersonFormProps {
  handleClose: (values: ExistingPrincipalPerson | null) => Promise<void>;
  existingPrincipal: ExistingPrincipalPerson | null;
  remainingOwnershipPercentage: number;
  requirementProperties: RequirementProperty[];
  isLoading?: boolean;
  dialogMode: DialogMode;
  disableEmailUpdate?: boolean;
  showBackButton?: boolean;
  onBack?: () => void;
  applicationId: string;
  partnerId: string;
  markInviteByDefault: boolean;
}

export const PersonForm: React.FC<PersonFormProps> = ({
  existingPrincipal,
  remainingOwnershipPercentage,
  handleClose,
  requirementProperties,
  isLoading,
  dialogMode,
  disableEmailUpdate,
  showBackButton = false,
  markInviteByDefault = true,
  applicationId,
  partnerId,
  onBack,
}) => {
  const { t } = useTranslation();

  const validationSchema = yup.object({
    firstName: firstNameValidation,
    lastName: lastNameValidation,
    email: emailValidation,
    position: positionValidation,
    phoneNumber: phoneNumberValidation.required('Required'),
    ownershipPercentage: ownershipPercentageValidation({
      maxValue: remainingOwnershipPercentage + (existingPrincipal?.ownershipPercentage ?? 0),
    }).required('Required'),
    ficoScore: ficoValidation,
    inviteToApplication: yup.boolean(),
  });

  const principalsProperty = useMemo(
    () => requirementProperties.find((p) => p.propertyKey === PRINCIPALS_PROPERTY_KEY),
    [requirementProperties],
  );

  const renderFicoScore = useMemo(
    () => !!principalsProperty?.childProperties?.find((p) => p.propertyKey === FICO_SCORE_PROPERTY_KEY),
    [principalsProperty],
  );

  const renderCompanyExecutive = useMemo(
    () => !!principalsProperty?.childProperties?.find((cp) => cp.propertyKey === COMPANY_EXECUTIVE_PROPERTY_KEY),
    [principalsProperty],
  );

  const renderControlPerson = useMemo(
    () => !!principalsProperty?.childProperties?.find((cp) => cp.propertyKey === CONTROL_PERSON_PROPERTY_KEY),
    [principalsProperty],
  );

  const renderAuthorizedSigner = useMemo(
    () => !!principalsProperty?.childProperties?.find((cp) => cp.propertyKey === AUTHORIZED_SIGNER_PROPERTY_KEY),
    [principalsProperty],
  );

  const [{ loading: submitting }, onSubmitInternal] = useAsyncFn(
    async ({ ficoScore, position, ...values }: PrincipalPersonFormValues) => {
      const newValues = {
        id: existingPrincipal?.id ?? uuidv4(),
        ...values,
        ownershipPercentage: !isNil(values.ownershipPercentage) ? Number(values.ownershipPercentage) : null,
        ...(renderFicoScore ? { ficoScore: ficoScore ? Number(ficoScore) : null } : {}),
        ...(position ? { position } : {}),
        applicationId,
        partnerId,
      };
      await handleClose(newValues);
    },
    [handleClose],
  );

  const initialValues: PrincipalPersonFormValues = useMemo(
    () => ({
      firstName: existingPrincipal?.firstName ?? '',
      lastName: existingPrincipal?.lastName ?? '',
      email: existingPrincipal?.email ?? '',
      phoneNumber: existingPrincipal?.phoneNumber ?? '',
      ficoScore: existingPrincipal?.ficoScore?.toString() ?? '',
      isPrimaryContact: existingPrincipal?.isPrimaryContact ?? false,
      ownershipPercentage: dialogMode === 'add' ? '' : existingPrincipal?.ownershipPercentage?.toString() ?? '',
      position: dialogMode === 'add' ? '' : existingPrincipal?.position ?? '',
      isExecutive: dialogMode === 'add' ? false : existingPrincipal?.isExecutive ?? false,
      controlPerson: dialogMode === 'add' ? false : existingPrincipal?.controlPerson ?? false,
      inviteToApplication: dialogMode === 'add' && markInviteByDefault,
      isAuthorizedSigner: dialogMode === 'add' ? false : existingPrincipal?.isAuthorizedSigner ?? false,
    }),
    [existingPrincipal, markInviteByDefault, dialogMode],
  );

  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={onSubmitInternal}>
      {({ handleSubmit }) => (
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <PropertyFormikInput
              name={'firstName'}
              label={'First Name'}
              required
              fullWidth
              disabled={dialogMode === 'add' && !!initialValues.firstName}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <PropertyFormikInput
              name={'lastName'}
              label={'Last Name'}
              required
              fullWidth
              disabled={dialogMode === 'add' && !!initialValues.lastName}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <FormikEmailInputField
              name={'email'}
              disabled={(!!disableEmailUpdate || dialogMode === 'add') && !!initialValues.email}
              required
              fullWidth
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <FormikPhoneNumberField
              name={'phoneNumber'}
              required
              fullWidth
              disabled={dialogMode === 'add' && !!initialValues.phoneNumber}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <FormikPositionPicker name={'position'} fullWidth />
          </Grid>
          <Grid item md={6} xs={12}>
            <PropertyFormikInput name={'ownershipPercentage'} label={'% Ownership'} type={'number'} required fullWidth />
          </Grid>
          {renderFicoScore ? (
            <Grid item md={6} xs={12}>
              <PropertyFormikInput name={'ficoScore'} label={'FICO Score'} type={'number'} fullWidth />
            </Grid>
          ) : null}
          {renderCompanyExecutive ? (
            <Grid item md={6} xs={12}>
              <FormikCheckbox name={'isExecutive'} label={'Company Executive'} />
            </Grid>
          ) : null}
          {renderAuthorizedSigner ? (
            <Grid item md={6} xs={12}>
              <FormikCheckbox name={'isAuthorizedSigner'} label={'Authorized Signatory'} />
            </Grid>
          ) : null}
          {renderControlPerson ? (
            <Grid item md={6} xs={12}>
              <FormikCheckbox name={'controlPerson'} label={'Control Person'} />
            </Grid>
          ) : null}
          <Grid item md={6} xs={12}>
            <FormikCheckbox name={'isPrimaryContact'} label={'Primary Contact'} />
          </Grid>
          {dialogMode === 'add' ? (
            <Grid item xs={12} md={6}>
              <FormikCheckbox name={'inviteToApplication'} label={'Invite this person to collaborate'} />
            </Grid>
          ) : null}
          <Grid item xs={12} marginTop={2}>
            {showBackButton ? (
              <Flex marginTop={'auto'} fullWidth justifyContent={'space-between'}>
                <Button onClick={onBack} variant={'tertiary'}>
                  {'Back'}
                </Button>
                <ModifyItemButton
                  text={t(`principals.dialog.cta.${dialogMode}`)}
                  onClick={handleSubmit}
                  showIcon={false}
                  loading={isLoading}
                />
              </Flex>
            ) : (
              <Flex justifyContent={'center'}>
                <ModifyItemButton
                  onClick={handleSubmit}
                  text={t(`principals.dialog.cta.${dialogMode}`)}
                  showIcon={false}
                  loading={isLoading ?? submitting}
                />
              </Flex>
            )}
          </Grid>
        </Grid>
      )}
    </Formik>
  );
};
