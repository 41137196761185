import React, { useCallback } from 'react';
import { IconButton, Typography } from '@mui/material';
import { Avatar, Tooltip, useIsMobile } from '@lama/app-components';
import { useTranslation } from 'react-i18next';
import { DeleteOutlineRounded } from '@mui/icons-material';
import { capitalCase } from 'change-case';
import { Flex, Text } from '@lama/design-system';

interface ConnectedAccountCardProps {
  accountTitle: string;
  accountId: string;
  serviceFirstName: string;
  serviceLastName: string;
  serviceLogo?: string;
  deleteEnabled: boolean;
  deleteAccount?: (id: string) => void;
}

export const ConnectedAccountCard: React.FC<ConnectedAccountCardProps> = ({
  accountTitle,
  accountId,
  deleteEnabled,
  serviceFirstName,
  serviceLastName,
  serviceLogo,
  deleteAccount,
}) => {
  const isMobile = useIsMobile();
  const { t } = useTranslation();

  const onDelete = useCallback(() => {
    if (deleteAccount) {
      deleteAccount(accountId);
    }
  }, [accountId, deleteAccount]);

  return (
    <Flex
      gap={4}
      height={'100px'}
      px={isMobile ? 5 : 8}
      py={6}
      border={'1px solid #E0E0E0'}
      borderRadius={'4px'}
      flexDirection={'row'}
      width={'100%'}
      justifyContent={'space-between'}
    >
      <Flex flexDirection={'row'} alignItems={'center'} gap={4}>
        <Avatar firstName={serviceFirstName} lastName={serviceLastName} imgSrc={serviceLogo} imgSize={'32px'} size={48} />
        <Flex flexDirection={'column'}>
          <Typography variant={'h6'} maxWidth={'170px'} noWrap>
            {capitalCase(accountTitle)}
          </Typography>
          <Text variant={'body2'} color={'secondary'}>
            {`XXXX-${accountId.slice(-5)}`}
          </Text>
        </Flex>
      </Flex>
      <Flex flexDirection={'row'} alignItems={'center'}>
        {deleteEnabled ? (
          <Tooltip title={t('itemActions.delete')}>
            <IconButton onClick={onDelete} color={'primary'}>
              <DeleteOutlineRounded />
            </IconButton>
          </Tooltip>
        ) : null}
      </Flex>
    </Flex>
  );
};
