import React from 'react';
import { Tooltip } from '@mui/material';
import { Flex, Text, grayPalette, Avatar } from '@lama/design-system';
import { ItemActions } from '../ItemActions';
import { useIsMobile } from '../../hooks/useIsMobile';

interface OwnedBusinessCardProps {
  ownedBusinessName: string;
  ownershipPercentage: number | null;
  onDelete?: (id: string) => void;
  onEdit: (id: string) => void;
  id: string;
}

export const OwnedBusinessCard: React.FC<OwnedBusinessCardProps> = ({ ownedBusinessName, ownershipPercentage, onDelete, onEdit, id }) => {
  const isMobile = useIsMobile();

  return (
    <Flex
      gap={4}
      height={'100px'}
      px={isMobile ? 5 : 8}
      py={6}
      border={`1px solid ${grayPalette[300]}`}
      borderRadius={'4px'}
      flexDirection={'row'}
      width={'100%'}
      justifyContent={'space-between'}
    >
      <Flex flexDirection={'row'} alignItems={'center'} gap={4} minWidth={0}>
        <Avatar name={ownedBusinessName} />
        <Flex flexDirection={'column'} minWidth={0}>
          <Tooltip title={ownedBusinessName} placement={'top'}>
            <Text variant={'h6'} ellipsis>
              {ownedBusinessName}
            </Text>
          </Tooltip>
          <Text variant={'body2'} noWrap>
            {`Ownership: ${ownershipPercentage ? `${ownershipPercentage}%` : '-'}`}
          </Text>
        </Flex>
      </Flex>
      <ItemActions item={{ id }} onDeleteClick={onDelete} onEditClick={onEdit} deleteEnabled relation={'business'} />
    </Flex>
  );
};
