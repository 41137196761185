import type { FunctionComponent } from 'react';
import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { get } from 'lodash-es';
import { StyledDotLoader } from './DotLoader.styles';

export interface DotLoaderProps {
  color?: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'data-testid'?: string;
}

export const DotLoader: FunctionComponent<DotLoaderProps> = ({ color, ...props }) => {
  const { colors, dotLoader } = useContext(ThemeContext);

  const loaderColor = color ? get(colors, color) ?? color : dotLoader.color;

  return <StyledDotLoader color={loaderColor} data-testid={props['data-testid'] ?? 'dotLoader'} role={'progressbar'} {...props} />;
};

DotLoader.displayName = 'DotLoader';
