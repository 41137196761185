import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { usePartnerFromSubdomain } from './usePartnerFromSubdomain';

export const useBrandOverrides = () => {
  const partner = usePartnerFromSubdomain();
  const { search } = useLocation();
  const textsForPartner = useMemo(() => {
    if (!partner) {
      return null;
    }
    const themeOverrideParamValue = partner.branding.themeOverrideParamName
      ? new URLSearchParams(search).get(partner.branding.themeOverrideParamName)
      : null;
    if (!themeOverrideParamValue) {
      return null;
    }
    const additionalOverrides = partner?.branding.overridesByParamName?.[themeOverrideParamValue]?.additionalOverrides;
    if (!additionalOverrides) {
      return null;
    }
    return {
      getStartedSubtitleOverride: additionalOverrides.getStartedSubtitle,
      conclusionSubtitleOverride: additionalOverrides.conclusionSubtitle,
    };
  }, [partner, search]);
  return textsForPartner;
};
