import { useIsMobile } from '@lama/app-components';
import React from 'react';

interface BankStatementsIconProps {
  size?: string;
}

export const BankStatementsIcon: React.FC<BankStatementsIconProps> = ({ size = '40px' }) => {
  const isMobile = useIsMobile();

  const finalSize = isMobile ? '32px' : size;

  return (
    <svg width={finalSize} height={finalSize} viewBox={'0 0 40 40'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'}>
      <path
        fillRule={'evenodd'}
        clipRule={'evenodd'}
        d={
          'M40 15.8551L36.7915 3.47936L24.4681 0.0673968L20.1362 4.33024L15.8638 0L3.48085 3.20977L0.0680851 15.535L4.33191 19.8736L0 24.1365L3.20851 36.5122L15.5319 39.9242L19.8723 35.6613L24.1362 40L36.5191 36.7902L39.9319 24.465L35.6681 20.1264L40 15.8635V15.8551ZM36.1106 14.7767L33.217 17.6243C33.217 17.6243 29.1234 13.4709 29.1234 13.4625L34.4255 8.24768L36.1191 14.7683L36.1106 14.7767ZM30.7234 20.0758L26.5617 24.1702L22.4681 20.0084L26.6298 15.9141L30.7234 20.0758ZM25.4809 3.97641L31.9745 5.77085L26.6723 10.9857L22.5787 6.82393L25.4723 3.97641H25.4809ZM24.1787 13.4372L20.017 17.5316L15.9234 13.3698L20.0851 9.27548L24.1787 13.4372ZM17.5319 19.9832L13.3702 24.0775L9.2766 19.9158L13.4383 15.8214L17.5319 19.9832ZM14.783 3.89216L17.634 6.79023L13.4723 10.8846L8.25532 5.58551L14.7745 3.89216H14.783ZM3.97447 14.524L5.77021 8.03707L10.9787 13.3361L6.81702 17.4305L3.96596 14.5324L3.97447 14.524ZM3.88936 25.2148L6.79149 22.3673L10.8851 26.5291L5.59149 31.7439L3.89787 25.2233L3.88936 25.2148ZM14.5277 36.0236L8.03404 34.2291L13.3362 29.0143L17.4298 33.1761L14.5277 36.0236ZM15.8213 26.5628L19.983 22.4684L24.0766 26.6302L19.9149 30.7245L15.8213 26.5628ZM25.217 36.1078L22.366 33.2098L26.5277 29.1154L31.7447 34.4145L25.2255 36.1078H25.217ZM36.0255 25.476L34.2298 31.9714L29.0128 26.6723L33.1745 22.5779L36.0255 25.476Z'
        }
        fill={'#111111'}
      />
    </svg>
  );
};
