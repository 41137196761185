import type { ApplicationStatus } from '@lama/contracts';
import { Stack } from '@mui/material';
import type { FC } from 'react';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Flex, Text } from '@lama/design-system';
import { useToggle } from 'react-use';
import styled from 'styled-components';
import { formatValue } from '@lama/data-formatters';
import { useIsMobile } from '@lama/app-components';
import { ApplicationStatusChip } from '../shared/ApplicationStatusChip';

const HoverVisibilityContainer = styled(Flex)<{ isHover: boolean }>(
  ({ isHover }) => `
    visibility: ${isHover ? 'visible' : 'hidden'};
  `,
);

const LastVisibileContainer = styled(Flex)`
'&:hover div:last-of-type': {
  visibility: 'visible',
},
`;

interface ApplicationCardProps {
  productName: string;
  requestedAmount?: number;
  loanName?: string | null;
  applicationId: string;
  applicationStatus: ApplicationStatus;
}

export const ApplicationCard: FC<ApplicationCardProps> = ({ applicationId, requestedAmount, productName, loanName, applicationStatus }) => {
  const requestedAmountFormatted = useMemo(() => formatValue(requestedAmount ?? 0, 'currencyCompact'), [requestedAmount]);
  const [isHover, toggleIsHover] = useToggle(false);

  const isMobile = useIsMobile();

  return (
    <Stack
      flexGrow={1}
      border={1}
      borderRadius={'8px'}
      borderColor={'grey.300'}
      flexBasis={'49%'}
      maxWidth={isMobile ? '100%' : '48%'}
      minHeight={'120px'}
      onMouseEnter={toggleIsHover}
      onMouseLeave={toggleIsHover}
      component={Link}
      color={'text.primary'}
      to={`/applications/${applicationId}/overview`}
      sx={{ textDecoration: 'none' }}
      px={3}
      pt={3}
      pb={1}
    >
      <LastVisibileContainer flexDirection={'column'} justifyContent={'space-between'} alignItems={'flex-start'} gap={2} color={'#000000'}>
        <Flex flexDirection={'row'} gap={2} justifyContent={'space-between'} width={'100%'}>
          <Text variant={'h6'}>
            {`${requestedAmountFormatted ?? ''} ${productName.toLowerCase().includes('loan') ? productName : `${productName} Loan`}`}
          </Text>
          <ApplicationStatusChip status={applicationStatus} size={'small'} />
        </Flex>
        <Flex flexDirection={'column'} width={'100%'} flex={1}>
          <Text variant={'body1'} color={'grey.500'}>
            {loanName}
          </Text>
        </Flex>
      </LastVisibileContainer>
      <HoverVisibilityContainer
        flexDirection={'column'}
        isHover={isHover}
        flex={1}
        justifyContent={'flex-end'}
        alignItems={'flex-end'}
        width={'100%'}
      >
        <Text variant={'body2'} color={'primary.main'}>
          {'Go To Application'}
        </Text>
      </HoverVisibilityContainer>
    </Stack>
  );
};
