import { css } from 'styled-components';
import { isNumber as lodashIsNumber, isString, toNumber, isNaN } from 'lodash-es';
import type { Theme } from '../../theme';
import type { FlexProps } from './Flex.component';

export const isNumber = (variable: number | string | undefined) =>
  lodashIsNumber(variable) || (isString(variable) && !isNaN(toNumber(variable)));

export const gapStyle = ({ theme, gap }: FlexProps & { theme: Theme }) => {
  const themeSpace = theme.space;

  if (gap !== undefined) {
    return css`
      gap: ${isNumber(gap) ? `${themeSpace[toNumber(gap) ?? 6]}px` : (gap as string)};
    `;
  }

  return;
};
