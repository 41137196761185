/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';

interface SendIconProps {
  color?: string;
  size?: number;
}
export const SendIcon: React.FC<SendIconProps> = ({ color = 'white', size = 20 }) => (
  <svg width={size} height={size} viewBox={'0 0 20 21'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'}>
    <path
      d={
        'M4.35269 16.6444L17.0577 10.7673C17.6475 10.4923 17.6475 9.59656 17.0577 9.32156L4.35269 3.44444C3.87216 3.21658 3.34065 3.60158 3.34065 4.15944L3.33337 7.78156C3.33337 8.17442 3.60276 8.51228 3.96681 8.55942L14.2546 10.0444L3.96681 11.5216C3.60276 11.5765 3.33337 11.9144 3.33337 12.3073L3.34065 15.9294C3.34065 16.4872 3.87216 16.8722 4.35269 16.6444Z'
      }
      fill={color}
    />
  </svg>
);
