import React from 'react';
import { Tooltip } from '@mui/material';
import { Flex, Text, grayPalette } from '@lama/design-system';
import { Avatar } from '../Avatar';
import { ItemActions } from '../ItemActions';
import { useIsMobile } from '../../hooks/useIsMobile';

interface RelatedCompanyCardProps {
  name: string;
  ownerName: string;
  deleteEnabled: boolean;
  onDelete?: (id: string) => void;
  onEdit: (id: string) => void;
  id: string;
}

export const RelatedCompanyCard: React.FC<RelatedCompanyCardProps> = ({ name, ownerName, deleteEnabled, onDelete, onEdit, id }) => {
  const isMobile = useIsMobile();

  return (
    <Flex
      gap={4}
      height={'100px'}
      px={isMobile ? 5 : 8}
      py={6}
      border={`1px solid ${grayPalette[300]}`}
      borderRadius={'4px'}
      flexDirection={'row'}
      width={'100%'}
      justifyContent={'space-between'}
    >
      <Flex flexDirection={'row'} alignItems={'center'} gap={4} minWidth={0}>
        <Avatar firstName={name} lastName={''} />
        <Flex flexDirection={'column'} minWidth={0}>
          <Tooltip title={name} placement={'top'}>
            <Text variant={'h6'} ellipsis>
              {name}
            </Text>
          </Tooltip>
          <Tooltip title={ownerName} placement={'top'}>
            <Text variant={'body2'} noWrap>
              {`Owned by ${ownerName}`}
            </Text>
          </Tooltip>
        </Flex>
      </Flex>
      <ItemActions
        item={{ id }}
        onDeleteClick={deleteEnabled ? onDelete : undefined}
        onEditClick={onEdit}
        deleteEnabled={deleteEnabled}
        relation={'business'}
      />
    </Flex>
  );
};
