import React, { useMemo } from 'react';
import type { FC, ReactNode } from 'react';
import { ThemeProvider } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { usePartnerFromSubdomain } from '../../hooks/usePartnerFromSubdomain';
import { createThemeForPartner } from '../../theme/theme';

export const ThemeOverrideProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const partner = usePartnerFromSubdomain();
  const { search } = useLocation();
  const themeForPartner = useMemo(() => {
    if (!partner) {
      return {};
    }
    const themeOverrideParamValue = partner.branding.themeOverrideParamName
      ? new URLSearchParams(search).get(partner.branding.themeOverrideParamName)
      : null;
    return createThemeForPartner(partner.branding, themeOverrideParamValue);
  }, [partner, search]);

  return <ThemeProvider theme={themeForPartner}>{children}</ThemeProvider>;
};
