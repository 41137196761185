/* eslint-disable react/no-array-index-key */
import type { FC } from 'react';
import React, { useMemo } from 'react';
import type { DataNode, TextVariant } from '@lama/design-system';
import { Text, Flex, greyPalette } from '@lama/design-system';
import type { DocumentWithIssues } from '@lama/document-service-client';
import { formatValue } from '@lama/data-formatters';
import { zip } from 'lodash-es';
import { InfoOutlined } from '@mui/icons-material';
import { Tooltip } from '../Tooltip';
import { DocumentActions } from './DocumentActions';
import { PaperClipIcon } from './assets/PaperClipIcon';
import type { OnMetadataUpdateProps } from './types';
import Analyzing from './assets/analyzing.svg';
import SuccessfulExtraction from './assets/successfulExtraction.svg';

export interface TurnedInDocumentProps {
  document: DocumentWithIssues;
  onDelete?: (e: React.MouseEvent) => Promise<void>;
  onOpen: (e: React.MouseEvent) => void;
  onMetadataUpdate?: (metadata: OnMetadataUpdateProps) => Promise<void>;
  onDownloadDocument?: (e: React.MouseEvent) => void;
  moveToTreeData?: DataNode[];
  onMoveToClick?: (moveToNode: DataNode) => Promise<void>;
  showExtractionStatus: boolean;
}

const DocumentIcon: FC = () => (
  <Flex alignItems={'center'} justifyContent={'center'} minWidth={'34px'} minHeight={'34px'} width={'34px'} height={'34px'}>
    <PaperClipIcon color={greyPalette[700]} />
  </Flex>
);

const Separator: FC<{ variant: TextVariant }> = ({ variant }) => (
  <Text variant={variant} color={'secondary'}>
    {'•'}
  </Text>
);

const addSeparator = (parts: React.JSX.Element[], variant: TextVariant) =>
  zip(
    parts,
    Array.from({ length: parts.length - 1 }).map((_, i) => <Separator variant={variant} key={`separator-${variant}-${i}`} />),
  ).flat();

const DocumentDescription: FC<{ document: DocumentWithIssues }> = ({ document: { id: documentId, description, filename } }) => {
  const descriptionParts = useMemo(() => {
    const parts = [];
    if (description) {
      parts.push(
        <Text variant={'body1'} ellipsis key={`${documentId}-description`}>
          {description}
        </Text>,
      );
    }
    if (filename) {
      parts.push(
        <Text variant={'body1'} color={'secondary'} ellipsis key={`${documentId}-filename`}>
          {filename}
        </Text>,
      );
    }

    return addSeparator(parts, 'body1');
  }, [description, documentId, filename]);

  return (
    <Flex gap={1} alignItems={'center'} minWidth={0}>
      {descriptionParts}
    </Flex>
  );
};

const documentStatusToComponent = ({ extractable, status, issues }: DocumentWithIssues) => {
  if (!extractable) {
    return null;
  }

  if (issues?.length) {
    return (
      <Tooltip color={'warning'} title={issues[0]?.description} placement={'bottom'}>
        <Flex alignItems={'center'} gap={'2px'}>
          <InfoOutlined color={'warning'} sx={{ height: '12px', width: '12px' }} />
          <Text variant={'body4'} color={'secondary'} ellipsis>
            {'Document error'}
          </Text>
        </Flex>
      </Tooltip>
    );
  }

  switch (status) {
    case 'Uploaded': {
      return (
        <Flex alignItems={'center'} gap={'2px'}>
          <Analyzing />
          <Text variant={'body4'} color={'secondary'} ellipsis>
            {'Analyzing'}
          </Text>
        </Flex>
      );
    }
    case 'Processed': {
      return (
        <Flex alignItems={'center'} gap={'2px'}>
          <SuccessfulExtraction />
          <Text variant={'body4'} color={'secondary'} ellipsis>
            {'Successfully analyzed'}
          </Text>
        </Flex>
      );
    }
    default: {
      return null;
    }
  }
};

const DocumentDescriptionSubtitle: FC<{ document: DocumentWithIssues; showExtractionStatus: boolean }> = ({
  document,
  showExtractionStatus,
}) => {
  const { id: documentId, createdAt, uploaderId } = document;
  const descriptionSubtitleParts = useMemo(() => {
    const createdAtData = formatValue(createdAt, 'date');
    const createdAtDateTime = formatValue(createdAt, 'datetime');

    const parts = [
      <Tooltip title={createdAtDateTime} placement={'bottom'} key={`${documentId}-created-at`}>
        <Text variant={'body4'} color={'secondary'} ellipsis>
          {createdAtData}
        </Text>
      </Tooltip>,
    ];

    if (uploaderId === 'verify-business') {
      parts.push(
        <Text variant={'body4'} color={'secondary'} ellipsis key={`${documentId}-uploader`}>
          {'Pulled from SoS'}
        </Text>,
      );
    }

    if (showExtractionStatus) {
      const documentExtractionStatus = documentStatusToComponent(document);

      if (documentExtractionStatus) {
        parts.push(documentExtractionStatus);
      }
    }

    return addSeparator(parts, 'body4');
  }, [createdAt, document, documentId, showExtractionStatus, uploaderId]);

  return (
    <Flex gap={1} alignItems={'center'}>
      {descriptionSubtitleParts}
    </Flex>
  );
};

export const TurnedInDocument: FC<TurnedInDocumentProps> = ({
  document,
  onDelete,
  onOpen,
  onMetadataUpdate,
  onDownloadDocument,
  moveToTreeData,
  onMoveToClick,
  showExtractionStatus,
}) => {
  const { description, filename } = document;

  return (
    <Flex gap={4} alignItems={'center'} width={'100%'} minWidth={0}>
      <DocumentIcon />
      <Flex flexDirection={'column'} minWidth={0} gap={'2px'}>
        <DocumentDescription document={document} />
        <DocumentDescriptionSubtitle document={document} showExtractionStatus={showExtractionStatus} />
      </Flex>
      <Flex justifyContent={'flex-end'} flex={1}>
        <DocumentActions
          onDelete={onDelete}
          onOpen={onOpen}
          onDownloadDocument={onDownloadDocument}
          fileName={filename}
          placeholder={description}
          onMetadataUpdate={onMetadataUpdate}
          moveToTreeData={moveToTreeData}
          onMoveToClick={onMoveToClick}
        />
      </Flex>
    </Flex>
  );
};
