import { useCallback } from 'react';
import type { PartnerPublicDataApiModel } from '@lama/partner-service-client';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { partnerServiceClient } from '../../shared/clients/partnerService';
import { localPartner } from '../../framework/environment';

const LAMA_BANKING_DOMAIN = 'lamabanking.com';
export const getPartnerFromUrl = (location: Location) => {
  const [subdomain] = window.location.hostname.split('.');
  if (subdomain.includes('localhost')) {
    return partnerServiceClient.getPartnerPublicData(localPartner as string);
  }

  if (location.hostname.includes('netlify')) {
    return partnerServiceClient.getPartnerPublicData('testing');
  }

  if (subdomain.includes('application')) {
    return partnerServiceClient.getPartnerPublicData('default');
  }
  if (window.location.hostname.includes(LAMA_BANKING_DOMAIN)) {
    return partnerServiceClient.getPartnerPublicData(subdomain);
  }

  return partnerServiceClient.getPartnerPublicDataByDomain(window.location.hostname);
};

export const usePartnerFromSubDomainQuery = (options?: UseQueryOptions<PartnerPublicDataApiModel | null>) => {
  const getPartnerQuery = useCallback(async () => getPartnerFromUrl(window.location), []);

  return useQuery<PartnerPublicDataApiModel | null>({
    queryKey: ['domain', window.location.hostname],
    queryFn: getPartnerQuery,
    ...options,
  });
};
