import React, { useCallback, useMemo, useRef } from 'react';
import { FileUploadOutlined, MoreVert } from '@mui/icons-material';
import { IconButton, Menu } from '@mui/material';
import { Flex, greyPalette } from '@lama/design-system';
import type { DismissedSourceReason } from '@lama/contracts';
import { useAsyncFn, useToggle } from 'react-use';
import { Tooltip } from '../Tooltip';
import { RenameDocumentModal } from './RenameDocumentModal';
import type { OnMetadataUpdate } from './types';
import { RenameMenuItem } from './MenuItems/RenameMenuItem';
import { DismissMenuItem } from './MenuItems/DismissMenuItem';

export const menuProps = {
  anchorOrigin: {
    vertical: 'bottom' as const,
    horizontal: 'right' as const,
  },
  transformOrigin: {
    vertical: 'top' as const,
    horizontal: 'right' as const,
  },
  PaperProps: {
    elevation: 0,
    sx: {
      border: 0,
      py: 0,
      borderWidth: '1px',
      borderColor: greyPalette[300],
      boxShadow: '0px 11px 15px 0px #DBDBDB26',
    },
  },
};

interface ActionButtonsProps {
  description: string;
  onFileSelected?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onDismiss?: (value: string) => Promise<void>;
  onUndismiss?: (value: string) => Promise<void>;
  dismissed?: DismissedSourceReason;
  dismissible?: boolean;
  onMetadataUpdate?: OnMetadataUpdate;
}

export const NoDocumentActionsMenu: React.FC<ActionButtonsProps> = ({
  description,
  onFileSelected,
  onDismiss,
  onUndismiss,
  dismissible,
  onMetadataUpdate,
}) => {
  const ref = useRef<HTMLInputElement>(null);
  const menuRef = useRef<HTMLButtonElement>(null);
  const [menuOpen, toggleMenuOpen] = useToggle(false);
  const [renameModalOpen, toggleRenameModal] = useToggle(false);

  const [{ loading: dismissing }, handleDismiss] = useAsyncFn(async () => {
    toggleMenuOpen();
    await onDismiss?.(description);
  }, [description, onDismiss]);

  const dismissActionsAllowed = useMemo(() => dismissible && (!!onDismiss || !!onUndismiss), [dismissible, onDismiss, onUndismiss]);

  const onUploadClick = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      ref.current?.click();
    },
    [ref],
  );

  const cancelInputPropagation = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
  }, []);

  return (
    <>
      <Flex alignItems={'center'} gap={4} data-testid={'document-actions'}>
        <Tooltip title={'Upload'}>
          <IconButton size={'small'} onClick={onUploadClick} aria-label={'Upload'} role={'button'}>
            <FileUploadOutlined sx={{ color: greyPalette[500] }} />
            <input role={'textbox'} type={'file'} hidden onChange={onFileSelected} ref={ref} onClick={cancelInputPropagation} />
          </IconButton>
        </Tooltip>
        <Tooltip title={'Actions'}>
          <IconButton onClick={toggleMenuOpen} ref={menuRef} role={'button'}>
            <MoreVert />
          </IconButton>
        </Tooltip>
        <Menu key={'application-actions-menu'} anchorEl={menuRef.current} open={menuOpen} onClose={toggleMenuOpen} {...menuProps}>
          <RenameMenuItem onClick={toggleRenameModal} />
          <DismissMenuItem onClick={handleDismiss} disabled={!dismissActionsAllowed} loading={dismissing} />
        </Menu>
      </Flex>
      <RenameDocumentModal open={renameModalOpen} onClose={toggleRenameModal} onSubmit={onMetadataUpdate} placeholder={description} />
    </>
  );
};
