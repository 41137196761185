/* eslint-disable @typescript-eslint/naming-convention */
import React, { useCallback } from 'react';
import type { ReactNode } from 'react';
import { Box, Chip } from '@mui/material';
import { capitalCase } from 'change-case';
import { useIsMobile, Tooltip } from '@lama/app-components';
import { Flex, Text } from '@lama/design-system';
import styled from 'styled-components';
import { BusinessDefaultImage } from './BusinessDefaultImage';

interface BusinessCardProps {
  name: string;
  place_id: string;
  address: string;
  image?: string;
  tags: string[];
  empty?: boolean;
  selected?: boolean;
  onClick: (id: string) => void;
}

interface CardProps {
  children: ReactNode;
  selected?: boolean;
  onClick?: () => void;
}

interface EmptyCardProps {
  id: string;
  searchTerm: string;
  selected?: boolean;
  onClick: (id: string) => void;
}

const StyledCardContainer = styled(Flex)<{ selected?: boolean }>`
  flex-direction: row;
  border: 1px solid;
  border-radius: 10px;
  border-color: ${({ selected, theme }) => (selected ? theme.colors.primary.main : theme.colors.grey[300])};
  background-color: ${({ selected, theme }) => (selected ? theme.colors.primary.light : 'white')};
  &:hover {
    background-color: ${({ theme }) => theme.colors.grey[100]};
  }
`;

const Card: React.FC<CardProps> = ({ children, onClick, selected }) => {
  const isMobile = useIsMobile();

  const height = isMobile ? 132 : 152;

  return (
    <StyledCardContainer flexDirection={'column'} selected={selected} gap={4} p={4} height={height} onClick={onClick}>
      {children}
    </StyledCardContainer>
  );
};

export const EmptyCard: React.FC<EmptyCardProps> = ({ searchTerm, id, selected, onClick }) => {
  const onClickHandler = useCallback(() => {
    onClick(id);
  }, [id, onClick]);

  return (
    <Card selected={selected} onClick={onClickHandler}>
      <Flex flexDirection={'column'} justifyContent={'flex-start'} alignItems={'flex-start'} gap={1}>
        <Text variant={'body2'} textAlign={'center'}>
          {'Continue with'}
        </Text>
        <Text variant={'h6'} textAlign={'center'}>
          {`"${searchTerm}"`}
        </Text>
      </Flex>
    </Card>
  );
};

export const BusinessCard: React.FC<BusinessCardProps> = ({ place_id, name, address, image, tags, selected = false, onClick }) => {
  const isMobile = useIsMobile();

  const onClickHandler = useCallback(() => {
    if (onClick) {
      onClick(place_id);
    }
  }, [onClick, place_id]);

  return (
    <Card selected={selected} onClick={onClickHandler}>
      <Box>{image ? <img src={image} width={'64px'} height={'64px'} style={{ borderRadius: '10px' }} /> : <BusinessDefaultImage />}</Box>
      <Flex flexDirection={'column'} gap={2} height={'100%'} minWidth={0}>
        <Tooltip title={name}>
          <Text variant={'h6'} size={16} weight={700} color={'primary'} ellipsis>
            {name}
          </Text>
        </Tooltip>
        <Tooltip title={address}>
          <Text variant={'body2'} color={'secondary'} maxLines={isMobile ? 1 : 2}>
            {address}
          </Text>
        </Tooltip>
        <Flex flexDirection={'column'} flex={1} justifyContent={'flex-end'}>
          <Flex gap={2}>
            {tags
              ? tags.map((t) => (
                  <Chip
                    key={t}
                    variant={'outlined'}
                    label={capitalCase(t)}
                    color={'primary'}
                    sx={{ '&:hover': { cursor: 'pointer' } }}
                    size={'small'}
                  />
                ))
              : null}
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
};
