import type { FC } from 'react';
import React from 'react';
import type { DismissedSourceReason } from '@lama/contracts';
import type { DocumentWithIssues } from '@lama/document-service-client';
import { isNil } from 'lodash-es';
import type { DataNode } from '@lama/design-system';
import { Flex, greyPalette, Text } from '@lama/design-system';
import { Upload } from '@mui/icons-material';
import { TurnedInDocument } from './TurnedInDocument.js';
import { NoDocument } from './NoDocument.js';
import { DeletedDocument } from './DeletedDocument.js';
import type { OnMetadataUpdate } from './types.js';

interface DocumentProps {
  description?: string;
  document?: DocumentWithIssues;
  onDelete?: (e: React.MouseEvent) => Promise<void>;
  onOpen: (e: React.MouseEvent) => void;
  onFileSelected: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onDismissSource?: (name: string) => Promise<void>;
  onUndismissSource?: (name: string) => Promise<void>;
  onMoveToClick?: (moveToNode: DataNode) => Promise<void>;
  dismissed?: DismissedSourceReason;
  onDocumentMetadataUpdate?: OnMetadataUpdate;
  onDownloadDocument?: (e: React.MouseEvent) => void;
  dismissible?: boolean;
  uploadingDocument?: boolean;
  isDragActive?: boolean;
  documentTreeData?: DataNode[];
  showExtractionStatus: boolean;
}

export const DocumentCardContent: FC<DocumentProps> = ({
  onDelete,
  onOpen,
  document,
  onFileSelected,
  description,
  onDismissSource,
  onUndismissSource,
  onDocumentMetadataUpdate,
  documentTreeData,
  onDownloadDocument,
  dismissed,
  dismissible,
  onMoveToClick,
  isDragActive,
  showExtractionStatus,
}) => {
  if (document?.status === 'Deleted') {
    return <DeletedDocument document={document} onDownloadDocument={onDownloadDocument} />;
  }

  if (document) {
    return (
      <TurnedInDocument
        onMetadataUpdate={onDocumentMetadataUpdate}
        document={document}
        onDelete={onDelete}
        onOpen={onOpen}
        onDownloadDocument={onDownloadDocument}
        moveToTreeData={documentTreeData}
        onMoveToClick={onMoveToClick}
        showExtractionStatus={showExtractionStatus}
      />
    );
  }

  if (isNil(document) && isDragActive) {
    return (
      <Flex
        flexDirection={'row'}
        alignItems={'center'}
        data-testid={'document-box'}
        justifyContent={'center'}
        backgroundColor={greyPalette[50]}
        width={'100%'}
        gap={2}
        height={'100%'}
        py={2}
      >
        <Upload sx={{ color: greyPalette[600] }} />
        <Text variant={'body2'} color={'secondary'}>
          {'Drop files here to upload'}
        </Text>
      </Flex>
    );
  }

  if (description) {
    return (
      <NoDocument
        description={description}
        onMetadataUpdate={onDocumentMetadataUpdate}
        onFileSelected={onFileSelected}
        onDismissSource={onDismissSource}
        onUndismissSource={onUndismissSource}
        dismissed={dismissed}
        dismissible={dismissible}
      />
    );
  }

  return null;
};
