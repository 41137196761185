import { createContext } from 'react';

export interface ComponentRestrictorContextValue {
  isAllowedToSeeValueHandler?: () => Promise<boolean>;
  overrideRestriction?: boolean;
}

export const componentRestrictoreContext = createContext<ComponentRestrictorContextValue>({});

export const ComponentRestrictorContextProvider = componentRestrictoreContext.Provider;
