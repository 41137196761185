import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { OpportunityRequirementUpdateApiModelV2 } from '@lama/clients';
import { displayToast } from '@lama/app-components';
import { applicationServiceClient } from '../../shared/clients/applicationService';
import { useAuthentication } from '../authentication/useAuthentication';

export const useUpdateApplicationRequirementV2Mutation = (applicationId?: string, requirementId?: string) => {
  const queryClient = useQueryClient();
  const { token, isAuthenticated } = useAuthentication();

  const updateRequirementMutation = useMutation({
    mutationFn: async ({ updateRequirementPayload }: { updateRequirementPayload: OpportunityRequirementUpdateApiModelV2 }) => {
      if (!isAuthenticated || !applicationId || !requirementId) {
        return null;
      }

      await applicationServiceClient.updateApplicationRequirementV2(applicationId, requirementId, updateRequirementPayload, token);
    },
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: ['applicationRequirements', applicationId],
        }),
      ]);
    },
    onError: () => {
      displayToast('Something went wrong while updating the requirement. Please contact support.', 'error');
    },
  });

  return updateRequirementMutation;
};
