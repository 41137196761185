import React from 'react';

export const FormatBoldIcon = () => (
  <svg width={'20'} height={'20'} viewBox={'0 0 20 20'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'}>
    <mask id={'mask0_30713_815'} style={{ maskType: 'alpha' }} maskUnits={'userSpaceOnUse'} x={'0'} y={'0'} width={'20'} height={'20'}>
      <rect width={'20'} height={'20'} fill={'#D9D9D9'} />
    </mask>
    <g mask={'url(#mask0_30713_815)'}>
      <path
        d={
          'M7.38123 15.5208C7.02975 15.5208 6.73087 15.3977 6.48461 15.1514C6.23836 14.9052 6.11523 14.6063 6.11523 14.2548V5.74519C6.11523 5.3937 6.23836 5.09483 6.48461 4.84856C6.73087 4.60231 7.02975 4.47919 7.38123 4.47919H10.1665C11.0158 4.47919 11.7837 4.74361 12.4702 5.27246C13.1566 5.80131 13.4998 6.51338 13.4998 7.40867C13.4998 8.03151 13.3494 8.53926 13.0487 8.9319C12.7479 9.32452 12.4239 9.60951 12.0767 9.78685C12.5041 9.9343 12.9122 10.2257 13.3011 10.6611C13.69 11.0964 13.8844 11.6827 13.8844 12.4199C13.8844 13.485 13.4918 14.2681 12.7065 14.7692C11.9213 15.2703 11.1302 15.5208 10.3332 15.5208H7.38123ZM7.90684 13.8606H10.2658C10.9218 13.8606 11.3855 13.6784 11.6569 13.3141C11.9283 12.9498 12.0639 12.6004 12.0639 12.266C12.0639 11.9316 11.9283 11.5822 11.6569 11.2179C11.3855 10.8536 10.909 10.6715 10.2274 10.6715H7.90684V13.8606ZM7.90684 9.06252H10.0687C10.5751 9.06252 10.9766 8.91776 11.2731 8.62823C11.5696 8.33869 11.7178 7.99199 11.7178 7.58815C11.7178 7.15865 11.561 6.80767 11.2474 6.53523C10.9339 6.2628 10.5495 6.12658 10.0944 6.12658H7.90684V9.06252Z'
        }
        fill={'#9E9E9E'}
      />
    </g>
  </svg>
);
