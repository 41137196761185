import { useTheme } from '@mui/material';
import { useIsMobile } from '@lama/app-components';
import React from 'react';

export const ConclusionImageV2 = () => {
  const theme = useTheme();
  const isMobile = useIsMobile();

  if (isMobile) {
    return (
      <svg width={'374'} height={'351'} viewBox={'0 0 374 351'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'}>
        <rect opacity={'0.3'} x={'67.5'} y={'26.5'} width={'239'} height={'239'} rx={'119.5'} fill={'#FAFAFA'} stroke={'#E0E0E0'} />
        <rect
          opacity={'0.5'}
          x={'93.4102'}
          y={'52.4091'}
          width={'187.182'}
          height={'187.182'}
          rx={'93.5909'}
          fill={'#FAFAFA'}
          stroke={'#E0E0E0'}
        />
        <rect x={'119.316'} y={'78.3182'} width={'135.364'} height={'135.364'} rx={'67.6818'} fill={'#F5F5F5'} stroke={'#E0E0E0'} />
        <rect x={'145.229'} y={'104.227'} width={'83.5455'} height={'83.5455'} rx={'41.7727'} fill={'#EEEEEE'} stroke={'#E0E0E0'} />
        <g clipPath={'url(#clip0_23537_20867)'}>
          <path
            d={
              'M67.8587 124.1C65.6851 112.332 72.2428 101.767 81.9004 98.3533C85.785 96.98 88.5823 93.2866 89.3288 89.0201C92.9664 68.2012 110.233 52.4318 130.985 52.4318H117.975C97.2237 52.4318 79.9567 68.2012 76.3178 89.0201C75.5726 93.2866 72.7753 96.98 68.8907 98.3533C59.2332 101.765 52.6755 112.332 54.849 124.1C56.8125 134.733 66.2546 141.973 76.4777 141.973H89.486C79.263 141.973 69.8209 134.732 67.8574 124.1H67.8587Z'
            }
            fill={theme.palette.primary.main}
          />
          <path
            d={
              'M89.488 141.973C88.0795 141.973 86.6934 141.806 85.3311 141.541H76.4783C66.0663 141.541 57.1501 134.173 55.2751 124.022C53.2284 112.927 59.0158 102.303 69.0353 98.7584C72.994 97.3613 75.9498 93.656 76.7452 89.0924C80.4132 68.1005 97.7541 52.8633 117.976 52.8633H125.387C127.223 52.6072 129.084 52.4302 130.985 52.4302C131.129 52.4302 131.268 52.4527 131.412 52.454C131.419 52.3365 131.38 52.2216 131.302 52.1371C131.219 52.0499 131.104 51.9984 130.985 51.9984H117.977C97.3313 51.9997 79.6349 67.5367 75.8943 88.9445C75.1517 93.2004 72.4139 96.6509 68.7486 97.945C58.3234 101.63 52.2995 112.665 54.4242 124.178C56.3745 134.741 65.6501 142.406 76.4783 142.406H124.186C124.412 142.406 124.599 142.23 124.617 142.007L124.619 141.973H89.488Z'
            }
            fill={'#0C2007'}
          />
          <path
            d={
              'M81.9018 98.3533C85.7864 96.98 88.5837 93.2866 89.3289 89.0201C92.9665 68.2012 110.233 52.4318 130.985 52.4318C143.215 52.4318 154.192 57.937 161.9 66.713C167.009 72.5311 174.146 75.5325 181.623 74.9132C184.953 74.6372 188.449 74.9462 192.071 75.9934C204.571 79.6063 213.884 90.5281 215.463 104.158C217.83 124.595 202.64 141.974 183.774 141.974H89.4874C79.2644 141.974 69.8223 134.733 67.8588 124.102C65.6852 112.333 72.2429 101.768 81.9004 98.3546L81.9018 98.3533Z'
            }
            fill={'white'}
          />
          <path
            d={
              'M183.772 142.406H89.4885C78.6577 142.406 69.3834 134.741 67.4344 124.178C65.3084 112.665 71.3323 101.631 81.7575 97.9452C85.4228 96.6512 88.1593 93.2007 88.9032 88.9448C92.6452 67.5369 110.342 52 130.984 52C142.96 52 154.053 57.1235 162.224 66.4264C167.253 72.1507 174.325 75.0915 181.588 74.4827C185.108 74.1962 188.671 74.5606 192.191 75.5787C205.013 79.2854 214.316 90.4831 215.891 104.111C217.062 114.212 213.958 124.24 207.376 131.623C201.267 138.479 192.664 142.408 183.774 142.408L183.772 142.406ZM82.0442 98.76C72.0234 102.304 66.2373 112.929 68.2854 124.024C70.1577 134.174 79.0752 141.543 89.4885 141.543H183.774C192.416 141.543 200.786 137.718 206.732 131.049C213.149 123.848 216.175 114.066 215.033 104.208C213.497 90.9295 204.437 80.0182 191.951 76.4093C188.533 75.4203 185.079 75.0677 181.66 75.345C174.132 75.9762 166.784 72.9298 161.576 66.9995C153.57 57.8841 142.706 52.8636 130.984 52.8636C110.764 52.8636 93.4234 68.1008 89.7541 89.0927C88.9587 93.6563 86.0029 97.3616 82.0442 98.7587V98.76Z'
            }
            fill={'#0C2007'}
          />
        </g>
        <g clipPath={'url(#clip1_23537_20867)'}>
          <path
            d={
              'M254.568 198.696C242.825 198.696 233.559 188.102 235.808 175.95C237.017 169.42 241.862 163.933 248.107 161.671C250.981 160.63 253.736 160.333 256.321 160.589C260.46 160.998 264.255 158.702 266.419 155.152C271.438 146.914 280.501 141.458 290.855 141.455V141.405H283.215C272.861 141.409 263.798 146.914 258.776 155.15C256.612 158.701 252.817 160.996 248.679 160.588C246.095 160.332 243.339 160.628 240.465 161.67C234.219 163.932 229.374 169.417 228.166 175.949C225.918 188.1 235.185 198.695 246.927 198.695H283.218H254.565L254.568 198.696Z'
            }
            fill={theme.palette.primary.main}
            fillOpacity={'0.6'}
          />
          <path
            d={
              'M296.253 141.899C283.699 139.607 272.296 145.507 266.416 155.152C264.252 158.702 260.457 160.998 256.318 160.589C253.733 160.333 250.978 160.63 248.104 161.671C241.859 163.935 237.014 169.421 235.805 175.95C233.556 188.102 242.824 198.696 254.565 198.696H290.854C308.696 198.696 322.728 182.387 318.855 163.866C316.537 152.778 307.396 143.934 296.251 141.899H296.253Z'
            }
            fill={'white'}
          />
          <path
            d={
              'M290.855 198.696H254.566C253.082 198.696 251.638 198.524 250.25 198.203H246.925C241.381 198.203 236.163 195.757 232.615 191.488C229.067 187.223 227.622 181.591 228.651 176.038C229.803 169.811 234.508 164.352 240.634 162.132C242.726 161.376 244.84 160.995 246.937 160.995C247.504 160.995 248.066 161.022 248.628 161.078C249.076 161.121 249.519 161.123 249.96 161.108C250.916 160.862 251.853 160.702 252.776 160.61C255.341 159.785 257.626 157.982 259.195 155.407C264.353 146.952 273.329 141.902 283.215 141.898H285.921C287.686 141.589 289.487 141.397 291.348 141.427V141.405C291.348 141.134 291.128 140.912 290.855 140.912H283.215C272.983 140.917 263.689 146.143 258.357 154.896C256.173 158.476 252.462 160.484 248.729 160.096C245.916 159.824 243.096 160.194 240.295 161.206C233.85 163.543 228.899 169.294 227.681 175.859C226.599 181.703 228.119 187.63 231.857 192.121C235.595 196.613 241.089 199.19 246.926 199.19L290.855 199.186C291.128 199.186 291.348 198.966 291.348 198.693V198.667C291.182 198.67 291.021 198.696 290.855 198.696Z'
            }
            fill={'#0C2007'}
          />
          <path
            d={
              'M290.856 199.19H254.567C248.73 199.19 243.236 196.613 239.498 192.121C235.76 187.628 234.239 181.703 235.321 175.86C236.539 169.295 241.49 163.542 247.935 161.206C250.733 160.194 253.565 159.822 256.37 160.096C260.107 160.484 263.814 158.476 265.998 154.896C272.328 144.499 284.218 139.192 296.342 141.412C307.735 143.495 316.977 152.477 319.339 163.766C321.184 172.582 319.05 181.566 313.482 188.415C307.919 195.26 299.67 199.19 290.854 199.19H290.856ZM254.577 160.996C252.481 160.996 250.366 161.377 248.274 162.134C242.149 164.352 237.444 169.812 236.292 176.041C235.263 181.594 236.708 187.225 240.256 191.489C243.804 195.757 249.02 198.205 254.565 198.205H290.854C299.373 198.205 307.34 194.41 312.715 187.794C318.094 181.178 320.154 172.493 318.374 163.968C316.094 153.069 307.167 144.395 296.163 142.386C284.458 140.25 272.961 145.364 266.836 155.411C264.459 159.319 260.443 161.498 256.267 161.082C255.705 161.026 255.143 160.999 254.576 160.999L254.577 160.996Z'
            }
            fill={'#0C2007'}
          />
        </g>
        <g clipPath={'url(#clip2_23537_20867)'}>
          <path
            d={
              'M46.6407 203.486C46.5558 203.468 46.4752 203.43 46.4042 203.349L7.96878 160.653C7.80868 160.465 7.81838 160.2 8.00454 160.038L41.4149 129.474C41.4957 129.401 41.614 129.358 41.7247 129.358C41.8301 129.38 41.957 129.405 42.0246 129.508L80.4591 172.204C80.6192 172.392 80.6095 172.656 80.4234 172.818L47.013 203.382C46.9322 203.455 46.8139 203.498 46.7032 203.498L46.6397 203.485L46.6407 203.486ZM8.90102 160.397L46.7627 202.444L79.5278 172.459L41.6661 130.413L8.90102 160.397Z'
            }
            fill={'#0C2007'}
          />
          <path d={'M46.7472 202.926L80.172 172.387L41.777 129.669L8.35311 160.208L46.7481 202.927L46.7472 202.926Z'} fill={'white'} />
          <path
            d={
              'M25.0731 158.932C24.9882 158.915 24.9086 158.876 24.8366 158.795C24.6763 158.608 24.6862 158.342 24.8723 158.18L37.9474 146.207C38.1345 146.045 38.3968 146.054 38.5571 146.241C38.7172 146.429 38.7075 146.694 38.5213 146.856L25.4461 158.83C25.3397 158.919 25.1999 158.957 25.0731 158.932Z'
            }
            fill={'#0C2007'}
          />
          <path
            d={
              'M31.3764 175.373C31.2915 175.356 31.2117 175.318 31.1398 175.237C30.9797 175.049 30.9894 174.785 31.1755 174.623L53.5924 154.119C53.7794 153.957 54.0418 153.966 54.2019 154.154C54.3622 154.341 54.3525 154.606 54.1661 154.769L31.7495 175.271C31.6431 175.36 31.5033 175.399 31.3764 175.373Z'
            }
            fill={'#0C2007'}
          />
          <path
            d={
              'M36.0505 180.582C35.9656 180.564 35.886 180.526 35.814 180.445C35.6539 180.257 35.6627 179.992 35.8498 179.83L58.2666 159.327C58.4537 159.165 58.716 159.174 58.8761 159.362C59.0362 159.55 59.0265 159.814 58.8403 159.976L36.4237 180.479C36.3171 180.569 36.1773 180.607 36.0505 180.582Z'
            }
            fill={'#0C2007'}
          />
          <path
            d={
              'M40.7439 185.794C40.659 185.776 40.5794 185.738 40.5074 185.657C40.3473 185.469 40.3561 185.204 40.5432 185.042L62.9598 164.54C63.1469 164.378 63.4094 164.386 63.5695 164.574C63.7296 164.762 63.7199 165.026 63.5338 165.188L41.1171 185.691C41.0106 185.781 40.8708 185.819 40.7439 185.794Z'
            }
            fill={'#0C2007'}
          />
          <path
            d={
              'M45.4173 191.001C45.3324 190.984 45.2528 190.945 45.1809 190.864C45.0206 190.677 45.0305 190.412 45.2166 190.25L67.6332 169.747C67.8203 169.585 68.0828 169.593 68.2429 169.781C68.403 169.969 68.3934 170.234 68.2072 170.396L45.7904 190.899C45.684 190.988 45.5442 191.027 45.4173 191.001Z'
            }
            fill={'#0C2007'}
          />
        </g>
        <rect
          x={'25.0496'}
          y={'240.192'}
          width={'117.011'}
          height={'69.9779'}
          rx={'2.5'}
          transform={'rotate(10.4335 25.0496 240.192)'}
          fill={'white'}
          stroke={'#0C2007'}
        />
        <line x1={'129.093'} y1={'265.437'} x2={'118.588'} y2={'322.484'} stroke={'black'} strokeLinecap={'round'} />
        <line x1={'27.575'} y1={'273.699'} x2={'26.6721'} y2={'278.603'} stroke={'black'} strokeLinecap={'round'} />
        <line x1={'79.4451'} y1={'307.597'} x2={'38.3777'} y2={'300.035'} stroke={'black'} strokeLinecap={'round'} />
        <rect
          x={'42.7578'}
          y={'282.942'}
          width={'6.84124'}
          height={'11.117'}
          rx={'1'}
          transform={'rotate(10.4335 42.7578 282.942)'}
          fill={theme.palette.primary.light}
        />
        <rect
          x={'52.8711'}
          y={'275.24'}
          width={'6.84124'}
          height={'20.5237'}
          rx={'1'}
          transform={'rotate(10.4335 52.8711 275.24)'}
          fill={theme.palette.primary.light}
        />
        <rect
          x={'60.3496'}
          y={'281.834'}
          width={'6.84124'}
          height={'15.3928'}
          rx={'1'}
          transform={'rotate(10.4335 60.3496 281.834)'}
          fill={theme.palette.primary.light}
        />
        <rect
          x={'71.3945'}
          y={'269.086'}
          width={'6.84124'}
          height={'29.9304'}
          rx={'1'}
          transform={'rotate(10.4335 71.3945 269.086)'}
          fill={theme.palette.primary.main}
        />
        <rect
          x={'81.1992'}
          y={'263.065'}
          width={'6.84124'}
          height={'37.6268'}
          rx={'1'}
          transform={'rotate(10.4335 81.1992 263.065)'}
          fill={theme.palette.primary.light}
        />
        <line x1={'110.89'} y1={'288.171'} x2={'94.1715'} y2={'285.093'} stroke={'black'} strokeLinecap={'round'} />
        <line
          x1={'101.754'}
          y1={'293.607'}
          x2={'92.9025'}
          y2={'291.977'}
          stroke={'black'}
          strokeLinecap={'round'}
          strokeDasharray={'1 2'}
        />
        <line x1={'97.5369'} y1={'299.948'} x2={'91.6361'} y2={'298.861'} stroke={'black'} strokeLinecap={'round'} />
        <line
          x1={'44.5239'}
          y1={'284.443'}
          x2={'42.9291'}
          y2={'293.104'}
          stroke={'black'}
          strokeWidth={'0.6'}
          strokeLinecap={'round'}
          strokeDasharray={'0.7 3'}
        />
        <line x1={'54.7996'} y1={'276.973'} x2={'51.5737'} y2={'294.492'} stroke={'black'} strokeLinecap={'round'} />
        <line
          x1={'62.1177'}
          y1={'283.335'}
          x2={'59.7485'}
          y2={'296.201'}
          stroke={'black'}
          strokeWidth={'0.6'}
          strokeLinecap={'round'}
          strokeDasharray={'0.7 3'}
        />
        <line x1={'73.3231'} y1={'270.819'} x2={'68.3937'} y2={'297.589'} stroke={'black'} strokeLinecap={'round'} />
        <line
          x1={'82.9673'}
          y1={'264.565'}
          x2={'76.5717'}
          y2={'299.298'}
          stroke={'black'}
          strokeWidth={'0.6'}
          strokeLinecap={'round'}
          strokeDasharray={'0.7 3'}
        />
        <path
          d={
            'M46.3344 306.207C46.5217 306.241 46.7144 306.219 46.884 306.144C48.4664 305.379 49.9926 304.521 51.4534 303.575C54.0203 301.993 57.2131 300.021 58.5908 300.605C58.6541 300.627 58.7123 300.66 58.7616 300.704C58.9007 301.083 57.7831 302.437 57.3058 303.016C56.4219 304.088 55.7232 304.935 55.8967 305.75C55.9322 305.919 56.0058 306.08 56.1126 306.222C56.2194 306.364 56.3569 306.484 56.516 306.574C57.5424 307.167 58.8758 306.638 60.2871 306.075C61.3624 305.646 62.834 305.057 63.3198 305.475C63.4897 305.621 63.4601 306.119 63.4366 306.519C63.3961 307.195 63.3412 308.12 64.1642 308.66C65.0261 309.225 66.4508 309.213 70.9008 307.411C71.1147 307.324 71.2806 307.161 71.3619 306.958C71.4432 306.754 71.4332 306.526 71.3342 306.324C71.2352 306.123 71.0553 305.964 70.834 305.883C70.6128 305.801 70.3683 305.804 70.1543 305.891C66.5282 307.36 65.4797 307.378 65.2099 307.329L65.2064 307.328C65.1871 307.104 65.188 306.88 65.2091 306.658C65.2519 305.943 65.3105 304.966 64.501 304.274C63.1488 303.115 61.2355 303.879 59.5471 304.553C59.0028 304.783 58.4421 304.981 57.8686 305.143C58.0988 304.808 58.4573 304.373 58.7256 304.047C59.754 302.8 60.818 301.51 60.4778 300.322C60.3941 300.048 60.2425 299.795 60.0353 299.583C59.8281 299.371 59.5712 299.207 59.2858 299.103C57.0127 298.139 53.8207 300.109 50.446 302.19C49.0505 303.095 47.5934 303.916 46.0831 304.65C45.9161 304.724 45.78 304.846 45.6942 304.999C45.6084 305.151 45.5773 305.326 45.6053 305.499C45.6333 305.672 45.719 305.834 45.8502 305.961C45.9813 306.089 46.1513 306.175 46.3357 306.209L46.3344 306.207Z'
          }
          fill={theme.palette.primary.dark}
          stroke={'white'}
        />
        <path
          d={
            'M343.94 107.612L298.766 90.5616C296.053 89.5378 294.964 86.2831 296.514 83.832L315.946 53.0917C317.58 50.5071 320.805 49.4252 323.665 50.5046L362.414 65.1294L343.941 107.609L343.94 107.612Z'
          }
          fill={'white'}
        />
        <path
          d={
            'M343.808 107.962L298.634 90.9122C297.236 90.3848 296.16 89.2684 295.686 87.8505C295.213 86.4327 295.398 84.8952 296.197 83.6323L315.629 52.8919C317.351 50.1693 320.785 49.0196 323.797 50.1563L362.546 64.7812C362.642 64.8173 362.718 64.8915 362.759 64.9871C362.798 65.082 362.798 65.1887 362.757 65.2827L344.284 107.763C344.204 107.946 343.994 108.035 343.807 107.965L343.808 107.962ZM323.534 50.8584C320.852 49.846 317.796 50.8713 316.264 53.2946L296.833 84.035C296.156 85.1066 295.997 86.4136 296.4 87.6152C296.802 88.8191 297.714 89.7639 298.901 90.2119L343.741 107.136L361.917 65.3448L323.537 50.8592L323.534 50.8584Z'
          }
          fill={'#0C2007'}
        />
        <path
          d={
            'M349.723 109.794L340.956 106.485C338.243 105.462 337.155 102.207 338.705 99.7558L360.108 65.8986C360.593 65.1286 361.555 64.8081 362.406 65.1291L364.66 65.9801C365.595 66.3328 366.102 67.344 365.827 68.3057L354.626 107.335C354.024 109.431 351.763 110.564 349.725 109.795L349.723 109.794Z'
          }
          fill={theme.palette.primary.light}
        />
        <path
          d={
            'M349.592 110.145L340.825 106.836C339.426 106.308 338.352 105.192 337.878 103.774C337.404 102.357 337.589 100.819 338.389 99.5561L359.792 65.6989C360.37 64.7823 361.528 64.3966 362.539 64.7785L364.794 65.6295C365.904 66.0484 366.514 67.2668 366.188 68.4067L354.987 107.436C354.671 108.534 353.905 109.46 352.884 109.972C351.864 110.484 350.665 110.547 349.595 110.143L349.592 110.145ZM362.275 65.4797C361.592 65.2222 360.815 65.4843 360.425 66.1007L339.022 99.9579C338.344 101.032 338.186 102.337 338.589 103.538C338.991 104.742 339.902 105.689 341.089 106.137L349.856 109.446C350.743 109.781 351.697 109.73 352.546 109.305C353.393 108.88 354.004 108.144 354.265 107.233L365.467 68.2038C365.687 67.4376 365.274 66.6143 364.529 66.333L362.274 65.482L362.275 65.4797Z'
          }
          fill={'#0C2007'}
        />
        <path
          d={
            'M347.385 79.8469L312.015 66.4972C311.821 66.424 311.723 66.2083 311.797 66.0144C311.87 65.8204 312.085 65.7229 312.279 65.796L347.65 79.1458C348.54 79.4817 349.533 79.1648 350.065 78.3776L358.151 66.3883C358.266 66.2157 358.5 66.1703 358.671 66.2885C358.844 66.4043 358.887 66.6368 358.771 66.8094L350.685 78.7987C349.958 79.8779 348.602 80.3087 347.384 79.8493L347.385 79.8469Z'
          }
          fill={'#0C2007'}
        />
        <path
          d={
            'M333.866 74.3445L331.367 77.9673C330.951 78.5819 330.168 78.8335 329.474 78.5716L322.469 75.9277C321.707 75.6403 321.427 74.7044 321.911 74.0458L324.329 70.7447L333.869 74.3454L333.866 74.3445Z'
          }
          fill={'white'}
        />
        <path
          d={
            'M329.341 78.9222L322.336 76.2783C321.862 76.0993 321.507 75.717 321.364 75.2331C321.22 74.746 321.308 74.2348 321.607 73.8244L324.025 70.5233C324.125 70.3873 324.302 70.3341 324.461 70.3941L334.001 73.9948C334.109 74.0353 334.193 74.1232 334.226 74.2344C334.259 74.3432 334.243 74.4651 334.175 74.5597L331.676 78.1825C331.162 78.9419 330.202 79.2499 329.34 78.9245L329.341 78.9222ZM324.46 71.1949L322.211 74.2685C322.052 74.486 322.004 74.7617 322.08 75.02C322.156 75.2783 322.345 75.4834 322.598 75.5786L329.603 78.2224C330.138 78.4244 330.736 78.2335 331.053 77.7605L333.282 74.5272L324.457 71.1964L324.46 71.1949Z'
          }
          fill={'#0C2007'}
        />
        <path
          d={
            'M335.687 101.074L298.111 86.8915C297.917 86.8183 297.82 86.6026 297.893 86.4086C297.966 86.2146 298.182 86.1171 298.376 86.1903L335.952 100.373C336.146 100.446 336.243 100.661 336.17 100.855C336.097 101.049 335.881 101.147 335.687 101.074Z'
          }
          fill={'#0C2007'}
        />
        <path
          d={
            'M333.16 52.3635L330.228 51.2567C329.436 50.9578 328.55 51.358 328.251 52.1503L334.053 54.34C334.352 53.5477 333.952 52.6624 333.16 52.3635V52.3635Z'
          }
          fill={theme.palette.primary.light}
        />
        <path
          d={
            'M333.922 54.6915L328.12 52.5018C327.926 52.4286 327.829 52.2129 327.902 52.0189C328.274 51.035 329.378 50.5358 330.361 50.907L333.294 52.0138C334.277 52.385 334.776 53.4892 334.405 54.4732C334.332 54.6672 334.116 54.7647 333.922 54.6915V54.6915ZM328.792 51.9541L333.778 53.836C333.794 53.3536 333.503 52.894 333.029 52.7149L330.097 51.6082C329.622 51.4292 329.099 51.5843 328.794 51.955L328.792 51.9541Z'
          }
          fill={'#0C2007'}
        />
        <path
          d={
            'M356.666 61.2359L353.733 60.1292C352.941 59.8302 352.056 60.2305 351.757 61.0228L357.559 63.2125C357.858 62.4201 357.458 61.5349 356.666 61.2359V61.2359Z'
          }
          fill={theme.palette.primary.light}
        />
        <path
          d={
            'M357.428 63.563L351.626 61.3733C351.432 61.3001 351.335 61.0845 351.408 60.8905C351.779 59.9065 352.883 59.4073 353.867 59.7785L356.799 60.8853C357.783 61.2566 358.282 62.3607 357.911 63.3447C357.837 63.5387 357.622 63.6362 357.428 63.563V63.563ZM352.3 60.8265L357.286 62.7084C357.303 62.226 357.012 61.7664 356.537 61.5873L353.605 60.4806C353.131 60.3016 352.608 60.4567 352.302 60.8274L352.3 60.8265Z'
          }
          fill={'#0C2007'}
        />
        <path
          d={
            'M355.931 60.8695L357.463 58.2382C358.549 56.373 357.817 54.2518 355.827 53.5004L338.239 46.8626C336.249 46.1113 333.754 47.0146 332.666 48.879L331.133 51.5102L332.479 52.0182L333.671 49.9713C334.517 48.5205 336.459 47.8168 338.008 48.4015L354.502 54.6266C356.051 55.2113 356.621 56.8629 355.777 58.3147L354.585 60.3615L355.931 60.8695Z'
          }
          fill={'white'}
        />
        <path
          d={
            'M355.799 61.2201L354.453 60.7122C354.35 60.6734 354.269 60.5919 354.231 60.4869C354.193 60.3818 354.204 60.2684 354.261 60.1726L355.453 58.1258C355.81 57.5128 355.89 56.8487 355.679 56.2538C355.472 55.671 355.007 55.218 354.372 54.9782L337.878 48.7531C336.495 48.231 334.754 48.8634 333.997 50.1612L332.805 52.2081C332.714 52.3658 332.52 52.4341 332.349 52.3697L331.003 51.8618C330.9 51.823 330.819 51.7415 330.781 51.6365C330.744 51.5314 330.754 51.418 330.812 51.3222L332.344 48.691C333.518 46.6774 336.222 45.7008 338.374 46.513L355.961 53.1508C357.02 53.5503 357.796 54.3158 358.147 55.3027C358.503 56.3104 358.376 57.4186 357.79 58.4258L356.258 61.057C356.166 61.2147 355.972 61.2831 355.802 61.2187L355.799 61.2201ZM355.131 60.1671L355.766 60.407L357.139 58.0517C357.614 57.2378 357.719 56.3509 357.437 55.5557C357.162 54.7789 356.542 54.1735 355.693 53.8534L338.106 47.2156C336.281 46.5269 333.987 47.359 332.988 49.0703L331.678 51.3181L332.314 51.558L333.346 49.7848C334.278 48.1847 336.429 47.4078 338.14 48.0533L354.633 54.2784C355.479 54.5977 356.102 55.2118 356.384 56.0047C356.67 56.8119 356.569 57.7006 356.1 58.506L355.13 60.1694L355.131 60.1671Z'
          }
          fill={'#0C2007'}
        />
        <path
          d={
            'M355.393 52.0099L339.943 46.179C339.322 45.9444 338.54 46.2261 338.2 46.8081L337.464 48.0735C337.124 48.6556 337.354 49.3191 337.976 49.5537L353.425 55.3846C354.046 55.6192 354.828 55.3375 355.168 54.7554L355.904 53.4901C356.244 52.908 356.014 52.2444 355.393 52.0099Z'
          }
          fill={theme.palette.primary.main}
        />
        <path
          d={
            'M353.292 55.7352L337.843 49.9043C337.441 49.7526 337.143 49.4588 337.008 49.0763C336.871 48.6884 336.916 48.2648 337.14 47.8846L337.876 46.6192C338.31 45.8725 339.277 45.5244 340.076 45.826L355.525 51.6569C355.927 51.8086 356.225 52.1025 356.361 52.4849C356.497 52.8729 356.452 53.2964 356.228 53.6767L355.492 54.9421C355.058 55.6888 354.091 56.0368 353.292 55.7352ZM339.809 46.5263C339.358 46.3561 338.77 46.5719 338.522 46.9939L337.786 48.2593C337.677 48.4479 337.65 48.6484 337.714 48.8249C337.775 48.9949 337.914 49.1299 338.107 49.2031L353.557 55.034C354.008 55.2042 354.596 54.9885 354.844 54.5665L355.58 53.3011C355.689 53.1124 355.716 52.9119 355.652 52.7355C355.591 52.5655 355.452 52.4304 355.258 52.3572L339.809 46.5263Z'
          }
          fill={'#0C2007'}
        />
        <path
          d={
            'M342.005 103.458C341.884 103.412 341.788 103.304 341.769 103.166C341.736 102.962 341.876 102.769 342.08 102.736L349.223 101.606L361.941 67.9096C362.014 67.7156 362.23 67.6181 362.424 67.6913C362.618 67.7645 362.715 67.9801 362.642 68.1741L349.847 102.074C349.8 102.2 349.688 102.291 349.555 102.31L342.196 103.477C342.131 103.487 342.064 103.48 342.005 103.458Z'
          }
          fill={'white'}
        />
        <path
          d={
            'M358.862 241.382C363.442 231.138 358.849 219.124 348.604 214.548C338.359 209.971 326.341 214.565 321.761 224.808C317.182 235.052 321.774 247.066 332.019 251.643C342.264 256.219 354.282 251.625 358.862 241.382Z'
          }
          fill={'#0C2007'}
        />
        <path
          d={
            'M359.598 225.945C356.137 215.625 344.962 210.065 334.638 213.527C324.314 216.99 318.751 228.163 322.212 238.484C325.673 248.805 336.848 254.364 347.172 250.902C357.496 247.44 363.059 236.266 359.598 225.945Z'
          }
          fill={theme.palette.primary.main}
        />
        <path
          d={
            'M356.674 227.22C353.918 218.509 344.62 213.683 335.907 216.441C327.194 219.198 322.365 228.495 325.121 237.205C327.877 245.915 337.175 250.741 345.888 247.984C354.601 245.227 359.43 235.93 356.674 227.22Z'
          }
          fill={'white'}
        />
        <path
          d={
            'M343.803 243.626L342.797 241.098C340.983 241.727 339.063 241.936 337.819 241.754L337.379 238.324C338.739 238.493 340.532 238.427 342.262 237.738C343.779 237.134 344.585 236.137 344.16 235.074C343.758 234.063 342.652 233.764 340.419 233.883C337.19 234.059 334.719 233.501 333.694 230.921C332.762 228.58 333.683 226.09 336.307 224.398L335.301 221.87L337.909 220.834L338.841 223.174C340.654 222.545 342.001 222.44 343.047 222.516L343.472 225.827C342.663 225.808 341.196 225.684 339.281 226.446C337.551 227.135 337.289 228.102 337.585 228.845C337.935 229.721 339.088 229.911 341.688 229.861C345.299 229.718 347.157 230.673 348.111 233.066C349.052 235.433 348.18 238.121 345.332 239.871L346.411 242.583L343.803 243.619L343.803 243.626Z'
          }
          fill={theme.palette.primary.main}
        />
        <path
          d={
            'M296.4 283.593C303.138 281.384 306.807 274.132 304.595 267.395C302.384 260.658 295.129 256.988 288.392 259.198C281.655 261.407 277.986 268.659 280.197 275.395C282.409 282.132 289.663 285.802 296.4 283.593Z'
          }
          fill={'#0C2007'}
        />
        <path
          d={
            'M303.934 277.38C307.292 271.376 305.145 263.787 299.14 260.429C293.134 257.07 285.544 259.215 282.187 265.218C278.829 271.221 280.976 278.811 286.981 282.169C292.987 285.527 300.577 283.383 303.934 277.38Z'
          }
          fill={theme.palette.primary.main}
        />
        <path
          d={
            'M302.096 276.553C305 271.564 303.307 265.165 298.316 262.26C293.325 259.356 286.925 261.046 284.021 266.036C281.118 271.025 282.81 277.425 287.801 280.329C292.792 283.233 299.192 281.543 302.096 276.553Z'
          }
          fill={'white'}
        />
        <path
          d={
            'M288.955 277.502L289.71 275.958C288.645 275.377 287.732 274.568 287.288 273.908L288.706 272.246C289.205 272.954 289.997 273.764 291.054 274.281C291.981 274.734 292.79 274.687 293.107 274.037C293.409 273.419 293.079 272.775 292.074 271.781C290.621 270.345 289.832 268.953 290.603 267.377C291.303 265.946 292.858 265.319 294.765 265.828L295.52 264.284L297.113 265.063L296.413 266.493C297.478 267.074 298.099 267.66 298.508 268.181L297.14 269.787C296.805 269.401 296.24 268.661 295.07 268.089C294.013 267.572 293.449 267.861 293.228 268.315C292.966 268.851 293.367 269.471 294.495 270.666C296.096 272.294 296.438 273.569 295.724 275.032C295.017 276.478 293.389 277.212 291.361 276.623L290.551 278.28L288.958 277.5L288.955 277.502Z'
          }
          fill={theme.palette.primary.main}
        />
        <path
          d={
            'M280.107 18.9307L280.106 18.9303C278.37 15.2142 279.526 10.3597 283.167 7.25596C283.684 7.60927 284.202 7.9626 284.719 8.31589C286.058 9.22996 287.396 10.1438 288.736 11.0565C285.04 14.3976 283.597 18.8554 283.888 22.7843C282.415 22.0084 280.979 20.8036 280.107 18.9307Z'
          }
          fill={theme.palette.primary.light}
          stroke={'black'}
          strokeWidth={'0.81'}
        />
        <path
          d={
            'M287.853 29.5029C286.346 28.2493 285.305 26.3335 284.876 24.1103C286.171 24.5975 287.535 24.888 288.918 24.9707L288.918 24.9709L288.929 24.9712C293.584 25.1288 296.777 22.3752 297.9 21.2313C298.354 22.0445 298.807 22.8573 299.261 23.67C300.178 25.3145 301.096 26.9582 302.012 28.602C297.204 32.2191 291.15 32.2447 287.853 29.5029Z'
          }
          fill={theme.palette.primary.light}
          stroke={'black'}
          strokeWidth={'0.81'}
        />
        <path
          d={
            'M266.411 220.078L266.411 220.078C273.363 224.334 278.392 230.962 280.559 234.077C279.366 236.676 278.17 239.272 276.972 241.861C274.719 239.187 268.092 232.084 258.593 230.13L258.59 230.129C255.073 229.426 251.6 229.486 248.391 230.312C250.156 226.477 251.72 223.191 252.885 220.805C253.517 219.51 254.03 218.482 254.394 217.775C254.577 217.421 254.72 217.151 254.821 216.969C254.848 216.92 254.871 216.88 254.89 216.846C254.901 216.843 254.911 216.839 254.921 216.836L254.981 216.814L254.992 216.811L254.993 216.81L255.029 216.799C255.058 216.79 255.097 216.778 255.137 216.764C255.132 216.766 255.139 216.765 255.157 216.761C255.495 216.703 259.706 215.969 266.411 220.078Z'
          }
          fill={'#E0E0E0'}
          stroke={'black'}
        />
        <path
          d={
            'M124.832 223.664L124.832 223.664C118.537 226.898 110.105 225.182 104.578 219.084C105.167 218.146 105.757 217.209 106.347 216.272C107.85 213.883 109.353 211.495 110.853 209.105C116.815 215.332 124.626 217.488 131.335 216.636C130.114 219.305 128.114 221.982 124.832 223.664Z'
          }
          fill={theme.palette.primary.main}
          stroke={'black'}
        />
        <path
          d={
            'M142.283 209.839C140.169 212.583 136.829 214.517 132.923 215.357C133.726 213.059 134.164 210.644 134.219 208.208L134.219 208.208L134.219 208.195C134.19 200.131 129.053 194.871 127.201 193.231C128.62 192.369 130.037 191.506 131.455 190.644C134.223 188.96 136.99 187.278 139.757 185.597C146.316 193.564 146.8 203.974 142.283 209.839Z'
          }
          fill={theme.palette.primary.main}
          stroke={'black'}
        />
        <path
          d={
            'M19.7322 40.4163C16.3189 38.3104 13.7294 38.6072 11.3008 38.8855C10.7967 38.9433 10.2994 39.0003 9.80323 39.0348C9.42396 39.0604 9.06522 39.0254 8.73126 38.941L14.221 29.4325C15.1588 29.5285 16.0391 29.4881 16.8848 29.4104C17.2613 29.3759 17.6207 29.3353 17.9741 29.2955C18.5321 29.2326 19.0753 29.1713 19.6482 29.1377C21.4488 29.0321 23.3369 29.2222 25.7147 30.6875L25.7152 30.6878C27.2835 31.6516 28.2541 32.6707 28.9353 33.7119C29.6226 34.7627 30.0261 35.8503 30.4488 36.9959L30.4571 37.0184C30.873 38.1455 31.3097 39.3293 32.062 40.481C32.8255 41.6498 33.9031 42.7716 35.5904 43.81C37.2802 44.8522 38.7691 45.3117 40.1582 45.4689C41.529 45.624 42.7854 45.4814 43.9815 45.3456L43.9998 45.3435C45.2137 45.2057 46.3669 45.0764 47.6148 45.2169C48.8513 45.3561 50.1983 45.7635 51.7649 46.7294L51.7652 46.7296C53.4154 47.7451 54.4285 48.7357 55.1328 49.7209C55.7704 50.6129 56.168 51.5171 56.5554 52.4868L48.7815 59.2114C48.0719 58.2211 47.1191 57.2879 45.7748 56.4593C44.0872 55.4189 42.6 54.9602 41.2121 54.8032C39.8436 54.6485 38.5888 54.7904 37.3941 54.9256L37.373 54.928C36.1596 55.0652 35.0065 55.1938 33.7586 55.0527C32.5221 54.9129 31.175 54.505 29.6083 53.5391L29.6077 53.5387C28.0394 52.5749 27.0688 51.5558 26.3877 50.5146C25.7003 49.4638 25.2968 48.3761 24.8741 47.2306L24.8658 47.2081C24.4499 46.081 24.0132 44.8972 23.2609 43.7455C22.4973 42.5766 21.4197 41.4548 19.7322 40.4163ZM19.7322 40.4163C19.7322 40.4163 19.7321 40.4162 19.7321 40.4162L19.47 40.842L19.7326 40.4165C19.7324 40.4164 19.7323 40.4164 19.7322 40.4163ZM7.99627 39.2141C8.1495 39.2821 8.3085 39.3407 8.47278 39.3887L8.24789 38.7782L7.99627 39.2141Z'
          }
          fill={theme.palette.primary.light}
          stroke={'black'}
        />
        <defs>
          <clipPath id={'clip0_23537_20867'}>
            <rect width={'162.134'} height={'90.4062'} fill={'white'} transform={'translate(54 52)'} />
          </clipPath>
          <clipPath id={'clip1_23537_20867'}>
            <rect width={'92.6477'} height={'58.2784'} fill={'white'} transform={'translate(227.34 140.912)'} />
          </clipPath>
          <clipPath id={'clip2_23537_20867'}>
            <rect width={'73.6975'} height={'71.6876'} fill={'white'} transform={'translate(15.1895 124) rotate(11.4162)'} />
          </clipPath>
        </defs>
      </svg>
    );
  }

  return (
    <svg width={'1017'} height={'363'} viewBox={'0 0 1017 363'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'}>
      <rect opacity={'0.3'} x={'334.5'} y={'11.5'} width={'351'} height={'351'} rx={'175.5'} fill={'#FAFAFA'} stroke={'#E0E0E0'} />
      <rect opacity={'0.5'} x={'372.5'} y={'49.5'} width={'275'} height={'275'} rx={'137.5'} fill={'#FAFAFA'} stroke={'#E0E0E0'} />
      <rect x={'410.5'} y={'87.5'} width={'199'} height={'199'} rx={'99.5'} fill={'#F5F5F5'} stroke={'#E0E0E0'} />
      <rect x={'448.5'} y={'125.5'} width={'123'} height={'123'} rx={'61.5'} fill={'#EEEEEE'} stroke={'#E0E0E0'} />
      <g clipPath={'url(#clip0_23526_19832)'}>
        <path
          d={
            'M281.123 158.639C280.997 158.613 280.876 158.556 280.77 158.435L223.402 94.7072C223.163 94.4265 223.178 94.0319 223.456 93.7898L273.324 48.1704C273.444 48.0617 273.621 47.998 273.786 47.9977C273.943 48.0295 274.133 48.0677 274.234 48.2211L331.6 111.948C331.839 112.229 331.825 112.624 331.547 112.866L281.679 158.485C281.559 158.594 281.382 158.658 281.217 158.658L281.122 158.639L281.123 158.639ZM224.794 94.326L281.305 157.084L330.21 112.33L273.698 49.5718L224.794 94.326Z'
          }
          fill={'#0C2007'}
        />
        <path d={'M281.283 157.805L331.173 112.222L273.865 48.461L223.977 94.0441L281.285 157.805L281.283 157.805Z'} fill={'white'} />
        <path
          d={
            'M248.932 92.1389C248.806 92.1133 248.687 92.0557 248.579 91.9346C248.34 91.6553 248.355 91.2592 248.633 91.0172L268.149 73.1465C268.428 72.9047 268.819 72.918 269.059 73.1972C269.298 73.4779 269.283 73.8726 269.005 74.1146L249.489 91.9867C249.33 92.1198 249.122 92.1771 248.932 92.1389Z'
          }
          fill={'#0C2007'}
        />
        <path
          d={
            'M258.34 116.679C258.213 116.653 258.094 116.597 257.987 116.476C257.748 116.195 257.762 115.801 258.04 115.559L291.499 84.9554C291.778 84.7136 292.17 84.7269 292.409 85.0076C292.648 85.2868 292.634 85.6815 292.356 85.925L258.897 116.527C258.738 116.66 258.529 116.717 258.34 116.679Z'
          }
          fill={'#0C2007'}
        />
        <path
          d={
            'M265.315 124.453C265.188 124.427 265.069 124.37 264.962 124.249C264.723 123.968 264.736 123.573 265.015 123.331L298.474 92.7282C298.754 92.4864 299.145 92.4997 299.384 92.7803C299.623 93.061 299.609 93.4557 299.331 93.6977L265.872 124.3C265.713 124.434 265.504 124.491 265.315 124.453Z'
          }
          fill={'#0C2007'}
        />
        <path
          d={
            'M272.323 132.232C272.196 132.207 272.077 132.149 271.97 132.028C271.731 131.747 271.744 131.352 272.023 131.111L305.482 100.509C305.761 100.267 306.153 100.279 306.392 100.56C306.631 100.84 306.617 101.235 306.339 101.477L272.88 132.079C272.721 132.213 272.512 132.271 272.323 132.232Z'
          }
          fill={'#0C2007'}
        />
        <path
          d={
            'M279.299 140.005C279.172 139.979 279.053 139.922 278.946 139.801C278.707 139.521 278.721 139.125 278.999 138.883L312.458 108.282C312.737 108.04 313.129 108.052 313.368 108.332C313.607 108.613 313.593 109.008 313.315 109.25L279.856 139.853C279.697 139.986 279.488 140.043 279.299 140.005Z'
          }
          fill={'#0C2007'}
        />
      </g>
      <g clipPath={'url(#clip1_23526_19832)'}>
        <path
          d={
            'M368.549 165.499C365.64 149.749 374.417 135.608 387.342 131.039C392.542 129.201 396.285 124.258 397.285 118.548C402.153 90.6837 425.263 69.5779 453.037 69.5779H435.625C407.851 69.5779 384.741 90.6837 379.871 118.548C378.873 124.258 375.129 129.201 369.93 131.039C357.004 135.606 348.228 149.749 351.137 165.499C353.765 179.73 366.402 189.42 380.085 189.42H397.495C383.812 189.42 371.175 179.728 368.547 165.499H368.549Z'
          }
          fill={theme.palette.primary.main}
        />
        <path
          d={
            'M397.497 189.42C395.612 189.42 393.757 189.198 391.934 188.842H380.085C366.15 188.842 354.216 178.98 351.707 165.395C348.967 150.546 356.713 136.325 370.123 131.582C375.422 129.712 379.378 124.753 380.442 118.645C385.351 90.5493 408.561 70.1558 435.625 70.1558H445.544C448.003 69.8129 450.493 69.5761 453.037 69.5761C453.23 69.5761 453.416 69.6061 453.609 69.6079C453.617 69.4506 453.566 69.2969 453.462 69.1837C453.35 69.0671 453.196 68.9982 453.037 68.9982H435.627C407.995 68.9999 384.31 89.7946 379.303 118.447C378.309 124.143 374.645 128.761 369.74 130.493C355.786 135.426 347.724 150.194 350.568 165.603C353.178 179.74 365.593 190 380.085 190H443.937C444.239 190 444.49 189.765 444.513 189.466L444.517 189.42H397.497Z'
          }
          fill={'#0C2007'}
        />
        <path
          d={
            'M387.344 131.039C392.543 129.201 396.287 124.258 397.284 118.548C402.153 90.6837 425.263 69.5779 453.037 69.5779C469.406 69.5779 484.098 76.946 494.413 88.6919C501.252 96.4788 510.803 100.496 520.811 99.6671C525.268 99.2977 529.947 99.7113 534.794 101.113C551.526 105.948 563.99 120.566 566.103 138.809C569.27 166.162 548.94 189.422 523.69 189.422H397.497C383.814 189.422 371.177 179.73 368.549 165.501C365.64 149.75 374.417 135.61 387.342 131.041L387.344 131.039Z'
          }
          fill={'white'}
        />
        <path
          d={
            'M523.69 190H397.5C383.004 190 370.591 179.741 367.982 165.603C365.137 150.194 373.199 135.426 387.152 130.493C392.058 128.761 395.721 124.143 396.716 118.447C401.725 89.7947 425.409 69 453.038 69C469.066 69 483.913 75.8573 494.85 88.3083C501.58 95.9698 511.045 99.9057 520.766 99.0909C525.477 98.7074 530.245 99.1952 534.956 100.558C552.117 105.519 564.569 120.506 566.677 138.745C568.244 152.265 564.09 165.687 555.281 175.568C547.104 184.744 535.589 190.002 523.691 190.002L523.69 190ZM387.536 131.584C374.124 136.327 366.38 150.547 369.121 165.397C371.627 178.982 383.563 188.844 397.5 188.844H523.691C535.259 188.844 546.46 183.726 554.418 174.799C563.008 165.162 567.057 152.069 565.529 138.876C563.473 121.103 551.346 106.5 534.636 101.67C530.061 100.346 525.439 99.8739 520.862 100.245C510.787 101.09 500.953 97.0125 493.981 89.0754C483.266 76.8753 468.726 70.1558 453.038 70.1558C425.975 70.1558 402.766 90.5493 397.855 118.645C396.791 124.753 392.835 129.712 387.536 131.582V131.584Z'
          }
          fill={'#0C2007'}
        />
      </g>
      <g clipPath={'url(#clip2_23526_19832)'}>
        <path
          d={
            'M618.441 265.338C602.725 265.338 590.323 251.159 593.333 234.896C594.95 226.156 601.436 218.812 609.793 215.784C613.64 214.391 617.328 213.993 620.787 214.336C626.327 214.883 631.406 211.811 634.303 207.059C641.02 196.034 653.15 188.73 667.008 188.726V188.66H656.783C642.924 188.666 630.795 196.034 624.073 207.057C621.177 211.809 616.097 214.881 610.56 214.334C607.1 213.991 603.413 214.389 599.566 215.782C591.206 218.81 584.721 226.152 583.105 234.894C580.095 251.157 592.499 265.336 608.214 265.336H656.787H618.437L618.441 265.338Z'
          }
          fill={theme.palette.primary.main}
          fillOpacity={'0.6'}
        />
        <path
          d={
            'M674.235 189.321C657.434 186.253 642.171 194.15 634.302 207.059C631.405 211.811 626.326 214.883 620.786 214.336C617.327 213.993 613.639 214.391 609.792 215.784C601.435 218.814 594.949 226.156 593.332 234.896C590.322 251.159 602.726 265.338 618.44 265.338H667.009C690.89 265.338 709.67 243.51 704.486 218.721C701.383 203.882 689.149 192.044 674.233 189.321H674.235Z'
          }
          fill={'white'}
        />
        <path
          d={
            'M667.009 265.338H618.44C616.453 265.338 614.52 265.108 612.663 264.679H608.213C600.793 264.679 593.809 261.405 589.061 255.691C584.312 249.983 582.378 242.445 583.755 235.013C585.297 226.678 591.594 219.373 599.793 216.402C602.593 215.389 605.423 214.879 608.229 214.879C608.987 214.879 609.74 214.915 610.492 214.99C611.091 215.048 611.684 215.05 612.275 215.03C613.554 214.701 614.809 214.487 616.043 214.364C619.477 213.259 622.535 210.847 624.635 207.4C631.538 196.084 643.553 189.325 656.784 189.319H660.405C662.767 188.906 665.178 188.649 667.669 188.69V188.66C667.669 188.297 667.374 188 667.009 188H656.784C643.089 188.006 630.65 195.001 623.513 206.716C620.59 211.508 615.624 214.195 610.627 213.677C606.863 213.312 603.089 213.808 599.339 215.161C590.713 218.29 584.086 225.986 582.456 234.773C581.008 242.595 583.043 250.527 588.046 256.538C593.049 262.551 600.402 266 608.215 266L667.009 265.994C667.374 265.994 667.669 265.699 667.669 265.334V265.3C667.447 265.304 667.231 265.338 667.009 265.338Z'
          }
          fill={'#0C2007'}
        />
        <path
          d={
            'M667.008 266H618.439C610.626 266 603.274 262.551 598.271 256.538C593.268 250.525 591.233 242.595 592.681 234.775C594.311 225.988 600.938 218.287 609.563 215.161C613.307 213.808 617.098 213.309 620.852 213.677C625.855 214.195 630.815 211.508 633.738 206.716C642.21 192.8 658.124 185.699 674.351 188.67C689.599 191.457 701.969 203.479 705.13 218.588C707.599 230.388 704.743 242.411 697.291 251.578C689.846 260.74 678.805 266 667.006 266H667.008ZM618.453 214.881C615.647 214.881 612.817 215.391 610.017 216.404C601.819 219.373 595.521 226.68 593.98 235.017C592.602 242.449 594.537 249.985 599.285 255.693C604.034 261.405 611.016 264.681 618.437 264.681H667.006C678.408 264.681 689.071 259.602 696.264 250.747C703.464 241.893 706.222 230.269 703.839 218.858C700.787 204.271 688.839 192.661 674.111 189.973C658.445 187.115 643.058 193.958 634.86 207.406C631.678 212.636 626.304 215.552 620.715 214.996C619.962 214.921 619.21 214.885 618.451 214.885L618.453 214.881Z'
          }
          fill={'#0C2007'}
        />
      </g>
      <path
        d={
          'M825.289 126.541C831.419 112.831 825.273 96.751 811.561 90.6256C797.849 84.5002 781.764 90.6487 775.634 104.359C769.504 118.069 775.651 134.148 789.363 140.274C803.075 146.399 819.16 140.251 825.289 126.541Z'
        }
        fill={'#0C2007'}
      />
      <path
        d={
          'M826.276 105.88C821.643 92.067 806.687 84.6257 792.869 89.2599C779.051 93.894 771.605 108.849 776.238 122.662C780.87 136.475 795.826 143.917 809.644 139.282C823.462 134.648 830.908 119.694 826.276 105.88Z'
        }
        fill={theme.palette.primary.main}
      />
      <path
        d={
          'M822.362 107.586C818.673 95.928 806.229 89.469 794.567 93.1594C782.906 96.8499 776.442 109.292 780.131 120.95C783.82 132.608 796.264 139.067 807.926 135.377C819.587 131.686 826.051 119.244 822.362 107.586Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M805.136 129.544L803.79 126.161C801.362 127.003 798.793 127.283 797.128 127.039L796.539 122.448C798.359 122.674 800.758 122.585 803.074 121.664C805.104 120.855 806.183 119.521 805.614 118.098C805.076 116.745 803.595 116.345 800.607 116.505C796.286 116.739 792.979 115.994 791.606 112.54C790.359 109.407 791.591 106.073 795.104 103.809L793.758 100.426L797.248 99.0389L798.495 102.172C800.922 101.329 802.724 101.188 804.124 101.291L804.693 105.722C803.611 105.697 801.647 105.53 799.084 106.551C796.768 107.472 796.418 108.767 796.814 109.761C797.283 110.934 798.826 111.188 802.305 111.121C807.139 110.93 809.625 112.209 810.902 115.411C812.162 118.579 810.995 122.176 807.183 124.518L808.627 128.148L805.137 129.535L805.136 129.544Z'
        }
        fill={theme.palette.primary.main}
      />
      <path
        d={
          'M739.014 164.976C748.031 162.019 752.942 152.313 749.982 143.297C747.022 134.281 737.313 129.369 728.296 132.325C719.279 135.282 714.368 144.988 717.328 154.004C720.288 163.021 729.997 167.933 739.014 164.976Z'
        }
        fill={'#0C2007'}
      />
      <path
        d={
          'M749.097 156.661C753.591 148.626 750.718 138.468 742.68 133.973C734.642 129.478 724.484 132.348 719.99 140.383C715.496 148.418 718.369 158.576 726.407 163.07C734.445 167.565 744.603 164.696 749.097 156.661Z'
        }
        fill={theme.palette.primary.main}
      />
      <path
        d={
          'M746.636 155.554C750.523 148.876 748.258 140.312 741.577 136.424C734.897 132.537 726.331 134.8 722.445 141.478C718.559 148.156 720.824 156.72 727.504 160.608C734.184 164.495 742.75 162.232 746.636 155.554Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M729.047 156.825L730.058 154.758C728.633 153.98 727.41 152.897 726.817 152.014L728.714 149.789C729.382 150.736 730.443 151.821 731.858 152.513C733.098 153.119 734.18 153.057 734.605 152.186C735.009 151.36 734.567 150.497 733.223 149.167C731.277 147.245 730.221 145.382 731.254 143.272C732.19 141.358 734.272 140.518 736.824 141.199L737.834 139.133L739.966 140.176L739.03 142.09C740.455 142.868 741.287 143.651 741.834 144.349L740.002 146.498C739.554 145.981 738.798 144.991 737.232 144.226C735.817 143.534 735.063 143.921 734.766 144.528C734.417 145.246 734.953 146.076 736.463 147.674C738.605 149.854 739.064 151.56 738.108 153.518C737.161 155.454 734.983 156.435 732.268 155.647L731.183 157.865L729.052 156.821L729.047 156.825Z'
        }
        fill={theme.palette.primary.main}
      />
      <rect
        x={'103.389'}
        y={'146.828'}
        width={'156.947'}
        height={'93.997'}
        rx={'2.5'}
        transform={'rotate(10.4335 103.389 146.828)'}
        fill={'white'}
        stroke={'#0C2007'}
      />
      <line x1={'242.641'} y1={'180.616'} x2={'228.521'} y2={'257.3'} stroke={'black'} strokeLinecap={'round'} />
      <line x1={'106.77'} y1={'191.674'} x2={'105.501'} y2={'198.57'} stroke={'black'} strokeLinecap={'round'} />
      <line x1={'176.527'} y1={'237.105'} x2={'121.23'} y2={'226.922'} stroke={'black'} strokeLinecap={'round'} />
      <rect
        x={'127.227'}
        y={'204.242'}
        width={'9.15634'}
        height={'14.8791'}
        rx={'1'}
        transform={'rotate(10.4335 127.227 204.242)'}
        fill={theme.palette.primary.light}
      />
      <rect
        x={'140.762'}
        y={'193.933'}
        width={'9.15634'}
        height={'27.469'}
        rx={'1'}
        transform={'rotate(10.4335 140.762 193.933)'}
        fill={theme.palette.primary.light}
      />
      <rect
        x={'150.771'}
        y={'202.759'}
        width={'9.15634'}
        height={'20.6018'}
        rx={'1'}
        transform={'rotate(10.4335 150.771 202.759)'}
        fill={theme.palette.primary.light}
      />
      <rect
        x={'165.555'}
        y={'185.697'}
        width={'9.15634'}
        height={'40.059'}
        rx={'1'}
        transform={'rotate(10.4335 165.555 185.697)'}
        fill={theme.palette.primary.main}
      />
      <rect
        x={'178.676'}
        y={'177.639'}
        width={'9.15634'}
        height={'50.3599'}
        rx={'1'}
        transform={'rotate(10.4335 178.676 177.639)'}
        fill={theme.palette.primary.light}
      />
      <line x1={'212.621'} y1={'210.002'} x2={'195.902'} y2={'206.923'} stroke={'black'} strokeLinecap={'round'} />
      <line x1={'203.484'} y1={'215.437'} x2={'194.633'} y2={'213.807'} stroke={'black'} strokeLinecap={'round'} strokeDasharray={'1 2'} />
      <line x1={'199.267'} y1={'221.778'} x2={'193.367'} y2={'220.692'} stroke={'black'} strokeLinecap={'round'} />
      <line
        x1={'129.508'}
        y1={'206.132'}
        x2={'127.337'}
        y2={'217.924'}
        stroke={'black'}
        strokeWidth={'0.6'}
        strokeLinecap={'round'}
        strokeDasharray={'0.7 3'}
      />
      <line x1={'143.208'} y1={'196.056'} x2={'138.829'} y2={'219.836'} stroke={'black'} strokeLinecap={'round'} />
      <line
        x1={'153.057'}
        y1={'204.649'}
        x2={'149.849'}
        y2={'222.069'}
        stroke={'black'}
        strokeWidth={'0.6'}
        strokeLinecap={'round'}
        strokeDasharray={'0.7 3'}
      />
      <line x1={'167.999'} y1={'187.819'} x2={'161.34'} y2={'223.981'} stroke={'black'} strokeLinecap={'round'} />
      <line
        x1={'180.961'}
        y1={'179.528'}
        x2={'172.365'}
        y2={'226.214'}
        stroke={'black'}
        strokeWidth={'0.6'}
        strokeLinecap={'round'}
        strokeDasharray={'0.7 3'}
      />
      <path
        d={
          'M132.013 235.379C132.264 235.425 132.522 235.396 132.749 235.295C134.867 234.272 136.909 233.123 138.865 231.857C142.3 229.739 146.573 227.101 148.417 227.882C148.502 227.911 148.58 227.956 148.646 228.014C148.832 228.521 147.336 230.334 146.697 231.109C145.514 232.544 144.579 233.677 144.812 234.768C144.859 234.994 144.957 235.21 145.1 235.4C145.243 235.59 145.427 235.751 145.64 235.871C147.014 236.665 148.799 235.956 150.688 235.203C152.127 234.629 154.096 233.841 154.747 234.4C154.974 234.595 154.934 235.262 154.903 235.798C154.849 236.702 154.775 237.939 155.877 238.663C157.03 239.419 158.937 239.403 164.893 236.991C165.179 236.875 165.401 236.657 165.51 236.384C165.619 236.111 165.606 235.807 165.473 235.537C165.341 235.267 165.1 235.054 164.804 234.946C164.508 234.837 164.18 234.841 163.894 234.957C159.041 236.923 157.637 236.948 157.276 236.881L157.272 236.88C157.246 236.581 157.247 236.281 157.275 235.983C157.333 235.027 157.411 233.719 156.327 232.792C154.518 231.242 151.957 232.264 149.697 233.166C148.969 233.474 148.218 233.738 147.451 233.956C147.759 233.507 148.239 232.925 148.598 232.488C149.974 230.819 151.398 229.093 150.943 227.503C150.831 227.136 150.628 226.798 150.351 226.514C150.073 226.231 149.729 226.01 149.347 225.872C146.305 224.581 142.033 227.219 137.516 230.003C135.649 231.214 133.698 232.314 131.677 233.296C131.453 233.395 131.271 233.558 131.156 233.762C131.042 233.967 131 234.201 131.037 234.432C131.075 234.664 131.19 234.88 131.365 235.051C131.541 235.221 131.768 235.337 132.015 235.383L132.013 235.379Z'
        }
        fill={theme.palette.primary.dark}
        stroke={'white'}
      />
      <path
        d={
          'M909.99 259.836L849.528 237.016C845.898 235.646 844.441 231.29 846.515 228.009L872.522 186.866C874.71 183.407 879.026 181.959 882.853 183.403L934.715 202.977L909.991 259.833L909.99 259.836Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M909.813 260.305L849.351 237.485C847.481 236.779 846.041 235.285 845.407 233.387C844.772 231.49 845.02 229.432 846.091 227.742L872.098 186.599C874.402 182.955 878.998 181.416 883.029 182.937L934.891 202.511C935.02 202.56 935.121 202.659 935.177 202.787C935.229 202.914 935.229 203.057 935.174 203.183L910.45 260.038C910.343 260.283 910.062 260.402 909.812 260.308L909.813 260.305ZM882.678 183.877C879.088 182.522 874.998 183.894 872.948 187.138L846.941 228.281C846.035 229.715 845.822 231.464 846.362 233.072C846.9 234.684 848.12 235.948 849.709 236.548L909.723 259.199L934.049 203.266L882.682 183.878L882.678 183.877Z'
        }
        fill={'#0C2007'}
      />
      <path
        d={
          'M917.728 262.757L905.994 258.328C902.364 256.958 900.907 252.602 902.981 249.322L931.627 204.007C932.277 202.976 933.564 202.547 934.702 202.977L937.72 204.116C938.971 204.588 939.65 205.941 939.282 207.229L924.29 259.465C923.485 262.271 920.458 263.787 917.731 262.758L917.728 262.757Z'
        }
        fill={theme.palette.primary.light}
      />
      <path
        d={
          'M917.552 263.226L905.818 258.798C903.945 258.091 902.508 256.598 901.874 254.7C901.239 252.802 901.487 250.744 902.557 249.054L931.204 203.74C931.978 202.513 933.526 201.997 934.88 202.508L937.898 203.647C939.384 204.207 940.201 205.838 939.764 207.364L924.772 259.601C924.349 261.071 923.324 262.31 921.958 262.995C920.592 263.68 918.988 263.765 917.556 263.224L917.552 263.226ZM934.526 203.446C933.613 203.102 932.573 203.452 932.051 204.277L903.405 249.592C902.498 251.029 902.285 252.776 902.825 254.384C903.364 255.995 904.583 257.263 906.171 257.862L917.905 262.291C919.093 262.739 920.369 262.671 921.505 262.102C922.639 261.533 923.457 260.548 923.806 259.329L938.799 207.092C939.093 206.067 938.54 204.965 937.543 204.588L934.525 203.449L934.526 203.446Z'
        }
        fill={'#0C2007'}
      />
      <path
        d={
          'M914.601 222.675L867.261 204.808C867.002 204.71 866.871 204.421 866.969 204.162C867.067 203.902 867.356 203.772 867.615 203.87L914.955 221.737C916.147 222.187 917.475 221.762 918.187 220.709L929.01 204.662C929.165 204.431 929.477 204.37 929.707 204.529C929.937 204.684 929.995 204.995 929.84 205.226L919.018 221.272C918.044 222.717 916.229 223.293 914.6 222.678L914.601 222.675Z'
        }
        fill={'#0C2007'}
      />
      <path
        d={
          'M896.509 215.311L893.164 220.16C892.607 220.982 891.558 221.319 890.629 220.968L881.254 217.43C880.234 217.045 879.86 215.793 880.507 214.911L883.743 210.493L896.512 215.312L896.509 215.311Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M890.452 221.438L881.076 217.899C880.441 217.66 879.966 217.148 879.775 216.5C879.582 215.848 879.701 215.164 880.101 214.615L883.337 210.197C883.47 210.015 883.707 209.943 883.92 210.024L896.689 214.843C896.832 214.897 896.945 215.015 896.989 215.163C897.034 215.309 897.012 215.472 896.921 215.599L893.576 220.448C892.889 221.464 891.604 221.876 890.451 221.441L890.452 221.438ZM883.919 211.095L880.909 215.209C880.695 215.5 880.631 215.869 880.733 216.215C880.835 216.561 881.088 216.835 881.426 216.963L890.802 220.501C891.518 220.771 892.318 220.516 892.743 219.883L895.726 215.555L883.915 211.097L883.919 211.095Z'
        }
        fill={'#0C2007'}
      />
      <path
        d={
          'M898.944 251.085L848.652 232.104C848.392 232.006 848.262 231.717 848.36 231.458C848.458 231.198 848.747 231.067 849.006 231.165L899.298 250.147C899.558 250.245 899.688 250.533 899.59 250.793C899.492 251.053 899.204 251.183 898.944 251.085Z'
        }
        fill={'#0C2007'}
      />
      <path
        d={
          'M895.562 185.891L891.637 184.41C890.577 184.01 889.392 184.546 888.992 185.606L896.757 188.537C897.157 187.476 896.622 186.291 895.562 185.891V185.891Z'
        }
        fill={theme.palette.primary.light}
      />
      <path
        d={
          'M896.583 189.007L888.818 186.076C888.558 185.979 888.428 185.69 888.526 185.43C889.023 184.113 890.5 183.445 891.817 183.942L895.742 185.423C897.058 185.92 897.726 187.398 897.229 188.715C897.131 188.975 896.842 189.105 896.583 189.007V189.007ZM889.716 185.343L896.39 187.862C896.412 187.217 896.022 186.601 895.387 186.362L891.463 184.88C890.828 184.641 890.128 184.848 889.719 185.345L889.716 185.343Z'
        }
        fill={'#0C2007'}
      />
      <path
        d={
          'M927.023 197.766L923.098 196.285C922.038 195.885 920.853 196.421 920.453 197.481L928.218 200.412C928.618 199.351 928.083 198.166 927.023 197.766V197.766Z'
        }
        fill={theme.palette.primary.light}
      />
      <path
        d={
          'M928.042 200.881L920.277 197.95C920.017 197.852 919.887 197.564 919.985 197.304C920.482 195.987 921.959 195.319 923.276 195.816L927.201 197.297C928.517 197.794 929.185 199.272 928.688 200.589C928.59 200.848 928.301 200.979 928.042 200.881V200.881ZM921.178 197.218L927.852 199.737C927.874 199.091 927.484 198.476 926.85 198.237L922.925 196.755C922.29 196.516 921.59 196.723 921.181 197.219L921.178 197.218Z'
        }
        fill={'#0C2007'}
      />
      <path
        d={
          'M926.038 197.276L928.089 193.754C929.542 191.258 928.563 188.419 925.899 187.413L902.36 178.529C899.696 177.523 896.356 178.733 894.9 181.228L892.849 184.749L894.65 185.429L896.245 182.69C897.378 180.748 899.977 179.806 902.051 180.589L924.126 188.92C926.199 189.703 926.962 191.914 925.832 193.857L924.237 196.596L926.038 197.276Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M925.86 197.745L924.059 197.065C923.921 197.013 923.812 196.904 923.762 196.764C923.711 196.623 923.726 196.471 923.803 196.343L925.398 193.604C925.875 192.783 925.982 191.894 925.7 191.098C925.423 190.318 924.801 189.712 923.951 189.391L901.876 181.059C900.024 180.36 897.694 181.207 896.681 182.944L895.086 185.683C894.963 185.894 894.704 185.986 894.475 185.9L892.674 185.22C892.537 185.168 892.428 185.059 892.377 184.918C892.327 184.778 892.341 184.626 892.418 184.498L894.469 180.976C896.04 178.281 899.659 176.974 902.539 178.061L926.078 186.945C927.495 187.48 928.533 188.504 929.003 189.825C929.48 191.174 929.31 192.657 928.526 194.005L926.475 197.527C926.352 197.738 926.093 197.829 925.865 197.743L925.86 197.745ZM924.967 196.336L925.817 196.657L927.654 193.505C928.29 192.415 928.431 191.228 928.054 190.164C927.685 189.124 926.855 188.314 925.719 187.886L902.181 179.001C899.738 178.08 896.667 179.193 895.331 181.484L893.578 184.492L894.428 184.813L895.81 182.44C897.058 180.298 899.937 179.259 902.226 180.123L924.301 188.454C925.433 188.882 926.266 189.704 926.644 190.765C927.026 191.845 926.891 193.035 926.263 194.113L924.966 196.339L924.967 196.336Z'
        }
        fill={'#0C2007'}
      />
      <path
        d={
          'M925.319 185.418L904.641 177.614C903.81 177.3 902.763 177.677 902.309 178.456L901.323 180.15C900.868 180.929 901.176 181.817 902.008 182.131L922.685 189.935C923.517 190.249 924.563 189.872 925.018 189.093L926.004 187.399C926.458 186.62 926.15 185.732 925.319 185.418Z'
        }
        fill={theme.palette.primary.main}
      />
      <path
        d={
          'M922.507 190.404L901.83 182.6C901.292 182.397 900.894 182.004 900.712 181.492C900.529 180.973 900.589 180.406 900.889 179.897L901.874 178.203C902.455 177.204 903.749 176.738 904.819 177.142L925.496 184.946C926.034 185.149 926.432 185.542 926.614 186.054C926.797 186.573 926.736 187.14 926.437 187.649L925.451 189.343C924.871 190.342 923.577 190.808 922.507 190.404ZM904.462 178.079C903.858 177.851 903.07 178.14 902.739 178.705L901.753 180.398C901.608 180.651 901.571 180.919 901.657 181.155C901.739 181.383 901.924 181.564 902.184 181.662L922.861 189.466C923.465 189.694 924.253 189.405 924.584 188.84L925.569 187.146C925.715 186.894 925.752 186.626 925.666 186.389C925.584 186.162 925.398 185.981 925.139 185.883L904.462 178.079Z'
        }
        fill={'#0C2007'}
      />
      <path
        d={
          'M907.4 254.277C907.238 254.215 907.11 254.071 907.083 253.886C907.04 253.612 907.227 253.354 907.501 253.31L917.061 251.797L934.082 206.698C934.18 206.439 934.469 206.308 934.728 206.406C934.988 206.504 935.118 206.793 935.02 207.052L917.896 252.424C917.832 252.593 917.683 252.715 917.505 252.741L907.655 254.302C907.568 254.315 907.478 254.306 907.4 254.277Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M791.318 42.144L791.318 42.1435C789.099 37.3945 790.58 31.192 795.234 27.231C795.897 27.6838 796.561 28.1366 797.224 28.5894C798.936 29.7588 800.648 30.9279 802.362 32.0955C797.797 36.2143 795.924 41.6689 796.142 46.5557L796.064 46.4755L796.141 47.0621C794.263 46.0714 792.432 44.5337 791.318 42.144ZM801.201 55.6293C799.27 54.0228 797.938 51.5655 797.393 48.7158C799.052 49.3426 800.798 49.7158 802.57 49.8218L802.57 49.8219L802.583 49.8224C808.534 50.0237 812.608 46.4903 814.025 45.0432C814.607 46.0876 815.19 47.1316 815.773 48.1754C816.944 50.2751 818.115 52.3737 819.285 54.4725C813.149 59.0963 805.415 59.1333 801.201 55.6293Z'
        }
        fill={'#E0E0E0'}
        stroke={'black'}
      />
      <path
        d={
          'M307.068 219.511L307.067 219.511C304.072 213.099 306.102 204.737 312.403 199.442C313.318 200.067 314.232 200.691 315.147 201.316C317.477 202.907 319.807 204.498 322.139 206.087C315.694 211.812 313.247 219.537 313.847 226.272C311.225 224.953 308.625 222.853 307.068 219.511Z'
        }
        fill={theme.palette.primary.main}
        stroke={'black'}
      />
      <path
        d={
          'M320.229 237.467C317.566 235.252 315.758 231.842 315.066 227.907C317.332 228.796 319.728 229.324 322.161 229.47L322.161 229.47L322.174 229.47C330.233 229.743 335.682 224.807 337.39 223.018C338.199 224.468 339.008 225.917 339.816 227.366C341.395 230.195 342.973 233.023 344.549 235.851C336.342 242.107 325.921 242.201 320.229 237.467Z'
        }
        fill={theme.palette.primary.main}
        stroke={'black'}
      />
      <path
        d={
          'M977.528 121.748L977.528 121.748C975.469 120.118 974.26 118.505 973.466 116.905C972.667 115.295 972.279 113.682 971.879 112.01L971.873 111.985C971.478 110.332 971.068 108.62 970.217 106.903C969.357 105.166 968.055 103.439 965.888 101.721C961.518 98.2484 957.916 98.2309 954.474 98.2142C953.754 98.2107 953.041 98.2073 952.329 98.1722C951.673 98.1385 951.071 97.9869 950.532 97.7452L960.046 85.0267C961.372 85.3372 962.639 85.4319 963.87 85.4653C964.394 85.4794 964.901 85.4827 965.401 85.486C966.199 85.4911 966.981 85.4962 967.793 85.545C970.365 85.6997 973.02 86.2918 976.146 88.7724L976.146 88.7728C978.204 90.4025 979.413 92.0154 980.207 93.6154C981.006 95.2256 981.394 96.8384 981.795 98.5108L981.801 98.5357C982.196 100.188 982.605 101.9 983.456 103.618C984.317 105.354 985.619 107.082 987.786 108.8C989.956 110.523 991.937 111.402 993.826 111.847C995.697 112.287 997.461 112.297 999.163 112.307L999.181 112.307C1000.9 112.316 1002.56 112.327 1004.31 112.738C1006.05 113.147 1007.9 113.956 1009.95 115.589L1009.95 115.589C1012.11 117.301 1013.38 118.878 1014.22 120.403C1015 121.826 1015.42 123.222 1015.81 124.701L1003.49 133.03C1002.67 131.478 1001.48 129.966 999.682 128.537C997.515 126.816 995.536 125.939 993.649 125.495C991.781 125.055 990.02 125.045 988.319 125.034L988.297 125.034C986.578 125.024 984.919 125.012 983.169 124.6C981.43 124.191 979.584 123.381 977.528 121.748Z'
        }
        fill={theme.palette.primary.light}
        stroke={'black'}
      />
      <path
        d={
          'M121.495 75.7166C125.025 73.8135 126.063 71.4226 127.036 69.1802C127.238 68.7147 127.437 68.2556 127.656 67.8086C127.823 67.4674 128.033 67.1742 128.273 66.9271L133.763 76.4357C133.211 77.1998 132.805 77.9824 132.45 78.7536C132.292 79.097 132.147 79.4284 132.005 79.7545C131.78 80.2691 131.562 80.7702 131.304 81.2832C130.495 82.8953 129.387 84.4354 126.929 85.762L126.928 85.7623C125.31 86.6386 123.942 86.9696 122.699 87.0389C121.446 87.1088 120.302 86.9144 119.099 86.7077L119.075 86.7036C117.891 86.5002 116.647 86.2866 115.274 86.3622C113.88 86.439 112.37 86.8114 110.627 87.7534C108.879 88.6957 107.737 89.7553 106.906 90.8797C106.086 91.9893 105.582 93.1487 105.101 94.2525L105.094 94.2693C104.606 95.3895 104.142 96.4529 103.396 97.4634C102.657 98.4646 101.631 99.4274 100.011 100.301L100.011 100.301C98.3062 101.223 96.9418 101.605 95.7365 101.722C94.6452 101.828 93.6633 101.721 92.6298 101.571L90.6931 91.4764C91.9056 91.3571 93.1902 90.9985 94.5798 90.2487C96.3247 89.3074 97.4656 88.2487 98.2954 87.1253C99.1137 86.0175 99.6181 84.8599 100.098 83.7576L100.107 83.7381C100.595 82.6187 101.06 81.5558 101.806 80.5456C102.545 79.5446 103.572 78.582 105.192 77.7082L105.193 77.7078C106.812 76.8315 108.179 76.5005 109.422 76.4312C110.675 76.3614 111.819 76.5557 113.022 76.7625L113.046 76.7665C114.23 76.9699 115.474 77.1836 116.847 77.1079C118.241 77.0311 119.752 76.6587 121.495 75.7166ZM121.495 75.7166C121.495 75.7166 121.495 75.7166 121.495 75.7165L121.257 75.2767L121.494 75.7168C121.495 75.7167 121.495 75.7167 121.495 75.7166ZM128.404 66.1541C128.268 66.2528 128.138 66.3612 128.014 66.4794L128.656 66.5899L128.404 66.1541Z'
        }
        fill={theme.palette.primary.main}
        stroke={'black'}
      />
      <path
        d={
          'M329.95 23.2696L329.952 23.2702C340.983 25.5393 348.649 33.8511 351.165 36.8542C352.568 33.8219 353.969 30.7812 355.367 27.7369C352.882 24.1595 347.068 16.4765 339.017 11.548L339.017 11.5479C331.222 6.77194 326.334 7.6554 325.981 7.71933C325.965 7.72223 325.958 7.72344 325.961 7.72235C325.916 7.73754 325.872 7.75172 325.839 7.76188L325.797 7.7746L325.794 7.77559L325.78 7.78058L325.711 7.80513C325.692 7.81165 325.672 7.81888 325.65 7.82662C325.624 7.86965 325.593 7.9249 325.555 7.99325C325.438 8.20379 325.272 8.51603 325.062 8.92437C324.642 9.73984 324.05 10.9253 323.321 12.4174C321.964 15.1978 320.137 19.0373 318.075 23.5204C321.804 22.5323 325.85 22.4496 329.95 23.2696Z'
        }
        fill={theme.palette.primary.light}
        stroke={'black'}
      />
      <path
        d={
          'M27.1885 156.793L27.1875 156.796C23.3589 167.387 14.0329 173.78 10.6999 175.839C13.4993 177.663 16.3075 179.486 19.1196 181.306C23.0166 179.36 31.4546 174.709 37.4875 167.449L37.4877 167.449C43.3328 160.42 43.1599 155.456 43.1474 155.097C43.1469 155.081 43.1466 155.074 43.1473 155.077C43.1387 155.031 43.131 154.984 43.1257 154.951L43.119 154.908L43.1185 154.904L43.1156 154.89L43.1012 154.818C43.0974 154.799 43.0932 154.777 43.0887 154.754C43.0498 154.723 42.9997 154.684 42.9375 154.636C42.7459 154.49 42.4606 154.282 42.0867 154.015C41.34 153.482 40.2518 152.726 38.8797 151.791C36.3229 150.049 32.7853 147.69 28.6445 145.006C29.0872 148.838 28.5884 152.854 27.1885 156.793Z'
        }
        fill={'#E0E0E0'}
        stroke={'black'}
      />
      <path
        d={
          'M633.056 113.631L633.056 113.631C638.798 121.237 643.974 122.236 644.326 122.304C644.341 122.307 644.347 122.308 644.345 122.308C644.394 122.311 644.443 122.314 644.479 122.317L644.525 122.32L644.529 122.321L644.546 122.321L644.624 122.324C644.649 122.325 644.678 122.326 644.708 122.327C644.753 122.294 644.811 122.249 644.882 122.193C645.083 122.034 645.372 121.796 645.743 121.482C646.485 120.856 647.546 119.94 648.864 118.779C651.324 116.613 654.678 113.601 658.523 110.057C654.459 109.592 650.447 108.128 646.747 105.769L646.745 105.767C636.808 99.354 632.648 88.3708 631.407 84.549C628.788 86.9517 626.167 89.3636 623.548 91.78C624.54 96.1734 627.142 105.804 633.056 113.631Z'
        }
        fill={theme.palette.primary.main}
        stroke={'black'}
      />
      <defs>
        <clipPath id={'clip0_23526_19832'}>
          <rect width={'110'} height={'107'} fill={'white'} transform={'translate(234.18 40) rotate(11.4162)'} />
        </clipPath>
        <clipPath id={'clip1_23526_19832'}>
          <rect width={'217'} height={'121'} fill={'white'} transform={'translate(350 69)'} />
        </clipPath>
        <clipPath id={'clip2_23526_19832'}>
          <rect width={'124'} height={'78'} fill={'white'} transform={'translate(582 188)'} />
        </clipPath>
      </defs>
    </svg>
  );
};
