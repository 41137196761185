import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { ArrowForward } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useIsMobile } from '@lama/app-components';
import { useNavigate } from 'react-router-dom';
import { Flex } from '@lama/design-system';
import { personFullName } from '@lama/data-formatters';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { analyticsEvent } from '../../shared/utils/analytics';
import { ApplicationContext } from '../../shared/contexts/ApplicationContext';
import { useSubmitApplicationMutation } from '../../hooks/react-query/useSubmitApplicationMutation';
import type { ScreenProps } from '../ScreenProps';
import { UserDetailsContext } from '../../shared/contexts/UserDetailsContext';
import { FibtSignAndSubmit } from '../fibtSignAndSubmit/FibtSignAndSubmit';
import { usePartnerFromSubDomainQuery } from '../../hooks/react-query/usePartnerFromSubdomainQuery';
import { Signature } from './Signature';

interface SignAndSubmitScreenProps extends ScreenProps {
  title?: string;
  description?: string;
}

export const SignAndSubmitScreen: React.FC<SignAndSubmitScreenProps> = ({ title, description, ...props }) => {
  const { fibtDemo } = useFlags();
  const { data: partner } = usePartnerFromSubDomainQuery();
  const useSignatureForSubmitApplication = partner?.featureConfigurations?.useSignatureForSubmitApplication;

  const { t } = useTranslation();
  const { application, requirements } = useContext(ApplicationContext);
  const { id: applicationId } = application;
  const userDetails = useContext(UserDetailsContext);
  const fullName = useMemo(() => {
    if (!userDetails?.firstName || !userDetails?.lastName) {
      return '';
    }

    const { firstName, lastName } = userDetails;

    return personFullName({ firstName, lastName });
  }, [userDetails]);

  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const { mutateAsync: submitApplication, isPending: isSubmitting } = useSubmitApplicationMutation(applicationId);

  const allRequirementsSubmitted = useMemo(
    () => requirements.length && requirements.every(({ submitted, isInvalidSubmission }) => submitted && !isInvalidSubmission),
    [requirements],
  );

  const onSaveClick = useCallback(async () => {
    analyticsEvent({
      action: 'signed_and_submitted',
      category: 'conversion',
    });
    await submitApplication();
    navigate('../overview');
  }, [navigate, submitApplication]);

  useEffect(() => {
    if (allRequirementsSubmitted) {
      navigate('../overview');
    }
  }, [navigate, allRequirementsSubmitted]);

  const [isSigned, setIsSigned] = useState<boolean>(false);

  const sign = useCallback(() => {
    setIsSigned(true);
  }, []);

  if (!userDetails) {
    navigate('../overview');
    return null;
  }

  return fibtDemo ? (
    <FibtSignAndSubmit title={title} {...props} />
  ) : (
    <Flex flexDirection={'column'} gap={4} alignItems={isMobile ? 'center' : 'left'} width={'100%'}>
      <Typography variant={isMobile ? 'h5' : 'h4'} maxWidth={'482px'} textAlign={'left'}>
        {title ?? t('signAndSubmit.title')}
      </Typography>
      <Typography variant={'caption'} maxWidth={'640px'} textAlign={'left'} color={'text.secondary'} whiteSpace={'pre-line'}>
        {description ?? t('signAndSubmit.description')}
      </Typography>
      {useSignatureForSubmitApplication ? <Signature fullName={fullName} sign={sign} isSigned={isSigned} /> : null}
      <LoadingButton
        loading={isSubmitting}
        disabled={useSignatureForSubmitApplication ? !isSigned : false}
        endIcon={<ArrowForward />}
        size={'medium'}
        variant={'contained'}
        color={'primary'}
        onClick={onSaveClick}
        sx={{ width: '236px', height: '36px' }}
      >
        {t('signAndSubmit.submit')}
      </LoadingButton>
    </Flex>
  );
};
