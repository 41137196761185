/* eslint-disable @typescript-eslint/naming-convention */
import { v4 as uuidv4 } from 'uuid';
import { useIsMobile, FormikMoneyInputField } from '@lama/app-components';
import { getYear } from '@lama/data-formatters';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import type { FinancialData } from '@lama/contracts';
import { useAsyncFn } from 'react-use';
import { Flex, Text } from '@lama/design-system';
import { getFinancialAttribute } from '@lama/selectors';
import type { BusinessApiModel } from '@lama/business-service-client';
import type { ScreenProps } from '../ScreenProps';
import { BasicScreen } from '../shared/BasicScreen';
import { useGetRequiredFields } from '../../hooks/useGetRequiredFields';
import { RequirementInput } from '../shared/RequirmentInput';
import { BasicForm } from '../shared/BasicForm';
import { ApplicationContext } from '../../shared/contexts/ApplicationContext';
import { UserDetailsContext } from '../../shared/contexts/UserDetailsContext';
import { useUpdateBusinessFinancialsMutation } from '../../hooks/react-query/useUpdateBusinessFinancialsMutation';
import { useDeleteBusinessFinancialsMutation } from '../../hooks/react-query/useDeleteBusinessFinancialsMutation';
import { useCreateBusinessFinancialsMutation } from '../../hooks/react-query/useCreateBusinessFinancialsMutation';
import { useGetCurrentRequirement } from '../../hooks/useGetCurrentRequirement';

const validationSchema = yup.object({
  gross1: yup.number().min(0),
  net1: yup.number(),
  gross2: yup.number().min(0),
  net2: yup.number(),
  gross3: yup.number().min(0),
  net3: yup.number(),
  gross4: yup.number().min(0),
  net4: yup.number(),
});

const getFinancialFieldByYear = (financials: FinancialData[], year: number, fieldName: string) =>
  financials.find(({ startDate, endDate, type }) => getYear(startDate) === year && getYear(endDate) === year && type === fieldName);

const createOrUpdatefinancialField = (
  financials: FinancialData[],
  year: number,
  fieldName: 'Net Income' | 'Net Revenue',
  value: number | undefined,
  userId: string,
) => {
  const fieldToUpdate = getFinancialFieldByYear(financials, year, fieldName);
  return (!!fieldToUpdate && fieldToUpdate.value !== value) || (!fieldToUpdate && value)
    ? [
        {
          id: fieldToUpdate?.id ?? uuidv4(),
          source: fieldToUpdate?.source ?? { type: 'Manual', user: { type: 'Borrower', userId } },
          startDate: fieldToUpdate?.startDate ?? `${year}-01-01T00:00:00.000Z`,
          endDate: fieldToUpdate?.endDate ?? `${year}-12-31T00:00:00.000Z`,
          type: fieldName,
          value: value ? Number(value) : undefined,
        },
      ]
    : [];
};

export const RevenueScreen: React.FC<ScreenProps> = ({ onNextClick, onBackClick, nextEnabled, ...stepsNavigationProps }) => {
  const { application } = useContext(ApplicationContext);
  const user = useContext(UserDetailsContext);
  const { t } = useTranslation();

  const { mutateAsync: updateBusinessFinancials } = useUpdateBusinessFinancialsMutation(application.id);
  const { mutateAsync: deleteBusinessFinancials } = useDeleteBusinessFinancialsMutation(application.id);
  const { mutateAsync: createBusinessFinancials } = useCreateBusinessFinancialsMutation(application.id);

  const requirement = useGetCurrentRequirement();

  const relevantBusiness = useMemo(
    () => application.relatedBusinesses.find(({ business }) => business.id === requirement?.entityId)?.business,
    [application, requirement?.entityId],
  );

  const financials = useMemo<FinancialData[]>(() => relevantBusiness?.financials ?? [], [relevantBusiness?.financials]);

  const isMobile = useIsMobile();
  const requiredFields = useGetRequiredFields();
  const currentYear = new Date().getUTCFullYear();

  const selectorContext = useMemo(() => {
    if (!relevantBusiness) {
      return null;
    }

    return {
      application,
      entity: relevantBusiness,
      entityType: 'business' as const,
      yearsBack: currentYear,
    };
  }, [relevantBusiness, currentYear, application]);

  const initialValues = useMemo(
    () => ({
      gross1: selectorContext
        ? getFinancialAttribute<BusinessApiModel>({ selectorContext, financialType: 'Net Revenue' })?.value ?? undefined
        : undefined,
      net1: selectorContext ? getFinancialAttribute({ selectorContext, financialType: 'Net Income' })?.value ?? undefined : undefined,
      gross2: selectorContext ? getFinancialAttribute({ selectorContext, financialType: 'Net Revenue' })?.value ?? undefined : undefined,
      net2: selectorContext ? getFinancialAttribute({ selectorContext, financialType: 'Net Income' })?.value ?? undefined : undefined,
      gross3: selectorContext ? getFinancialAttribute({ selectorContext, financialType: 'Net Revenue' })?.value ?? undefined : undefined,
      net3: selectorContext ? getFinancialAttribute({ selectorContext, financialType: 'Net Income' })?.value ?? undefined : undefined,
      gross4: selectorContext ? getFinancialAttribute({ selectorContext, financialType: 'Net Revenue' })?.value ?? undefined : undefined,
      net4: selectorContext ? getFinancialAttribute({ selectorContext, financialType: 'Net Income' })?.value ?? undefined : undefined,
    }),
    [selectorContext],
  );

  const [{ loading: savingFinancialsChanges }, saveFinancialsChanges] = useAsyncFn(
    async (newValues: typeof initialValues) => {
      if (!user?.id || !relevantBusiness) {
        onNextClick();
        return;
      }

      const { id: userId } = user;

      const { gross1, net1, gross2, net2, gross3, net3, gross4, net4 } = newValues;
      const revenuePayload: Partial<FinancialData>[] = [
        ...createOrUpdatefinancialField(financials, currentYear, 'Net Revenue', gross1, userId),
        ...createOrUpdatefinancialField(financials, currentYear, 'Net Income', net1, userId),
        ...createOrUpdatefinancialField(financials, currentYear - 1, 'Net Revenue', gross2, userId),
        ...createOrUpdatefinancialField(financials, currentYear - 1, 'Net Income', net2, userId),
        ...createOrUpdatefinancialField(financials, currentYear - 2, 'Net Revenue', gross3, userId),
        ...createOrUpdatefinancialField(financials, currentYear - 2, 'Net Income', net3, userId),
        ...createOrUpdatefinancialField(financials, currentYear - 3, 'Net Revenue', gross4, userId),
        ...createOrUpdatefinancialField(financials, currentYear - 3, 'Net Income', net4, userId),
      ];

      const financialsIds = financials.map(({ id }) => id) ?? [];
      const updatePayload = revenuePayload
        .filter(({ id, value }) => id && financialsIds.includes(id) && value)
        .map((f) => f as FinancialData);
      const createPayload = revenuePayload
        .filter(({ id, value }) => id && !financialsIds.includes(id) && value)
        .map((f) => f as FinancialData);
      const deletePayload = revenuePayload.filter(({ id, value }) => id && !value).map(({ id }) => id!);

      await Promise.all([
        createPayload.length
          ? createBusinessFinancials({ businessId: relevantBusiness.id, createdBusinessFinancials: createPayload })
          : Promise.resolve(),
        updatePayload.length
          ? updateBusinessFinancials({ businessId: relevantBusiness.id, businessFinancialsUpdate: updatePayload })
          : Promise.resolve(),
        deletePayload.length
          ? deleteBusinessFinancials({ businessId: relevantBusiness.id, deletedBusinessfinancials: deletePayload })
          : Promise.resolve(),
      ]);

      onNextClick();
    },
    [
      user,
      relevantBusiness,
      financials,
      currentYear,
      createBusinessFinancials,
      updateBusinessFinancials,
      deleteBusinessFinancials,
      onNextClick,
    ],
  );

  return (
    <BasicForm initialValues={initialValues} validationSchema={validationSchema} submitCallback={saveFinancialsChanges}>
      {({ handleSubmit, dirty, isValid, submitted }) => (
        <BasicScreen
          title={t('revenue.title')}
          subtitle={t('revenue.subtitle')}
          onNextClick={handleSubmit}
          onBackClick={onBackClick}
          nextLoading={savingFinancialsChanges}
          nextEnabled={!!nextEnabled || (dirty && isValid)}
          {...stepsNavigationProps}
        >
          <Flex flexDirection={'column'} gap={8}>
            <Flex flexDirection={'column'} gap={4}>
              <Text variant={'body1'} color={'disabled'}>
                {currentYear} {'YTD'}
              </Text>
              <Flex gap={4} flexDirection={isMobile ? 'column' : 'row'}>
                <RequirementInput
                  name={'gross1'}
                  label={t('revenue.gross')}
                  Component={FormikMoneyInputField}
                  required={requiredFields.includes('grossIncomeCurrentYear')}
                  submitted={submitted}
                />
                <RequirementInput
                  name={'net1'}
                  label={t('revenue.net')}
                  Component={FormikMoneyInputField}
                  required={requiredFields.includes('netIncomeCurrentYear')}
                  submitted={submitted}
                />
              </Flex>
            </Flex>
            <Flex flexDirection={'column'} gap={4}>
              <Text variant={'body1'} color={'text.disabled'}>
                {currentYear - 1}
              </Text>
              <Flex gap={4} flexDirection={isMobile ? 'column' : 'row'}>
                <RequirementInput
                  name={'gross2'}
                  label={t('revenue.gross')}
                  Component={FormikMoneyInputField}
                  required={requiredFields.includes('grossIncomeLastYear')}
                  submitted={submitted}
                />
                <RequirementInput
                  name={'net2'}
                  label={t('revenue.net')}
                  Component={FormikMoneyInputField}
                  required={requiredFields.includes('netIncomeLastYear')}
                  submitted={submitted}
                />
              </Flex>
            </Flex>
            <Flex flexDirection={'column'} gap={4}>
              <Text variant={'body1'} color={'text.disabled'}>
                {currentYear - 2}
              </Text>
              <Flex gap={4} flexDirection={isMobile ? 'column' : 'row'}>
                <RequirementInput
                  name={'gross3'}
                  label={t('revenue.gross')}
                  Component={FormikMoneyInputField}
                  required={requiredFields.includes('grossIncomeTwoYearsAgo')}
                  submitted={submitted}
                />
                <RequirementInput
                  name={'net3'}
                  label={t('revenue.net')}
                  Component={FormikMoneyInputField}
                  required={requiredFields.includes('netIncomeTwoYearsAgo')}
                  submitted={submitted}
                />
              </Flex>
            </Flex>
            <Flex flexDirection={'column'} gap={4}>
              <Text variant={'body1'} color={'text.disabled'}>
                {currentYear - 3}
              </Text>
              <Flex gap={4} flexDirection={isMobile ? 'column' : 'row'}>
                <RequirementInput
                  name={'gross4'}
                  label={t('revenue.gross')}
                  Component={FormikMoneyInputField}
                  required={requiredFields.includes('grossIncomeThreeYearsAgo')}
                  submitted={submitted}
                />
                <RequirementInput
                  name={'net4'}
                  label={t('revenue.net')}
                  Component={FormikMoneyInputField}
                  required={requiredFields.includes('netIncomeThreeYearsAgo')}
                  submitted={submitted}
                />
              </Flex>
            </Flex>
          </Flex>
        </BasicScreen>
      )}
    </BasicForm>
  );
};
