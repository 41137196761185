import type { FC } from 'react';
import React from 'react';
import { Link } from '@mui/material';
import { useIsMobile } from '@lama/app-components';
import { Flex } from '@lama/design-system';
import styled from 'styled-components';
import { SideNavCategories } from './SideNavCategories';
import { SideNavContactInfo } from './SideNavContactInfo';

interface SidebarProps {
  logoUrl: string;
}

const sideNavWidth = 375;

export const sideNavZindex = 500;
const SideNavContainer = styled(Flex)`
  height: 100%;
  width: ${sideNavWidth}px;
  z-index: ${sideNavZindex};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  background-color: white;
  position: fixed;
`;

export const SideNav: FC<SidebarProps> = ({ logoUrl }) => {
  const isMobile = useIsMobile();

  return (
    <Flex flexDirection={'column'} width={sideNavWidth}>
      <SideNavContainer flexDirection={'column'} pt={isMobile ? 0 : 4} py={4} data-testid={'sidebar'}>
        <Link href={'/'} px={4}>
          <img height={'28px'} src={logoUrl} />
        </Link>
        <SideNavCategories />
        <SideNavContactInfo />
      </SideNavContainer>
    </Flex>
  );
};
