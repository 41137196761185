import type { PartnerPublicDataApiModel } from '@lama/partner-service-client';

const buildLogoUrlForPartnerId = (partnerId: string, size: 'big' | 'small' = 'big') =>
  `https://lama-ai-assets.s3.amazonaws.com/partner-logos/${partnerId.toLowerCase()}${size === 'small' ? '_small' : ''}.png`;

export const getLogoUrl = (partner: PartnerPublicDataApiModel | null, overrideParamName: string | null, size: 'big' | 'small' = 'big') => {
  if (!partner) {
    return '';
  }

  if (overrideParamName) {
    const override = partner.branding.overridesByParamName?.[overrideParamName]?.additionalOverrides?.logoUrl;
    if (override) {
      return buildLogoUrlForPartnerId(override, size);
    }
  }
  return buildLogoUrlForPartnerId(partner.id, size);
};
