import * as pdfjsLib from 'pdfjs-dist';

pdfjsLib.GlobalWorkerOptions.workerSrc = '//cdnjs.cloudflare.com/ajax/libs/pdf.js/3.7.107/pdf.worker.js'; // Replace <version> with the version of PDF.js you're using

export const isDocumentPasswordProtected = async (file: File): Promise<boolean> => {
  try {
    const arrayBuffer = await file.arrayBuffer();

    await pdfjsLib.getDocument({ data: arrayBuffer }).promise;

    return false;
  } catch (error: any) {
    console.error(error);
    return error?.name === 'PasswordException';
  }
};
