import React, { useCallback } from 'react';
import type { FC } from 'react';
import { Flex, Text } from '@lama/design-system';

import { TextField } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import { componentRestrictoreContext } from './ComponentRestrictorContext';

interface ComponentRestrictorProps {
  restricted?: boolean;
  children: React.ReactNode;
  componentType?: 'input' | 'textField';
  displayName?: string;
  isEmptyValue: boolean;
}

export const ComponentRestrictor: FC<ComponentRestrictorProps> = ({
  children,
  displayName,
  restricted,
  componentType = 'outlined',
  isEmptyValue,
}) => {
  const [valueVisible, setValueVisible] = React.useState<boolean>(!restricted);
  const { isAllowedToSeeValueHandler, overrideRestriction } = React.useContext(componentRestrictoreContext);
  const onClick = useCallback(async () => {
    if (isAllowedToSeeValueHandler) {
      const allowed = await isAllowedToSeeValueHandler();
      setValueVisible(allowed);
    }
  }, [isAllowedToSeeValueHandler]);

  if (!overrideRestriction && !valueVisible) {
    if (componentType === 'input') {
      return (
        <TextField
          fullWidth
          type={'text'}
          onClick={onClick}
          label={displayName}
          variant={'outlined'}
          slotProps={{
            input: {
              startAdornment: (
                <Flex style={{ color: '#00000099' }} pr={1}>
                  <LockIcon />
                </Flex>
              ),
            },
          }}
          minRows={3}
          maxRows={8}
          value={isEmptyValue ? '-' : '*****'}
          role={'input'}
        />
      );
    }
    return (
      <Text onClick={onClick} variant={'body2'}>
        {isEmptyValue ? '-' : '****'}
      </Text>
    );
  }
  return children;
};
