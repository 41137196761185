/* eslint-disable react/no-array-index-key */
import React, { useCallback, useContext, useMemo } from 'react';
import { useIsMobile } from '@lama/app-components';
import { Flex } from '@lama/design-system';
import { formatValue, loanDisplayNameSelector } from '@lama/data-formatters';
import { useTranslation } from 'react-i18next';
import type { ScreenProps } from '../ScreenProps';
import { BasicScreen } from '../shared/BasicScreen';
import { analyticsEvent } from '../../shared/utils/analytics';
import { ApplicationContext } from '../../shared/contexts/ApplicationContext';
import { useSubmitApplicationMutation } from '../../hooks/react-query/useSubmitApplicationMutation';
import { usePartnerFromSubDomainQuery } from '../../hooks/react-query/usePartnerFromSubdomainQuery';
import { useBrandOverrides } from '../../hooks/useBrandOverrides';
import { SummaryCard } from './SummaryCard';
import { DollarIcon } from './DollarIcon';
import { LoanTypeIcon } from './LoanTypeIcon';
import { BusinessIcon } from './BusinessIcon';

type ApplicationSubmissionScreenProps = ScreenProps & {
  cards?: string[];
};
const FROM_PHONE_NUMBER = '+13158733245';
const TO_PHONE_NUMBER = '+18373656515';
const sendDemoTwilioFlow = async () => {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');
  myHeaders.append('Authorization', 'Basic <TOKEN_GOES_HERE>');

  const urlencoded = new URLSearchParams();
  urlencoded.append('To', TO_PHONE_NUMBER);
  urlencoded.append('From', FROM_PHONE_NUMBER);

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: urlencoded,
    redirect: 'follow' as const,
  };

  try {
    await fetch('https://studio.twilio.com/v2/Flows/FW7393ccfe15f8224d60799d0f629e7514/Executions', requestOptions);
  } catch (error) {
    console.error(error);
  }
};

export const ApplicationSubmissionScreen: React.FC<ApplicationSubmissionScreenProps> = ({
  cards = ['borrowers', 'loanType', 'loanAmount'],
  onNextClick = () => {},
  onBackClick,
  nextLoading,
  ...stepsNavigationProps
}) => {
  const isMobile = useIsMobile();
  const { application, product } = useContext(ApplicationContext);
  const { data: partner } = usePartnerFromSubDomainQuery();
  const { mutate: submitApplication, isPending: submittingApplication } = useSubmitApplicationMutation(application.id);

  const { t } = useTranslation();

  const submit = useCallback(async () => {
    analyticsEvent({
      action: 'onboarding_completed',
      category: 'conversion',
    });
    if (!partner?.featureConfigurations?.enableAutoMoveToApplication) {
      submitApplication();
    }
    if (partner?.branding.themeOverrideParamName) {
      const url = new URL(window.location.href);
      url.searchParams.delete(partner?.branding.themeOverrideParamName);
    }
    onNextClick();
  }, [
    onNextClick,
    partner?.featureConfigurations?.enableAutoMoveToApplication,
    submitApplication,
    partner?.branding.themeOverrideParamName,
  ]);

  const borrowersCard = useMemo(
    () => ({ Icon: BusinessIcon, text1: loanDisplayNameSelector(application) || 'N/A', text2: 'Borrower(s)' }),
    [application],
  );
  const loanTypeCard = useMemo(
    () => ({
      Icon: LoanTypeIcon,
      text1: product?.prettyName ?? '',
      text2: 'Loan Type',
      onClick: partner?.featureConfigurations?.enableHiddenTwilioFlow
        ? () => {
            void sendDemoTwilioFlow();
          }
        : undefined,
    }),
    [product?.prettyName, partner?.featureConfigurations?.enableHiddenTwilioFlow],
  );
  const loanAmountCard = useMemo(
    () => ({ Icon: DollarIcon, text1: formatValue(application.requestedAmount ?? 0, 'currencyCompact'), text2: 'Loan Amount' }),
    [application.requestedAmount],
  );

  const summaryCards: { Icon: React.FC; text1: string; text2: string; onClick?: () => void }[] = useMemo(
    () => [
      ...(cards.includes('borrowers') ? [borrowersCard] : []),
      ...(cards.includes('loanType') ? [loanTypeCard] : []),
      ...(cards.includes('loanAmount') ? [loanAmountCard] : []),
    ],
    [borrowersCard, cards, loanAmountCard, loanTypeCard],
  );
  const overrides = useBrandOverrides();
  return (
    <BasicScreen
      {...stepsNavigationProps}
      title={t('applicationSubmission.title')}
      subtitle={overrides?.conclusionSubtitleOverride ?? t('applicationSubmission.subtitle')}
      onNextClick={submit}
      nextButtonText={t('applicationSubmission.cta')}
      nextEnabled={application.status !== 'Disqualified'}
      nextLoading={submittingApplication || nextLoading}
      onBackClick={onBackClick}
    >
      <Flex flexDirection={isMobile ? 'column' : 'row'} gap={6} justifyContent={'center'}>
        {summaryCards.map(({ Icon, text1, text2, onClick }, index) => (
          <SummaryCard isMobile={isMobile} Icon={Icon} text1={text1} text2={text2} key={`summary_card_${index}`} onClick={onClick} />
        ))}
      </Flex>
    </BasicScreen>
  );
};
