import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { ApplicationApiModel } from '@lama/clients';
import { Modal, ModalContent } from '@lama/design-system';
import { businessOwnershipSumSelector } from '@lama/data-formatters';
import type { BusinessApiModel } from '@lama/business-service-client';
import type { Entity } from '@lama/common-types';
import { type DialogMode } from '../BaseDialog';
import type { BusinessWithOwnership } from '../Principals/BusinessForm';
import { BusinessForm } from '../Principals/BusinessForm';
import { AddOrCreateRelatedEntity } from '../RelationsList/AddOrCreateRelatedEntity';

export interface OwnedBusinessModalProps {
  application: ApplicationApiModel;
  editedBusinessId?: string;
  ownerId: string;
  loading: boolean;
  excludedEntityIds: string[];
  onSubmit: (ownedBusinessValues: BusinessWithOwnership | null) => void;
  onClose: () => void;
  modalOpen: boolean;
}

const initialState = {
  step: 'selection' as const,
  selectedBusiness: null,
  dialogMode: 'add' as const,
};

export const OwnedBusinessModal: React.FC<OwnedBusinessModalProps> = ({
  application,
  loading,
  editedBusinessId,
  excludedEntityIds,
  ownerId,
  onSubmit,
  onClose,
  modalOpen,
}) => {
  const { t } = useTranslation();
  const [selectedBusiness, setSelectedBusiness] = useState<BusinessApiModel | null>(initialState.selectedBusiness);
  const [dialogMode, setDialogMode] = useState<DialogMode>(initialState.dialogMode);

  useMemo(() => {
    const editedBusiness = application.relatedBusinesses.find((b) => b.business.id === editedBusinessId)?.business;
    if (editedBusiness) {
      setSelectedBusiness(editedBusiness);
      setDialogMode('edit');
    }
  }, [application, editedBusinessId]);

  const ownershipPercentage = useMemo(
    () =>
      selectedBusiness?.owningBusinesses?.find(({ id }) => id === ownerId)?.ownershipPercentage ??
      selectedBusiness?.people.find(({ id }) => id === ownerId)?.ownershipPercentage ??
      0,
    [selectedBusiness, ownerId],
  );

  const remainingOwnershipPercentage = useMemo(
    () => 100 - (selectedBusiness ? businessOwnershipSumSelector(selectedBusiness) : 0),
    [selectedBusiness],
  );

  const onEntitySelected = useCallback(
    async (values: Record<string, any>, entityType: Entity) => {
      if (entityType === 'business') {
        setSelectedBusiness(values as BusinessApiModel);
      }
    },
    [setSelectedBusiness],
  );

  const resetAndClose = useCallback(() => {
    setSelectedBusiness(initialState.selectedBusiness);
    setDialogMode(initialState.dialogMode);

    onClose();
  }, [onClose]);

  const onBusinessFormClose = useCallback(
    async (ownedBusinessValues: BusinessWithOwnership | null) => {
      if (ownedBusinessValues) {
        onSubmit(ownedBusinessValues);
      }

      resetAndClose();
    },
    [onSubmit, resetAndClose],
  );

  const onBack = useCallback(() => {
    setSelectedBusiness(null);
  }, []);

  return (
    <Modal open={modalOpen} onClose={resetAndClose} title={t(`ownedBusinesses.dialog.title.${dialogMode}`)} fullWidth alignTitle={'center'}>
      {dialogMode === 'add' ? (
        <AddOrCreateRelatedEntity
          allowedEntityTypes={['business']}
          dialogMode={'add'}
          application={application}
          existingEntityIds={excludedEntityIds}
          initialValues={selectedBusiness ?? undefined}
          entityType={'business'}
          currentEntityProperties={[]}
          submitHandler={onEntitySelected}
          isLoading={loading}
          onBack={onBack}
          onClose={onClose}
          searchButtonVisible={false}
          showEntityTypeToggle={false}
          businessesSelectionOnly
          editSelectedEntity
          // eslint-disable-next-line react/jsx-no-bind, react/no-unstable-nested-components
          customForm={({ onBack: onBackInner }) => (
            <ModalContent>
              <BusinessForm
                showBackButton
                business={selectedBusiness}
                ownershipPercentage={ownershipPercentage}
                remainingOwnershipPercentage={remainingOwnershipPercentage}
                dialogMode={dialogMode}
                isLoading={loading}
                handleClose={onBusinessFormClose}
                onBack={onBackInner}
              />
            </ModalContent>
          )}
        />
      ) : (
        <ModalContent>
          <BusinessForm
            business={selectedBusiness}
            ownershipPercentage={ownershipPercentage}
            remainingOwnershipPercentage={remainingOwnershipPercentage}
            dialogMode={dialogMode}
            isLoading={loading}
            handleClose={onBusinessFormClose}
          />
        </ModalContent>
      )}
    </Modal>
  );
};
