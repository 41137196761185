/* eslint-disable react/jsx-no-literals */
import React, { useEffect, forwardRef, useRef, useState, useMemo, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { autoUpdate, useFloating, offset, shift, FloatingPortal } from '@floating-ui/react';
import type { PropsWithChildren, MutableRefObject } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { AnimatePresence } from 'framer-motion';
import type { MarginProps, PaddingProps } from 'styled-system';

import { Flex } from '../Flex/Flex.component';
import { Text } from '../Text/Text.component';
import { Backdrop, StyledModal, CloseButton } from './Modal.styles';

export type ModalSize = 'l' | 'm' | 's';

export interface ModalProps extends PaddingProps, MarginProps {
  size?: ModalSize;
  onClose?: () => void;
  open: boolean;
  autoFocus?: boolean;
  disableBackdrop?: boolean;
  title?: string;
  alignTitle?: 'center' | 'left';
  subtitle?: string;
  hideCloseButton?: boolean;
  top?: string;
  fullscreen?: boolean;
  fullWidth?: boolean;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'data-testid'?: string;
}

export const Modal = forwardRef<HTMLDivElement, PropsWithChildren<ModalProps>>(
  (
    {
      size = 'l',
      onClose,
      open,
      autoFocus = true,
      disableBackdrop = false,
      children,
      title,
      alignTitle = 'left',
      subtitle,
      hideCloseButton = false,
      fullscreen = false,
      fullWidth = false,
      'data-testid': dataTestId,
      ...props
    },
    ref,
  ) => {
    const internalRef = useRef<HTMLDivElement>(null);
    const resolvedRef = (ref as MutableRefObject<HTMLDivElement>) || internalRef;
    const [shouldRender, setShouldRender] = useState(open);

    const { refs } = useFloating({
      strategy: 'fixed',
      middleware: [offset(0), shift()],
      whileElementsMounted: autoUpdate,
    });

    useEffect(() => {
      if (open !== shouldRender) {
        if (open) {
          refs.setReference(resolvedRef.current);
          setShouldRender(true);
          if (autoFocus) {
            resolvedRef.current?.focus();
          }
        } else {
          refs.setReference(null);
          setTimeout(() => {
            setShouldRender(false);
          }, 300);
        }
      }
    }, [autoFocus, open, refs, resolvedRef, shouldRender]);

    const onModalExitAnimation = useCallback(() => {
      setShouldRender(false);
    }, []);

    const onCloseInternal = useCallback(() => {
      onClose?.();
    }, [onClose]);

    useHotkeys(
      'esc',
      () => {
        onCloseInternal();
      },
      { enableOnFormTags: true, enabled: open },
      [onCloseInternal, open],
    );

    const key = useMemo(() => `modal-${title ?? uuidv4()}`, [title]);

    if (!shouldRender) {
      return null;
    }

    return (
      <FloatingPortal>
        <AnimatePresence initial onExitComplete={onModalExitAnimation}>
          <>
            <Backdrop
              initial={{ opacity: 0 }}
              animate={{ opacity: open ? 0.8 : 0 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.15 }}
              onClick={onCloseInternal}
              disableBackdrop={disableBackdrop}
            />
            <Flex ref={refs.setReference} key={key}>
              <StyledModal
                data-testid={dataTestId}
                open={open}
                fullWidth={fullWidth}
                role={'dialog'}
                ref={refs.setFloating}
                size={size}
                fullscreen={fullscreen}
                tabIndex={autoFocus ? -1 : undefined}
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{
                  opacity: open ? 1 : 0,
                  scale: open ? 1 : 0.9,
                }}
                exit={{ opacity: 0, scale: 0.9 }}
                transition={{ duration: 0.3, ease: 'easeInOut' }}
                top={props.top}
                {...props}
              >
                {title ? (
                  <Flex flexDirection={'column'} alignItems={alignTitle} pb={2} maxWidth={'95%'}>
                    <Text variant={'h6'}>{title}</Text>
                    {subtitle ? (
                      <Text variant={'body1'} textAlign={alignTitle}>
                        {subtitle}
                      </Text>
                    ) : null}
                  </Flex>
                ) : null}
                {!hideCloseButton && (
                  <CloseButton data-testid={'modal-close'} onClick={onCloseInternal}>
                    &times;
                  </CloseButton>
                )}
                {children}
              </StyledModal>
            </Flex>
          </>
        </AnimatePresence>
      </FloatingPortal>
    );
  },
);

Modal.displayName = 'Modal';
