import { ToggleButton, styled, Typography, ToggleButtonGroup } from '@mui/material';
import { useIsMobile } from '@lama/app-components';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface RequirementsToggleProps {
  showFulfilled: boolean;
  toggleTaskView: (event: React.MouseEvent<HTMLElement>, value: string | null) => void;
}

const TaskToggleButton = styled(ToggleButton)({
  width: '80px',
  height: '24px',
  backgroundColor: '#EEEEEE',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '&.hover': {
    backgroundColor: '#EEEEEE',
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '&.Mui-selected, &.Mui-selected:hover': {
    backgroundColor: 'white',
    zIndex: 2,
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '&.MuiToggleButtonGroup-grouped': {
    borderRadius: '20px !important',
    border: '1px solid rgb(0, 0, 0, 0.12) !important',
  },
});

export const RequirementsToggle: React.FC<RequirementsToggleProps> = ({ showFulfilled, toggleTaskView }) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  return (
    <ToggleButtonGroup value={showFulfilled ? 'true' : 'false'} exclusive onChange={toggleTaskView}>
      <TaskToggleButton value={'true'} sx={{ marginRight: '-26px', width: isMobile ? '100%' : '100px', height: '32px' }}>
        <Typography variant={'subtitle2'}>{t('requirements.tasks.all')}</Typography>
      </TaskToggleButton>
      <TaskToggleButton value={'false'} sx={{ width: isMobile ? '100%' : '110px', height: '32px' }}>
        <Typography variant={'subtitle2'}>{t('requirements.tasks.open')}</Typography>
      </TaskToggleButton>
    </ToggleButtonGroup>
  );
};
