/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import type { FC } from 'react';
import { Flex, Text, amberPalette, bluePalette } from '@lama/design-system';
import { ErrorOutlineRounded } from '@mui/icons-material';
import { useIsMobile } from '@lama/app-components';
import { AlertActions } from './alertActions/AlertActions';

interface ApplicationAlertProps {
  message: string;
  actions: string[];
  applicationId: string;
  level?: string;
}

export const ApplicationAlertView: FC<ApplicationAlertProps> = ({ message, actions, applicationId, level }) => {
  const isMobile = useIsMobile();

  return (
    <Flex
      alignItems={'center'}
      justifyContent={'space-between'}
      gap={8}
      width={'100%'}
      p={4}
      borderRadius={'4px'}
      minHeight={'64px'}
      backgroundColor={level === 'info' ? bluePalette[50] : amberPalette[50]}
      flexDirection={isMobile ? 'column' : 'row'}
    >
      <Flex alignItems={'center'} gap={4}>
        <ErrorOutlineRounded color={level === 'info' ? 'info' : 'warning'} />
        <Text variant={'body1'}>{message}</Text>
      </Flex>
      <AlertActions applicationId={applicationId} actions={actions} />
    </Flex>
  );
};
