import type { ApplicationApiModel, ApplicationCreateApiModel, PersonCreateApiModel } from '@lama/clients';
import { v4 as uuidv4 } from 'uuid';
import type { ProductPublicData } from '@lama/contracts';
import { useCallback, useMemo } from 'react';
import { useQueryParams } from '../../../hooks/useQueryParams.js';
import { useCreateBusinessMutation } from '../../../hooks/react-query/useCreateBusinessMutation';
import { useCreateApplicationMutation } from '../../../hooks/react-query/useCreateApplicationMutation';
import { useAddApplicationRelation } from '../../../hooks/react-query/useAddApplicationRelation.js';
import { useCreatePerson } from '../../../hooks/react-query/useCreatePerson.js';
import { useAddPersonToUserMutation } from '../../../hooks/react-query/useAddPersonIdToUserMutation.js';
import type { UserCreationDetails } from '../../ScreenProps.js';

const skipToRegistrationQueryParam = 'skipToRegistration';

export const useCreateApplicationAndRelatedEntities = () => {
  const queryParams = useQueryParams([
    'ref',
    'requestedAmount',
    'salesforceUserId',
    skipToRegistrationQueryParam,
    'utm_source',
    'trackingId',
    'referralId',
  ]);

  const { mutateAsync: createApplication, isPending: creatingApplication } = useCreateApplicationMutation();
  const { mutateAsync: createBusiness, isPending: creatingBusiness } = useCreateBusinessMutation();
  const { mutateAsync: addApplicationRelation, isPending: addingRelation } = useAddApplicationRelation();
  const { mutateAsync: createPerson, isPending: creatingPerson } = useCreatePerson();
  const { mutateAsync: addPersonToUser, isPending: addingPersonToUser } = useAddPersonToUserMutation();

  const createApplicationAndRelatedEntities = useCallback(
    async ({ partnerId, product, userDetails }: { partnerId: string; product: ProductPublicData; userDetails: UserCreationDetails }) => {
      const metadata: ApplicationApiModel['metadata'] = {
        salesforceUserId: queryParams.salesforceUserId ?? undefined,
        utmSource: queryParams.utm_source ?? undefined,
        trackingId: queryParams.trackingId ?? undefined,
        referralId: queryParams.referralId ?? undefined,
      };

      const applicationId = uuidv4();
      const personId = uuidv4();

      const applicationToCreate: ApplicationCreateApiModel = {
        id: applicationId,
        relatedBusinesses: [],
        relatedPeople: [],
        initiator: personId,
        originatingPartner: partnerId,
        originalProductId: product.id,
        ...(queryParams.ref ? { referredBy: queryParams.ref } : {}),
        ...(queryParams.requestedAmount ? { requestedAmount: Number(queryParams.requestedAmount) } : {}),
        metadata,
      };

      await createApplication({ createApplicationPayload: applicationToCreate });

      if (product.createBorrowingBusiness) {
        const businessId = uuidv4();
        await createBusiness({ business: { id: businessId, people: [], applicationId, partnerId }, applicationId });

        await addApplicationRelation({
          applicationId,
          entityId: businessId,
          entityType: 'business',
          relation: 'borrower',
        });
      }

      const personToCreate: PersonCreateApiModel = {
        id: personId,
        applicationId,
        partnerId,
        firstName: userDetails.firstName,
        lastName: userDetails.lastName,
        email: userDetails.email,
        phoneNumber: userDetails.phoneNumber,
      };

      await createPerson({ person: personToCreate });
      await addPersonToUser({ personId, userId: userDetails.id });

      await addApplicationRelation({
        applicationId,
        entityId: personId,
        entityType: 'person',
        relation: product.createInitiatorAsBorrower ? 'borrower' : 'guest',
      });

      return applicationToCreate.id;
    },
    [
      queryParams.salesforceUserId,
      queryParams.utm_source,
      queryParams.trackingId,
      queryParams.referralId,
      queryParams.ref,
      queryParams.requestedAmount,
      createApplication,
      createPerson,
      addPersonToUser,
      addApplicationRelation,
      createBusiness,
    ],
  );

  const creatingApplicationAndRelatedEntities = useMemo(
    () => creatingApplication || creatingBusiness || addingRelation || creatingPerson || addingPersonToUser,
    [addingPersonToUser, addingRelation, creatingApplication, creatingBusiness, creatingPerson],
  );

  return { createApplicationAndRelatedEntities, creatingApplicationAndRelatedEntities };
};
