/* eslint-disable @typescript-eslint/naming-convention */
import type { ThemeOptions } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import { amber, grey, red } from '@mui/material/colors';
import type { PartnerBranding } from '@lama/partner-service-client';

import { deepmerge } from '@mui/utils';

const defaultThemeOptions: ThemeOptions = {
  zIndex: {
    modal: 1400,
    tooltip: 1400,
  },
  palette: {
    primary: {
      main: '#714EF5',
      dark: '#3221C1',
      light: '#F3F0FF',
    },
    secondary: {
      main: '#79F5BA',
      dark: '#40C18A',
      light: '#DAFFEE',
    },
    success: {
      main: '#44CFA6',
      light: '#E2FBF3',
    },
    neutral: createTheme().palette.augmentColor({
      color: { main: '#EEEEEE', dark: '#E0E0E0' },
      name: 'neutral',
    }),
    warning: {
      main: amber[700],
      contrastText: '#fff',
    },
    error: {
      main: red[400],
      light: '#EF5350',
      contrastText: '#fff',
    },
  },
  colors: {
    greyHover: '#f5f6f6',
    greySelected: '#EEEEEE',
    dark: '#0C2007',
    helperText: grey[500],
    border: grey[300],
  },
  text: {
    primary: '#0C2007',
    secondary: '#00000099',
    disabled: '#C5C5C5',
  },
  typography: {
    body1: {
      color: grey[700],
      fontWeight: 400,
    },
    body2: {
      fontSize: '14px',
      color: grey[700],
    },
    h4: {
      fontFamily: "'Ubuntu', sans-serif",
      fontWeight: 600,
      fontSize: '32px',
    },
    h5: {
      fontFamily: "'Ubuntu', sans-serif",
      fontSize: '24px',
      fontWeight: 600,
      lineHeight: '32px',
    },
    h6: {
      fontFamily: "'Ubuntu', sans-serif",
      fontWeight: 400,
      fontSize: '20px ',
      lineHeight: '28px',
    },
    subtitle2: {
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '22px',
    },
    subtitle3: {
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '18px',
    },
    signature: {
      fontFamily: "'Angello', sans-serif",
      fontWeight: '400',
      fontSize: '40px',
      lineHeight: '64px',
    },
    badge: {
      fontFamily: "'Poppins', sans-serif",
      fontWeight: '500',
      fontSize: '12px',
      lineHeight: '12px',
    },
    actionLink: {
      fontFamily: "'Poppins', sans-serif",
      fontWeight: '600',
      fontSize: '14px',
      lineHeight: '24px',
    },
    banner: {
      fontFamily: "'Poppins', sans-serif",
      fontWeight: '600',
      fontSize: '10px',
      lineHeight: '14px',
    },
    helperText: {
      fontFamily: "'Poppins', sans-serif",
      fontWeight: '400',
      fontSize: '12px',
      lineHeight: '20px',
    },
    allVariants: {
      fontFamily: "'Poppins', sans-serif",
    },
  },
  components: {
    MuiSlider: {
      styleOverrides: {
        markLabel: {
          color: grey[700],
          transform: 'translateX(-100%)',
          '&[data-index="0"]': {
            transform: 'none',
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          borderRadius: '8px',
          textTransform: 'none',
        },
      },
    },
    MuiToggleButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          borderRadius: '10px',
          textTransform: 'none',
        },
      },
    },
    MuiStack: {
      defaultProps: {
        borderRadius: '10px',
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: '#EF5350',
          '&$error': {
            color: '#EF5350',
          },
        },
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 768,
      lg: 1200,
      xl: 1536,
    },
  },
};

export const defaulTheme = createTheme(defaultThemeOptions);

export const createThemeForPartner = (brand: PartnerBranding, overrideParamName?: string | null) => {
  const palette = {
    primary: brand.theme.primary,
    secondary: brand.theme.secondary,
  };
  if (overrideParamName) {
    const brandingForReferrer = brand.overridesByParamName?.[overrideParamName]?.brand;
    if (brandingForReferrer) {
      palette.primary = deepmerge(palette.primary, brandingForReferrer.theme.primary);
      palette.secondary = deepmerge(palette.secondary, brandingForReferrer.theme.secondary);
    }
  }
  return createTheme(
    deepmerge(defaultThemeOptions, {
      palette,
    }),
  );
};
