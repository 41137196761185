import type { FC } from 'react';
import * as yup from 'yup';
import React, { useMemo, useCallback } from 'react';
import { Button, Flex } from '@lama/design-system';

import { Formik } from 'formik';
import { GenericPropertiesGrid, getValidationObjectShape } from '../GenericProperties';
import { FormikCheckbox } from '../FormikFields';
import { ModifyItemButton } from '../ModifyItemButton';
import type { GenericEditRelationComponentProps } from './types';

interface EntityFormProps extends GenericEditRelationComponentProps {
  onBack: () => void;
}

export const EntityForm: FC<EntityFormProps> = ({
  submitHandler,
  initialValues = {},
  entity = {},
  isLoading,
  dialogMode,
  entityType,
  currentEntityProperties,
  application,
  opportunity,
  onBack,
}) => {
  const actionDisplayName = useMemo(() => (dialogMode === 'add' ? 'Add' : 'Update'), [dialogMode]);

  const validationSchema = useMemo(
    () =>
      yup.object({
        ...getValidationObjectShape({ properties: currentEntityProperties, blockOnMissingRequiredFields: true }),
        inviteToApplication: yup.boolean(),
      }),
    [currentEntityProperties],
  );

  const isCreatingPerson = useMemo(() => dialogMode === 'add', [dialogMode]);

  const onSubmitInternal = useCallback(
    async (values: Record<string, any>) => {
      await submitHandler(values, entityType);
    },
    [submitHandler, entityType],
  );

  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={onSubmitInternal}>
      {({ handleSubmit, isValid }) => (
        <Flex height={'100%'} flexDirection={'column'} gap={12} width={'100%'} pt={2}>
          <Flex gap={4} flexWrap={'wrap'}>
            <GenericPropertiesGrid
              properties={currentEntityProperties}
              entityType={entityType}
              entity={entity}
              application={application}
              opportunity={opportunity}
            />
            {isCreatingPerson && entityType === 'person' ? (
              <FormikCheckbox
                name={'inviteToApplication'}
                label={'Send invite to collaborate on this application'}
                helperText={'You can always invite them later'}
              />
            ) : null}
          </Flex>
          <Flex marginTop={'auto'} fullWidth justifyContent={'space-between'}>
            <Button onClick={onBack} variant={'tertiary'}>
              {'Back'}
            </Button>
            <ModifyItemButton text={actionDisplayName} onClick={handleSubmit} showIcon={false} disabled={!isValid} loading={isLoading} />
          </Flex>
        </Flex>
      )}
    </Formik>
  );
};
