import type { ThemeProps } from 'styled-components';
import styled, { css } from 'styled-components';
import { height, space, width } from 'styled-system';
import { toNumber } from 'lodash-es';
import type { Theme } from '../../theme';
import type { PixelSuffix } from '../../types';
import type { TextVariant } from '../Text';
import { isNumber } from '../Flex/Flex.styles';
import type { ButtonProps } from './Button.component';

export type ButtonVariant = 'primary' | 'secondary' | 'tertiary';
export type ButtonColors = 'danger' | 'neutral' | 'primary';

export type ButtonSize = 'l' | 'm' | 's';

export const textVariants: Record<ButtonSize, TextVariant> = {
  l: 'body1',
  m: 'body2',
  s: 'body3',
};

export const spinnerSizes: Record<ButtonSize, PixelSuffix> = {
  l: '24px',
  m: '16px',
  s: '12px',
};

export const iconSize: Record<ButtonSize, number> = {
  l: 24,
  m: 20,
  s: 16,
};

const padding: Record<ButtonSize, string> = {
  l: '8px 16px',
  m: '6px 16px',
  s: '4px 8px',
};

const borderRadius: Record<ButtonSize, string> = {
  l: '4px',
  m: '4px',
  s: '4px',
};

type StylingArgs = ButtonProps & ThemeProps<Record<string, any>>;

const hoverStyle = ({ theme, variant, selected, color }: StylingArgs) =>
  variant &&
  color &&
  css`
    :hover {
      cursor: pointer;
      color: ${variant && color ? theme.button.variants[variant]?.colors[color]?.hover?.textColor : ''};
      background-color: ${variant && selected && color
        ? theme.button.variants[variant]?.colors[color]?.selected?.backgroundColor
        : theme.button.variants[variant]?.colors[color]?.hover?.backgroundColor};
    }
  `;

const activeStyle = ({ theme, variant, color }: StylingArgs) =>
  variant &&
  color &&
  css`
    :active {
      background-color: ${variant && theme.button.variants[variant]?.colors[color]?.active.backgroundColor};
    }
  `;

const disabledStyle = ({ theme, disabled, variant }: StylingArgs) =>
  disabled &&
  variant &&
  css`
    background-color: ${variant && theme.button.variants[variant]?.disabled?.backgroundColor};
    color: ${variant && theme.button.variants[variant]?.disabled.textColor};
    cursor: not-allowed;
    box-shadow: 0 0 0 1px ${(variant && theme.button.variants[variant]?.disabled?.borderColor) ?? 'transparent'};
    :hover {
      background-color: ${variant && theme.button.variants[variant]?.disabled?.backgroundColor};
      color: ${variant && theme.button.variants[variant]?.disabled.textColor};
      cursor: not-allowed;
    }
  `;

const selectedStyle = ({ theme, variant, disabled, selected, color }: StylingArgs) => {
  const selectedBorderColor = variant && color && theme.button.variants[variant]?.colors[color]?.active.borderColor;
  return (
    selected &&
    !disabled &&
    variant &&
    color &&
    css`
      background-color: ${variant && theme.button.variants[variant]?.colors[color]?.active.backgroundColor};
      border: ${selectedBorderColor ? `1px solid ${selectedBorderColor}` : ''};
    `
  );
};

const gapStyle = ({ theme, gap }: ButtonProps & { theme: Theme }) => {
  const themeSpace = theme.space;

  if (gap !== undefined) {
    return css`
      gap: ${isNumber(gap) ? `${themeSpace[toNumber(gap) ?? 6]}px` : (gap as string)};
    `;
  }

  return;
};

export const StyledButton = styled.button<ButtonProps>`
  margin: 0;

  box-sizing: border-box;
  user-select: none;
  border-radius: ${({ size }) => size && borderRadius[size]};
  padding: ${({ size }) => size && padding[size]};
  color: ${({ theme, variant, color }) => variant && color && theme.button.variants[variant]?.colors[color]?.textColor};
  background-color: ${({ theme, variant, color }) => variant && color && theme.button.variants[variant]?.colors[color]?.backgroundColor};
  box-shadow: 0 0 0 1px
    ${({ theme, variant, color }) => (variant && color && theme.button.variants[variant]?.colors[color]?.borderColor) ?? 'transparent'};
  border: none;
  transition: all 0.1s ease-in-out;
  font-weight: 500;
  width: auto;

  .buttonIcon {
    color: ${({ theme, variant, color }) => variant && color && theme.button.variants[variant]?.colors[color]?.iconColor};
  }

  ${height}
  ${space}
  ${width}
  ${hoverStyle}
  ${activeStyle}
  ${disabledStyle}
  ${selectedStyle}
  ${gapStyle};
`;
