import { Flex, Text } from '@lama/design-system';
import type { FC } from 'react';
import React from 'react';
import { useIsMobile } from '@lama/app-components';
import { useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { SimpleHeader } from '../shared/SimpleHeader';
import { InactiveApplicationImage } from './assets/InactiveApplicationImage';

export const InactiveApplicationScreen: FC<{ title: string }> = ({ title }) => {
  const theme = useTheme();
  const isMobile = useIsMobile();
  const { t } = useTranslation();

  return (
    <Flex width={'100%'} height={'100%'} flexDirection={'column'}>
      <SimpleHeader showAccountSettings />
      <Flex
        flexDirection={'column'}
        gap={isMobile ? 15 : 20}
        alignItems={'center'}
        flex={isMobile ? 1 : 0.7}
        py={10}
        px={isMobile ? 0 : 4}
        justifyContent={'center'}
      >
        <Text variant={'h4'} textAlign={'center'}>
          {title}
        </Text>
        <InactiveApplicationImage />
        <Link to={'/applications'} style={{ textDecoration: 'none' }}>
          <Text variant={'body1'} color={theme.palette.primary.main}>
            {t('inactiveApplication.cta')}
          </Text>
        </Link>
      </Flex>
    </Flex>
  );
};
