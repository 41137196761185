import { Divider } from '@mui/material';
import React, { useContext, useMemo } from 'react';
import { Flex, Text } from '@lama/design-system';
import { formatValue, loanDisplayNameSelector } from '@lama/data-formatters';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ApplicationContext } from '../../shared/contexts/ApplicationContext';
import { ApplicationStatusChip } from './ApplicationStatusChip';

interface ApplicationSummaryBarProps {
  showBusinessName?: boolean;
}

const BarDivider = () => <Divider orientation={'vertical'} flexItem />;

export const ApplicationSummaryBar: React.FC<ApplicationSummaryBarProps> = ({ showBusinessName = true }) => {
  const { application, product } = useContext(ApplicationContext);
  const { fibtDemo } = useFlags();

  const SummaryComponents = useMemo(() => {
    const components = [];

    const loanDisplayName = loanDisplayNameSelector(application);

    if (showBusinessName && loanDisplayName) {
      components.push(
        <Text key={'businessName'} variant={'body1'}>
          {loanDisplayName}
        </Text>,
      );
    }

    if (application.requestedAmount && !fibtDemo) {
      components.push(
        <Text key={'requestedAmount'} variant={'body1'}>
          {formatValue(application.requestedAmount, 'currencyCompact')}
        </Text>,
      );
    }

    if (!fibtDemo) {
      components.push(
        <Text key={'productName'} variant={'body1'}>
          {product?.prettyName}
        </Text>,
        <ApplicationStatusChip key={'statusChip'} status={application.status} size={'small'} />,
      );
    }

    return components.flatMap((component, index) =>
      index < components.length - 1 ? [component, <BarDivider key={`divider-${index}`} />] : [component],
    );
  }, [application, fibtDemo, product?.prettyName, showBusinessName]);

  return <Flex gap={2}>{SummaryComponents}</Flex>;
};
