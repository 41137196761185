import React from 'react';
import { useAsyncFn } from 'react-use';
import { useTranslation } from 'react-i18next';
import { Check, InfoOutlined } from '@mui/icons-material';
import { Button, Flex, Text, greenPalette, greyPalette } from '@lama/design-system';
import { Avatar } from '../Avatar';
import { Tooltip } from '../Tooltip';
import { ItemActions } from '../ItemActions';
import { useIsMobile } from '../../hooks/useIsMobile';

interface RelationsListItemProps {
  title: string;
  deleteEnabled: boolean;
  onDelete?: (id: string) => void;
  onEdit: (id: string) => void;
  id: string;
  subtitle?: string;
  inviteAllowed?: boolean;
  hasBeenInvited?: boolean;
  onInvite?: (id: string) => Promise<void>;
  relation?: string;
}

export const RelationsListItem: React.FC<RelationsListItemProps> = ({
  title,
  deleteEnabled,
  onDelete,
  onEdit,
  id,
  subtitle = '',
  inviteAllowed,
  hasBeenInvited,
  onInvite,
  relation,
}) => {
  const isMobile = useIsMobile();
  const { t } = useTranslation();

  const [{ loading }, onInviteClick] = useAsyncFn(async () => {
    if (!onInvite) {
      return;
    }

    await onInvite(id);
  }, [onInvite, id]);

  return (
    <Flex
      gap={4}
      height={'100px'}
      px={isMobile ? 5 : 8}
      py={6}
      border={`1px solid ${greyPalette[300]}`}
      borderColor={greyPalette[300]}
      borderRadius={'4px'}
      width={'100%'}
      justifyContent={'space-between'}
    >
      <Flex alignItems={'center'} gap={4} minWidth={0}>
        <Avatar firstName={title} lastName={''} />
        <Flex flexDirection={'column'} minWidth={0}>
          <Tooltip title={title} placement={'top'}>
            <Text variant={'h6'}>{title}</Text>
          </Tooltip>
          <Text variant={'body2'}>{subtitle}</Text>
        </Flex>
      </Flex>
      <Flex flexDirection={'row'} alignItems={'center'} gap={2}>
        {inviteAllowed && !isMobile ? (
          <Tooltip title={t('principals.invite.tooltip')} placement={'top'}>
            <Button
              endIcon={hasBeenInvited ? <Check /> : <InfoOutlined />}
              iconColor={greenPalette[500]}
              variant={'secondary'}
              color={'neutral'}
              onClick={onInviteClick}
              disabled={hasBeenInvited}
              loading={loading}
              size={'s'}
            >
              {hasBeenInvited ? t('principals.invite.invited') : t('principals.invite.invite')}
            </Button>
          </Tooltip>
        ) : null}
        <ItemActions
          item={{ id }}
          onDeleteClick={deleteEnabled ? onDelete : undefined}
          onEditClick={onEdit}
          inviteAllowed={inviteAllowed}
          onInviteClick={onInviteClick}
          hasBeenInvited={hasBeenInvited}
          relation={relation}
        />
      </Flex>
    </Flex>
  );
};
