import type { ReactNode } from 'react';
import React from 'react';
import type { Entity } from '@lama/common-types';
import type { EvaluatedApplicationRequirement, EvaluatedOpportunityRequirement, Person, Relation } from '@lama/contracts';
import { keyBy, sortBy } from 'lodash-es';
import {
  businessName as businessNameSelector,
  businessRelationsSelector,
  personFullName,
  personRelationsSelector,
  businessPrimaryRelation,
  relatedPeopleByRelationsSelector,
  relatedBusinessesByRelationsSelector,
  applicationGuarantorsSelector,
} from '@lama/data-formatters';
import type { ApplicationApiModel, ApplicationRelatedBusinessApiModel, ApplicationRelatedPersonApiModel } from '@lama/clients';
import type { RelatedPersonApiModel } from '@lama/business-service-client';
import { capitalCase } from 'change-case-all';
import { BusinessIcon } from '../icons/BusinessIcon';
import { PersonIcon } from '../icons/PersonIcon';

type RelationWithCoBorrower = Relation | 'co-borrower';

export const entityRelationsPriority: RelationWithCoBorrower[] = [
  'borrower',
  'co-borrower',
  'guarantor',
  'explicitGuarantor',
  'owner',
  'seller',
  'property',
  'subsidiary',
  'sisterCompany',
  'guest',
];

type EvaluatedRequirement = EvaluatedApplicationRequirement | EvaluatedOpportunityRequirement;

// converts borrower to coborrower if the given entity is not the main borrower, as coborrower is not
// represented as an explicit relation in the API
// also searches for implicit guarantors and owners as they are not represented as explicit relations in the API yet
export const getEntityPrimaryRelation = (entityId: string, application: ApplicationApiModel) => {
  const relatedEntity =
    application.relatedBusinesses.find(({ business }) => business.id === entityId) ??
    application.relatedPeople.find(({ person }) => person.id === entityId);

  let relatedEntityRelations: RelationWithCoBorrower[] = [];

  if (relatedEntity) {
    relatedEntityRelations = relatedEntity.relations;

    const [mainBorrower] = sortBy(
      [
        ...relatedPeopleByRelationsSelector(application, ['borrower']).map(({ person }) => person),
        ...relatedBusinessesByRelationsSelector(application, ['borrower']).map(({ business }) => business),
      ],
      ({ createdAt }) => createdAt,
    );

    if (mainBorrower?.id !== entityId) {
      relatedEntityRelations = relatedEntityRelations.map((relation) => (relation === 'borrower' ? 'co-borrower' : relation));
    }
  } else {
    const applicationGuarantors = applicationGuarantorsSelector(application);
    relatedEntityRelations = applicationGuarantors.some(({ id }) => id === entityId) ? ['guarantor'] : ['owner'];
  }

  return entityRelationsPriority.find((relation) => relatedEntityRelations.includes(relation));
};

const categoriesEntityRelationSortOrder =
  (application: ApplicationApiModel) =>
  <T extends EvaluatedRequirement>(c: RequirementsByCategory<T>) => {
    if (c.categoryShortName === 'Submit') {
      return 1;
    }

    if (!c.requirements.length) {
      return 1;
    }

    const primaryRelation = getEntityPrimaryRelation(c.requirements[0]!.entityId, application);

    if (!primaryRelation) {
      return 1;
    }

    return entityRelationsPriority.indexOf(primaryRelation);
  };

// Submit category should always be last
const submitCategorySortOrder = <T extends EvaluatedRequirement>(c: RequirementsByCategory<T>) =>
  c.categoryShortName === 'Submit' ? 1 : 0;

// opportunity and application requirement categories should always be first
const entityTypePriority: Entity[] = ['opportunity', 'application'];
const entityTypeCategorySortOrder = <T extends EvaluatedRequirement>(c: RequirementsByCategory<T>) =>
  entityTypePriority.includes(c.entityType) ? entityTypePriority.indexOf(c.entityType) : entityTypePriority.length + 1;

const categoryNameSortOrder = <T extends EvaluatedRequirement>(c: RequirementsByCategory<T>) => c.categoryShortName;

const ownerCategoryText = ({ firstName, lastName }: Person) => `${firstName} ${lastName[0]}.`;

export interface RequirementsByCategory<T extends EvaluatedRequirement> {
  categoryId: string;
  entityType: Entity;
  entityRelation?: string;
  categoryShortName: string;
  categoryLongName: string;
  categoryIcon?: ReactNode;
  requirements: T[];
  entityId: string;
}

const getCategoryId = (requirement: EvaluatedRequirement) =>
  requirement.entityType === 'application' ? requirement.category : requirement.entityId;

const getCategoryShortName = (
  requirement: EvaluatedRequirement,
  relatedPeopleById: Record<string, ApplicationRelatedPersonApiModel>,
  businessPeopleById: Record<string, RelatedPersonApiModel>,
  relatedBusinessesById: Record<string, ApplicationRelatedBusinessApiModel>,
) => {
  if (requirement.entityType === 'person' && (!!relatedPeopleById[requirement.entityId] || !!businessPeopleById[requirement.entityId])) {
    const person = relatedPeopleById[requirement.entityId]?.person ?? businessPeopleById[requirement.entityId];
    return ownerCategoryText(person!) ?? capitalCase(requirement.entityType);
  }

  if (requirement.entityType === 'business' && relatedBusinessesById[requirement.entityId]) {
    const business = relatedBusinessesById[requirement.entityId]?.business;
    const businessName = business && businessNameSelector(business);

    const businessRelation = businessPrimaryRelation(relatedBusinessesById[requirement.entityId]?.relations ?? []);
    const businessRelationText = businessRelation ? capitalCase(businessRelation) : '';

    return businessName ?? businessRelationText;
  }

  return capitalCase(requirement.category);
};

const getCategoryLongName = (
  requirement: EvaluatedRequirement,
  relatedPeopleById: Record<string, ApplicationRelatedPersonApiModel>,
  businessPeopleById: Record<string, RelatedPersonApiModel>,
  relatedBusinessesById: Record<string, ApplicationRelatedBusinessApiModel>,
) => {
  if (requirement.entityType === 'person' && (!!relatedPeopleById[requirement.entityId] || !!businessPeopleById[requirement.entityId])) {
    const person = relatedPeopleById[requirement.entityId]?.person ?? businessPeopleById[requirement.entityId];
    return personFullName(person!);
  }

  return getCategoryShortName(requirement, relatedPeopleById, businessPeopleById, relatedBusinessesById);
};

const getEntityRelation = (requirement: EvaluatedRequirement, application: ApplicationApiModel) => {
  if (requirement.entityType === 'person') {
    return personRelationsSelector(requirement.entityId, application);
  }

  if (requirement.entityType === 'business') {
    return businessRelationsSelector(requirement.entityId, application);
  }

  return '';
};

const getCategoryIcon = (entityType: Entity) => {
  if (entityType === 'business') {
    return <BusinessIcon />;
  }

  if (entityType === 'person') {
    return <PersonIcon />;
  }

  return null;
};

export const getRequirementsByCategory = <T extends EvaluatedRequirement>(
  requirements: T[],
  application: ApplicationApiModel,
): RequirementsByCategory<T>[] => {
  const businessPeopleById = keyBy(
    application.relatedBusinesses.flatMap(({ business: { people } }) => people),
    ({ id }) => id,
  );
  const relatedPeopleById = keyBy(application.relatedPeople, ({ person: { id } }) => id);
  const relatedBusinessesById = keyBy(application.relatedBusinesses, ({ business: { id } }) => id);

  return sortBy(
    requirements
      .reduce<RequirementsByCategory<T>[]>((acc, req) => {
        const categoryId = getCategoryId(req);

        const existingCategory = acc.find((item) => item.categoryId === categoryId);

        if (existingCategory) {
          existingCategory.requirements.push(req);
        } else {
          const categoryShortName = getCategoryShortName(req, relatedPeopleById, businessPeopleById, relatedBusinessesById);
          const categoryLongName = getCategoryLongName(req, relatedPeopleById, businessPeopleById, relatedBusinessesById);
          const entityRelation = getEntityRelation(req, application);
          const categoryIcon = getCategoryIcon(req.entityType);

          acc.push({
            categoryId,
            entityId: req.entityId,
            entityType: req.entityType,
            entityRelation,
            categoryIcon,
            categoryShortName,
            categoryLongName,
            requirements: [req],
          });
        }

        return acc;
      }, [])
      .map((item) => ({
        ...item,
        requirements: sortBy(item.requirements, (requirement) => requirement.viewIndex ?? 1000),
      })),
    [submitCategorySortOrder, entityTypeCategorySortOrder, categoriesEntityRelationSortOrder(application), categoryNameSortOrder],
  );
};
