import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import { Navigate, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import type { ProductPublicData } from '@lama/contracts';
import type { ApplicationApiModel } from '@lama/clients';
import type { PartnerPublicDataApiModel } from '@lama/partner-service-client';
import { isNil } from 'lodash-es';
import { Flex } from '@lama/design-system';
import { LoadingPage } from '@lama/app-components';
import { MainHeader } from '../../applicationFormComponents/MainHeader';
import { Footer } from '../../applicationFormComponents/Footer';
import { currentOnboardingApplicationIdState, partnerState } from '../../state/appState';
import { useStepsUrlNavigation } from '../../hooks/useStepsUrlNavigation';
import { useApplicationQuery } from '../../hooks/react-query/useApplicationQuery';
import { useProductsByPartnerQuery } from '../../hooks/react-query/useProductsByPartnerQuery';
import { ApplicationRejectedScreen } from '../applicationRejected/ApplicationRejectedScreen';
import { FlowScreen } from './FlowScreen';
import { PreFlowScreen } from './PreFlowScreen';

export const OnboardingScreenInner: React.FC<{
  application?: ApplicationApiModel | null;
  product: ProductPublicData;
  partner: PartnerPublicDataApiModel;
}> = ({ product, application, partner }) => {
  const {
    screensConfiguration: { flowSteps, preFlowSteps },
  } = product;

  const { currentStep } = useStepsUrlNavigation(product);
  const isFirstStep = useMemo(() => currentStep === 'getStarted', [currentStep]);
  const isPreFlowStep = useMemo(() => preFlowSteps.includes(currentStep), [currentStep, preFlowSteps]);
  const isFlowStep = useMemo(() => flowSteps.flat().includes(currentStep), [currentStep, flowSteps]);

  if (application?.status === 'Rejected') {
    return <ApplicationRejectedScreen application={application} product={product} />;
  }

  return (
    <Flex flexDirection={'column'} alignItems={'center'} height={'100%'}>
      <MainHeader showProgress={isFlowStep} currentStep={currentStep} groupedSteps={flowSteps} showAccountSettings />
      <Box width={'100%'} maxWidth={'lg'} pt={5} pb={2} px={4} display={'flex'} flex={1} justifyContent={'center'}>
        {isPreFlowStep ? (
          <PreFlowScreen product={product} partner={partner} />
        ) : !isNil(application) ? (
          <FlowScreen application={application} product={product} />
        ) : (
          <Navigate to={'/'} replace />
        )}
      </Box>
      <Footer isFirstStep={isFirstStep} />
    </Flex>
  );
};

export const OnboardingScreen = () => {
  const partner = useRecoilValue(partnerState);
  const { data: products, isPending: loadingProducts } = useProductsByPartnerQuery(partner?.id);
  const { productName } = useParams();
  const currentOnboardingApplicationId = useRecoilValue(currentOnboardingApplicationIdState);

  const product = useMemo(() => products?.find((p) => p.name === productName), [products, productName]);

  const {
    data: application,
    isPending: loadingApplication,
    error: errorFetchingApplication,
  } = useApplicationQuery(currentOnboardingApplicationId);

  if (!partner) {
    return null;
  }

  if (partner.onboardingDisabled) {
    return <Navigate to={'/login'} replace />;
  }

  if (loadingApplication || loadingProducts) {
    return <LoadingPage />;
  }

  if (!product) {
    return <Navigate to={'products'} replace />;
  }

  if (errorFetchingApplication) {
    console.log(errorFetchingApplication);
  }

  return <OnboardingScreenInner product={product} application={application} partner={partner} />;
};
