/* eslint-disable react/jsx-no-useless-fragment */
import type { PersonApiModel } from '@lama/clients';
import type { BusinessApiModel } from '@lama/business-service-client';

import React, { useCallback, useMemo, useState } from 'react';
import type { FC } from 'react';

import type { Entity } from '@lama/common-types';
import { Flex } from '@lama/design-system';
import { Toggle } from '../Toggle';
import { SelectOrCreateEntity } from './SelectOrCreateEntity';
import type { GenericAddRelationComponentProps } from './types';
import { EntityForm } from './EntityForm';
import { SearchExistingCustomer } from './SearchExistingCustomers';

type Step = 'form' | 'search' | 'selection';

interface EntityFormContainerProps extends GenericAddRelationComponentProps {
  step: Step;
  onBack: () => void;
}

const EntityFormContainer = (props: EntityFormContainerProps) => {
  const { showEntityTypeToggle, onToggleChange, entityType } = props;

  return (
    <Flex minHeight={'100%'} fullWidth height={'100%'} gap={6} alignItems={'center'} flexDirection={'column'}>
      {showEntityTypeToggle && onToggleChange ? (
        <Toggle
          onChange={onToggleChange}
          optionA={{ value: true, text: 'Person' }}
          optionB={{ value: false, text: 'Business' }}
          value={entityType === 'person'}
        />
      ) : null}
      <EntityForm {...props} entityType={entityType} />
    </Flex>
  );
};

export const AddOrCreateRelatedEntity: FC<GenericAddRelationComponentProps> = ({
  editSelectedEntity = false,
  allowedEntityTypes,
  ...props
}) => {
  const {
    application,
    existingEntityIds,
    submitHandler,
    isLoading,
    onClose,
    searchButtonVisible,
    customForm: CustomForm,
    onSearchResultConverted,
  } = props;

  const [step, setStep] = useState<Step>('selection');
  const onSearchSelected = useCallback(() => {
    setStep('search');
  }, []);
  const onBack = useCallback(() => {
    setStep('selection');
  }, []);
  const onEntitySelected = useCallback(
    async (selectedEntity?: BusinessApiModel | PersonApiModel, entityType?: Entity) => {
      const existingEntity = selectedEntity && entityType;

      if (!existingEntity) {
        setStep('form');

        return;
      }

      await submitHandler(selectedEntity, entityType);

      if (editSelectedEntity) {
        setStep('form');
      }
    },
    [submitHandler, editSelectedEntity],
  );

  const formComponent = useMemo(
    () =>
      CustomForm ? (
        <CustomForm onBack={onBack} />
      ) : (
        <EntityFormContainer allowedEntityTypes={allowedEntityTypes} {...props} onBack={onBack} step={step} />
      ),
    [CustomForm, allowedEntityTypes, props, onBack, step],
  );

  const searchComponent = useMemo(
    () => <SearchExistingCustomer onSearchResultConverted={onSearchResultConverted} onClose={onClose} onBack={onBack} />,
    [onSearchResultConverted, onClose, onBack],
  );

  const selectionComponent = useMemo(
    () => (
      <SelectOrCreateEntity
        entityType={allowedEntityTypes.length === 1 ? (allowedEntityTypes[0] as 'business' | 'person') : undefined}
        onEntitySelected={onEntitySelected}
        excludedEntityIds={existingEntityIds}
        application={application}
        loading={!!isLoading}
        searchEnabled={searchButtonVisible}
        onSearchSelected={onSearchSelected}
      />
    ),
    [allowedEntityTypes, onEntitySelected, existingEntityIds, application, isLoading, searchButtonVisible, onSearchSelected],
  );

  const CurrentView = useMemo(() => {
    switch (step) {
      case 'form': {
        return formComponent;
      }
      case 'search': {
        return searchComponent;
      }
      default: {
        return selectionComponent;
      }
    }
  }, [step, formComponent, searchComponent, selectionComponent]);

  return (
    <Flex height={'100%'} overflow={'auto'} fullWidth flexDirection={'column'}>
      {CurrentView}
    </Flex>
  );
};
