import React from 'react';
import { Text } from '@lama/design-system';
import { ImmutableEmailInputField } from '../FormikFields/ImmutableEmailInputField.js';
import { FormikPercentInputField } from '../FormikFields/FormikPercentInputField.js';
import { FormikMultiPicker } from '../FormikFields/FormikMultiPicker.js';
import { FormikHierarchicalMultiPicker } from '../FormikFields/FormikHierarchicalMultiPicker.js';

import { FormikYesNoQuestion } from '../FormikFields/FormikYesNoQuestion.js';
import { FormikYesNoQuestionPicker } from '../FormikFields/FormikYesNoQuestionPicker.js';
import { GenericDescription, BusinessAddress, Address, GenericRichText } from './CustomComponents/index.js';
import type { PropertyComponentProps } from './propertyTypeToComponentMap.js';

export const customComponents: Record<string, React.FC<PropertyComponentProps>> = {
  businessAddress: (props) => <BusinessAddress {...props} />,
  partialBusinessAddress: (props) => <BusinessAddress {...props} enableChildPropertiesConfiguration />,
  propertyAddress: (props) => <BusinessAddress {...props} enableChildPropertiesConfiguration />,
  description: (props) => <GenericDescription {...props} />,
  yesNoQuestion: ({ property: { fieldName, displayName, optional, helperText } }) => (
    <FormikYesNoQuestion name={fieldName} question={displayName} required={!optional} helperText={helperText} />
  ),
  yesNoQuestionPicker: ({ property: { fieldName, displayName, optional } }) => (
    <FormikYesNoQuestionPicker name={fieldName} label={displayName} required={!optional} />
  ),
  immutableEmail: ({ property: { fieldName, displayName, optional, helperText }, submitted }) => (
    <ImmutableEmailInputField name={fieldName} label={displayName} highlight={submitted} required={!optional} helperText={helperText} />
  ),
  percent: ({ property: { fieldName, displayName, optional, helperText, propertyValues }, submitted }) => (
    <FormikPercentInputField
      name={fieldName}
      label={displayName}
      highlight={submitted}
      required={!optional}
      helperText={helperText}
      sourcesValues={propertyValues}
    />
  ),
  multiPickerWithFreeformOptions: ({
    property: { fieldName, valuesSource, displayName, optional, helperText, maxItems, valueOptions },
    submitted,
    sourceToValues,
  }) => (
    <FormikMultiPicker
      name={fieldName}
      highlight={submitted}
      options={valueOptions ?? sourceToValues?.[valuesSource ?? ''] ?? []}
      label={displayName}
      required={!optional}
      helperText={helperText}
      maxItems={maxItems}
      allowFreeformOptions
    />
  ),
  personalInfoAddress: ({ submitted, property: { optional } }) => (
    <Address fieldNamePrefix={'address'} required={!optional} submitted={submitted} />
  ),
  hierarchicalMultiPicker: ({
    property: { fieldName, displayName, maxItems, valuesSource, helperText, optional },
    submitted,
    sourceToHierarchicalValues,
  }) => (
    <FormikHierarchicalMultiPicker
      name={fieldName}
      highlight={submitted}
      options={sourceToHierarchicalValues?.[valuesSource ?? ''] ?? []}
      label={displayName}
      required={!optional}
      helperText={helperText}
      maxItems={maxItems}
    />
  ),
  text: ({ property: { displayName } }) => (
    <Text variant={'body2'} color={'secondary'} pb={8}>
      {displayName}
    </Text>
  ),
  genericRichText: ({ property }) => <GenericRichText property={property} />,
};
